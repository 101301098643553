import { Popover, Transition } from "@headlessui/react";
import { isAuth, logout } from "actions";
import axiosAPI from "actions/axiosAPI";
import NoData from "assets/image/NoData.png";
import { API_URL_getnotif, API_URL_isread, baseurl } from "constants";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { HiMenu } from "react-icons/hi";
import { IoPerson } from "react-icons/io5";
import { MdNotificationsActive } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { userReducer } from "reducers/authReducers";

const Header = ({ sideOpen, setSideOpen }) => {
  const dispatch = useDispatch();
  const { updateAkunResult } = useSelector((state) => state.auth);
  const [image, setImage] = useState("");
  const [nama, setNama] = useState("");
  const [email, setEmail] = useState("");
  const [notif, setNotif] = useState([]);

  const navigate = useNavigate();

  const handleNotifClick = async (item) => {
    console.log(item);
    if (item.is_read === false) {
      await axiosAPI.post(API_URL_isread, { id: item.id });
    }
    fetchData();
    dispatch(userReducer({ type: "ON_TAP_NOTIF", data: item.kegiatan }));
    navigate("/kegiatan");
  };

  const handleReadAll = async () => {
    await axiosAPI.post(API_URL_isread, { user_id: isAuth().user_id });
    fetchData();
  };

  const fetchData = useCallback(async () => {
    const res_notif = await axiosAPI.post(API_URL_getnotif, {
      user_id: isAuth().user_id,
    });
    setNotif(res_notif.data);
    if (isAuth().image != null) {
      setImage(baseurl + isAuth().image);
    }
    setNama(isAuth().nama);
    setEmail(isAuth().email);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    fetchData();
  }, [updateAkunResult]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="sticky z-40 top-0 px-4 sm:px-8 bg-white drop-shadow">
      <div className="flex justify-between h-16 items-center text-base-600">
        <HiMenu
          className="cursor-pointer"
          onClick={() => setSideOpen(!sideOpen)}
          size={20}
        />
        <div className="flex items-center gap-2">
          <button
            onClick={() => navigate("/")}
            className="mr-3 font-medium px-3 py-1 border rounded bg-primary-800 text-white shadow active:shadow-sm active:bg-primary-900 transition-all"
          >
            Portal
          </button>

          {/* Notifications */}
          <Popover as={"div"} className="flex relative">
            <Popover.Button className="flex h-10 w-10 justify-center items-center bg-gray-200 rounded-full outline-none">
              <MdNotificationsActive size={20} />
              {notif.filter((item) => item.is_read === false).length > 0 && (
                <div className="absolute top-0.5 right-0.5 w-2.5 h-2.5 bg-red-500 rounded-full"></div>
              )}
            </Popover.Button>
            <Transition
              enter="transition duration-100 ease-out"
              enterFrom="transform scale-95 opacity-0"
              enterTo="transform scale-100 opacity-100"
              leave="transition duration-75 ease-out"
              leaveFrom="transform scale-100 opacity-100"
              leaveTo="transform scale-95 opacity-0"
            >
              <Popover.Panel className="absolute w-72 max-h-80 overflow-y-auto flex flex-col right-3 top-14 rounded-sm shadow-lg bg-white text-base-800 overflow-hidden">
                <div className="p-3 flex justify-between items-center shadow sticky top-0 bg-white">
                  <span className="font-semibold">Notifikasi</span>
                  {notif.length > 0 &&
                    notif.filter((item) => item.is_read === false).length >
                      0 && (
                      <span
                        className="text-xs font-semibold text-primary-700 cursor-pointer"
                        onClick={handleReadAll}
                      >
                        Tandai sudah dibaca
                      </span>
                    )}
                </div>
                {notif.length > 0 ? (
                  notif.map((item, index) => (
                    <div
                      key={item.id}
                      className={`p-3 cursor-pointer border-b transition-all ${
                        item.is_read
                          ? "bg-white"
                          : "bg-primary-50 hover:bg-primary-100"
                      }`}
                      onClick={() => handleNotifClick(item)}
                    >
                      <div className="text-xs font-semibold">{item.title}</div>
                      <div className="text-[10px]">
                        {moment(item.created_at).fromNow()}
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="h-40 flex justify-center items-center flex-col">
                    <img className="h-20" src={NoData} alt="nodata" />
                    <div className="text-xs font-semibold">
                      Tidak ada notifikasi
                    </div>
                  </div>
                )}
              </Popover.Panel>
            </Transition>
          </Popover>

          <hr className="w-px h-6 bg-slate-200" />

          {/* profile */}
          <Popover as={"div"} className="flex relative">
            <Popover.Button className="flex items-center gap-2 outline-none">
              <div className="w-10 h-10 rounded-full cursor-pointer overflow-hidden justify-center items-center flex">
                {image ? (
                  <img
                    src={image}
                    alt="foto"
                    className="w-full h-full object-cover"
                  />
                ) : (
                  <IoPerson className="text-gray-800 text-2xl" />
                )}
              </div>
            </Popover.Button>
            <Transition
              enter="transition duration-100 ease-out"
              enterFrom="transform scale-95 opacity-0"
              enterTo="transform scale-100 opacity-100"
              leave="transition duration-75 ease-out"
              leaveFrom="transform scale-100 opacity-100"
              leaveTo="transform scale-95 opacity-0"
            >
              <Popover.Panel className="absolute w-max min-w-[170px] flex flex-col right-3 top-14 rounded-sm shadow-lg bg-white text-base-800 overflow-hidden">
                <div
                  onClick={() => navigate("/profile")}
                  className="px-2 py-2 hover:bg-gray-100 cursor-pointer"
                >
                  <div className="text-xs font-medium">{email}</div>
                  <div className="text-[10px]">{nama}</div>
                </div>
                <div
                  onClick={() => navigate("/ubahpassword")}
                  className="text-xs px-2 py-2 hover:bg-gray-100 cursor-pointer"
                >
                  Ubah Password
                </div>
                <div className="flex flex-col">
                  <button
                    onClick={logout}
                    className="text-xs py-2 px-2 text-left hover:bg-primary-700 hover:text-white transition-all"
                  >
                    Keluar
                  </button>
                </div>
              </Popover.Panel>
            </Transition>
          </Popover>
        </div>
      </div>
    </div>
  );
};

export default Header;
