import { getData, getTanggal, textArray } from "actions";
import axiosAPI from "actions/axiosAPI";
import axios from "axios";
import {
  ButtonHover,
  ErrorMessages,
  ModalContent,
  Pagination,
  PortalHeader,
} from "components";
import {
  API_URL_getdatakegiatan,
  API_URL_getderus,
  API_URL_getresumekegiatan,
  API_URL_getriwayatkegiatan,
  baseurl,
} from "constants";
import moment from "moment";
import { Fragment, useCallback, useEffect, useState } from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { FiDownload, FiEye, FiSearch } from "react-icons/fi";
import { MdArrowBack } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { kegiatanReducer } from "reducers/kegiatanReducers";
import listName from "utils/listName";
import Modal from "../../components/molecules/Modal";
import KegiatanResume from "pages/Kegiatan/KegiatanResume";
import KegiatanGallery from "pages/Kegiatan/KegiatanGallery";

const Kegiatan = () => {
  const { getKegiatanResult, getKegiatanLoading, getKegiatanError } =
    useSelector((state) => state.kegiatan);
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [limit, setLimit] = useState(10);
  const [pageActive, setPageActive] = useState(0);
  const [search, setSearch] = useState("");
  const [modalDetail, setModalDetail] = useState(false);
  const [dataDetail, setDataDetail] = useState("");
  const [riwayatKegiatan, setRiwayatKegiatan] = useState([]);
  const [modalImage, setModalImage] = useState(false);
  const [image, setImage] = useState("");

  const [derus, setDerus] = useState({});
  const [resumeData, setResumeData] = useState({});
  const [dataGallery, setDataGallery] = useState([]);

  const handlePageClick = (e) => {
    const offset = e.selected * limit;
    const param = {};
    if (search !== "") {
      param.search = search;
    }
    param.anggota = params.id;
    param.limit = limit;
    param.offset = offset;
    get(param);
    setPageActive(e.selected);
  };

  const handleSelect = (e) => {
    const param = {};
    if (search !== "") {
      param.search = search;
    }
    param.anggota = params.id;
    param.limit = e;
    get(param);
    setLimit(e);
    setPageActive(0);
  };

  const onSearch = (e) => {
    const { value } = e.target;
    setSearch(value);
    setLimit(10);
    setPageActive(0);
    get({ anggota: params.id, search: value });
  };

  const onDownload = (url) => {
    axios({
      url,
      method: "GET",
      responseType: "blob",
    })
      .then((response) => {
        const href = window.URL.createObjectURL(response.data);

        const anchorElement = document.createElement("a");

        anchorElement.href = href;
        anchorElement.download = url.split("/").pop();

        document.body.appendChild(anchorElement);
        anchorElement.click();

        document.body.removeChild(anchorElement);
        window.URL.revokeObjectURL(href);
      })
      .catch((error) => {
        console.log("error: ", error);
      });
  };

  const onDetail = async (item) => {
    try {
      const res = await axiosAPI.post(API_URL_getresumekegiatan, {
        kegiatan_id: item.id,
      });
      setResumeData(res.data);
      if (item.jenis_kegiatan.is_riwayat) {
        const res_riwayat = await axiosAPI.post(API_URL_getriwayatkegiatan, {
          kegiatan_id: item.id,
        });
        setRiwayatKegiatan(res_riwayat.data);
      }
    } catch (error) {
      console.log(error);
    }
    setModalDetail(true);
    setDataDetail(item);
  };

  const get = useCallback(
    async (param) => {
      getData(
        { dispatch, redux: kegiatanReducer },
        param,
        API_URL_getdatakegiatan,
        "GET_KEGIATAN"
      );
    },
    [dispatch]
  );

  const fetchData = useCallback(async () => {
    try {
      get({ anggota: params.id, limit: limit });
      const res_derus = await axiosAPI.post(API_URL_getderus, {
        anggota_id: params.id,
      });
      setDerus(res_derus.data);
    } catch (error) {
      console.log(error);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    fetchData();
    window.scrollTo(0, 0);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const setModalGallery = (image) => {
    setModalImage(true);
    setImage(image);
  };

  const [tabPage, setTabPage] = useState(0);
  const tab = [
    {
      name: "Resume Kegiatan",
      comp: <KegiatanResume resumeData={resumeData} />,
    },
    {
      name: "Dokumentasi",
      comp: (
        <KegiatanGallery
          data={dataGallery}
          setData={setDataGallery}
          setModal={setModalGallery}
          kegiatan_id={dataDetail?.id}
        />
      ),
    },
  ];

  return (
    <Fragment>
      <PortalHeader />

      <div className="w-full px-5 md:px-16 lg:px-24 mb-10 transition-all my-8">
        <div className="flex items-center py-2 gap-3">
          <MdArrowBack
            className="text-xl cursor-pointer"
            onClick={() => navigate(-1)}
          />
          <div className="text-lg md:text-xl font-semibold">
            {derus?.jabatan?.nama}
          </div>
        </div>
        <hr className="my-4 w-28 border-2 border-primary-800" />

        {/* Table */}
        <div className="bg-white rounded-sm border">
          <div className="flex justify-between items-center px-6 py-2 border-b">
            <div className="">{derus?.jabatan?.nama}</div>
            <label className="border w-60 h-10 p-2 outline-none rounded-sm flex">
              <div className="p-1 mr-2">
                <FiSearch />
              </div>
              <input
                type="text"
                className="outline-none w-full text-xs"
                placeholder="Cari"
                onChange={onSearch}
              />
            </label>
          </div>
          <div className="p-4">
            <div className="overflow-y-auto">
              <table className="w-full text-xs sm:text-sm">
                <thead>
                  <tr className="text-left text-base-500 border-b">
                    <th className="p-2">No</th>
                    <th className="p-2">Tanggal</th>
                    <th className="p-2">Jenis Kegiatan</th>
                    <th className="p-2">Nama Kegiatan</th>
                    <th className="p-2 text-center">Peserta</th>
                    <th className="p-2 text-center">Pembuat</th>
                    <th className="p-2">CC</th>
                    <th className="p-2 text-center">Status</th>
                    <th className="p-2 text-center">Aksi</th>
                  </tr>
                </thead>
                <tbody>
                  {/* Loading */}
                  {getKegiatanLoading ? (
                    <tr className="text-base-800">
                      <td className="p-10" colSpan="7">
                        <div className="flex justify-center items-center">
                          <AiOutlineLoading3Quarters className="animate-spin text-xl" />
                        </div>
                      </td>
                    </tr>
                  ) : // No Data
                  getKegiatanResult.count === 0 ? (
                    <tr className="text-base-800">
                      <td className="p-10" colSpan="7">
                        <div className="flex flex-col justify-center items-center">
                          <ErrorMessages
                            messages="Data tidak ditemukan"
                            type="nodata"
                          />
                        </div>
                      </td>
                    </tr>
                  ) : // Data
                  getKegiatanResult.count > 0 ? (
                    getKegiatanResult.results.map((item, itemIdx) => (
                      <tr className="text-base-800 border-b" key={itemIdx}>
                        <td className="p-2">
                          {pageActive * limit + itemIdx + 1}
                        </td>
                        <td className="p-2">
                          {getTanggal(item.start_date, item.end_date)}
                        </td>
                        <td className="p-2">{item.jenis_kegiatan.nama}</td>
                        <td className="p-2">{item.nama}</td>
                        <td className="p-2">
                          {listName(item.penerima, "nama")}
                        </td>
                        <td className="p-2">{item.pembuat.nama}</td>
                        <td className="p-2">
                          {textArray(item.email_cc, false, false)}
                        </td>
                        <td className="p-2 flex justify-center items-center">
                          <div
                            className={`flex justify-center font-semibold items-center px-2 py-1 rounded-lg text-white ${
                              !item.is_publish
                                ? "bg-red-500"
                                : item.status?.id === 0
                                ? "bg-blue-700"
                                : item.status?.id === 1
                                ? "bg-yellow-400"
                                : item.status?.id === 2
                                ? "bg-red-600"
                                : [3, 4, 5].includes(item.status?.id)
                                ? "bg-base-400	"
                                : "bg-green-700"
                            }`}
                          >
                            {item.is_publish ? item.status.status : "Drafted"}
                          </div>
                        </td>
                        <td className="p-2">
                          <div className="flex justify-center gap-x-2 text-base">
                            <ButtonHover textHover="Lihat" placement="right">
                              <FiEye
                                onClick={() => onDetail(item)}
                                className="cursor-pointer text-gray-500"
                              />
                            </ButtonHover>
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : // Error
                  getKegiatanError ? (
                    <tr className="text-base-800">
                      <td className="p-10" colSpan="7">
                        <div className="flex flex-col justify-center items-center">
                          <ErrorMessages
                            messages={getKegiatanError}
                            type="error"
                          />
                        </div>
                      </td>
                    </tr>
                  ) : null}
                </tbody>
              </table>
            </div>
            <Pagination
              handlePageClick={handlePageClick}
              pageCount={
                getKegiatanResult.count > 0 ? getKegiatanResult.count : 0
              }
              limit={limit}
              setLimit={handleSelect}
              pageActive={pageActive}
            />
          </div>
        </div>
      </div>

      {/* Modal Detail */}
      <Modal
        title={"Detail Tugas"}
        dismiss
        closeButton
        show={modalDetail}
        setShow={setModalDetail}
      >
        {dataDetail && (
          <div className="p-5">
            {/* Detail */}
            <div>
              <div className="mb-10">
                <div className="flex gap-2 mb-2">
                  <div className="w-1/3 font-semibold">Nama Kegiatan</div>
                  <div className="flex-1">: {dataDetail?.nama}</div>
                </div>
                <div className="flex gap-2 mb-2">
                  <div className="w-1/3 font-semibold">Jenis Kegiatan</div>
                  <div className="flex-1">
                    : {dataDetail?.jenis_kegiatan?.nama}
                  </div>
                </div>
                <div className="flex gap-2 mb-2">
                  <div className="w-1/3 font-semibold">Tanggal</div>
                  <div className="flex-1">
                    : {getTanggal(dataDetail?.start_date, dataDetail?.end_date)}
                  </div>
                </div>
                <div className="flex gap-2 mb-2">
                  <div className="w-1/3 font-semibold">Lokasi</div>
                  <div className="flex-1">: {dataDetail?.tempat}</div>
                </div>
                <div className="flex gap-2 mb-2">
                  <div className="w-1/3 font-semibold">Pembuat</div>
                  <div className="flex-1">: {dataDetail?.pembuat?.nama}</div>
                </div>
                <div className="flex gap-2 mb-2">
                  <div className="w-1/3 font-semibold">Penerima</div>
                  <div className="flex-1">
                    : {listName(dataDetail?.penerima, "nama")}
                  </div>
                </div>
                <div className="flex gap-2 mb-2">
                  <div className="w-1/3 font-semibold">CC</div>
                  <div className="flex-1">
                    : {textArray(dataDetail?.email_cc, false, false)}
                  </div>
                </div>
                <div className="flex gap-2 mb-2">
                  <div className="w-1/3 font-semibold">Publish</div>
                  <div className="flex-1">
                    :{" "}
                    {dataDetail?.is_publish ? (
                      <span className="text-green-500">Publish</span>
                    ) : (
                      <span className="text-red-500">Draft</span>
                    )}
                  </div>
                </div>
                <div className="flex gap-2 mb-2">
                  <div className="w-1/3 font-semibold">Status</div>
                  <div className="flex flex-1 items-center gap-1">
                    :
                    <div
                      className={`flex justify-center font-semibold items-center px-2 py-1 rounded-lg text-white ${
                        !dataDetail?.is_publish
                          ? "bg-red-500"
                          : dataDetail?.status?.id === 0
                          ? "bg-blue-700"
                          : dataDetail?.status?.id === 1
                          ? "bg-yellow-400"
                          : dataDetail?.status?.id === 2
                          ? "bg-red-600"
                          : [3, 4, 5].includes(dataDetail?.status?.id)
                          ? "bg-base-400	"
                          : "bg-green-700"
                      }`}
                    >
                      {dataDetail?.is_publish
                        ? dataDetail?.status.status
                        : "Drafted"}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-col gap-3">
              {/* Tabs */}
              <div className="flex flex-row rounded-lg gap-2">
                {tab.map((item, idx) => (
                  <div key={idx}>
                    <button
                      onClick={() => setTabPage(idx)}
                      className={`px-4 p-2 flex rounded-lg justify-center text-sm transition-all ease-in duration-200 w-full ${
                        tabPage === idx
                          ? "text-white bg-gradient-to-br bg-primary-700 hover:bg-primary-800 font-bold"
                          : "border-transparent text-gray-400 hover:bg-neutral-200"
                      }`}
                    >
                      {item.name}
                    </button>
                  </div>
                ))}
              </div>

              {/* Content */}
              <div className="p-3 border-2 rounded-md">
                {tab.find((item, index) => index === tabPage).comp}
              </div>
            </div>
          </div>
        )}
      </Modal>

      {/* Modal Image */}
      <ModalContent dismiss show={modalImage} setShow={setModalImage}>
        <div className="flex relative m-auto w-full h-full items-center justify-center">
          <img className="max-h-full max-w-full" src={image} alt="foto" />
        </div>
      </ModalContent>
    </Fragment>
  );
};

export default Kegiatan;
