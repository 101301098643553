import { createSlice } from "@reduxjs/toolkit";

export const akunReducers = createSlice({
  name: "akun",
  initialState: {
    addAkunResult: false,
    addAkunLoading: false,
    getAkunResult: false,
    getAkunLoading: false,
    getAkunError: false,
    deleteAkunResult: false,
  },
  reducers: {
    akunReducer: (state, action) => {
      const { type, payload } = action.payload;
      switch (type) {
        case "GET_AKUN":
          return {
            ...state,
            getAkunResult: payload.data,
            getAkunLoading: payload.loading,
            getAkunError: payload.error,
          };
        case "ADD_AKUN":
          return {
            ...state,
            addAkunResult: payload.data,
            addAkunLoading: payload.loading,
          };
        case "DELETE_AKUN":
          return {
            ...state,
            deleteAkunResult: payload.data,
          };
        default:
          return state;
      }
    },
  },
});

export const { akunReducer } = akunReducers.actions;

export default akunReducers.reducer;
