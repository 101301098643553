import React from "react";
import Logo from "assets/image/icon-white.png";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import { logout } from "actions";

const PortalHeader = () => {
  const navigate = useNavigate();
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div
      className={`h-20 md:h-24 py-2 flex items-center justify-between sticky top-0 bg-white z-50 px-5 md:px-16 lg:px-24 transition-all ${
        isScrolled ? "shadow" : ""
      }`}
    >
      <img
        onClick={() => navigate("/")}
        className="h-12 object-contain cursor-pointer	"
        src={Logo}
        alt="logo-apjii"
      />
      <div className="gap-1 flex">
        <button
          onClick={() => navigate("/dashboard")}
          className="font-medium text-xs md:text-base px-2 md:px-6 py-1 border rounded-lg bg-primary-800 text-white shadow active:shadow-sm active:bg-primary-900 transition-all"
        >
          Dashboard
        </button>
        <button
          onClick={logout}
          className="font-medium text-xs md:text-base px-2 md:px-6 py-1 border rounded-lg bg-red-700 text-white shadow active:shadow-sm active:bg-red-800 transition-all"
        >
          Keluar
        </button>
      </div>
    </div>
  );
};

export default PortalHeader;
