import { baseurl } from "constants";
import { IoPerson } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

const CardBidang = ({ data }) => {
  const navigate = useNavigate();

  return (
    <div
      onClick={() => navigate(`/kegiatan/${data.id}`)}
      className="w-full h-full flex flex-col justify-center items-center p-5 border rounded-lg group/card cursor-pointer"
    >
      <div className="h-36 w-36 flex my-2 justify-center items-center rounded-full bg-white border shadow group-hover/card:scale-105 group-hover/card:shadow-md duration-100 transition-all overflow-hidden mb-5">
        {data?.user?.akun?.foto ? (
          <img
            className="h-full w-full object-cover"
            src={baseurl + data?.user?.akun?.foto}
            alt="imagecard"
          />
        ) : (
          <IoPerson className="text-6xl text-gray-800" />
        )}
      </div>
      <div className="h-16 text-center text-gray-800">
        <div>{data?.user?.nama}</div>
        <div className="font-bold text-sm">{data?.jabatan?.nama}</div>
      </div>
    </div>
  );
};

export default CardBidang;
