import { isAuth } from "actions";
import { Navigate } from "react-router-dom";

const PrivateRoute = ({ children }) => {
  const level = ["Super Admin", "Admin", "User"];
  if (!isAuth()) {
    return <Navigate to="/login" replace />;
  } else if (
    !isAuth().level.filter((item) => level.includes(item)).length > 0
  ) {
    return <Navigate to="/forbidden" replace />;
  }

  return children;
};

export default PrivateRoute;
