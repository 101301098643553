import { Fragment, useCallback, useEffect, useState } from "react";

import {
  addData,
  addFormData,
  deleteData,
  getData,
  getTanggal,
  isAuth,
  textArray,
  updateData,
} from "actions";
import axiosAPI from "actions/axiosAPI";
import axios from "axios";
import {
  ButtonHover,
  ErrorMessages,
  HeadContent,
  Modal,
  ModalContent,
  Pagination,
  ToggleSwitch,
} from "components";
import {
  API_URL_createdokumentasikegiatan,
  API_URL_createkegiatan,
  API_URL_createresume,
  API_URL_detailproyekgalery,
  API_URL_edelkegiatan,
  API_URL_getakun,
  API_URL_getdatakegiatan,
  API_URL_getjeniskegiatan,
  API_URL_getkegiatan,
  API_URL_getresponseattendes,
  API_URL_getresumekegiatan,
  API_URL_getriwayatkegiatan,
  API_URL_publishkegiatan,
  baseurl,
} from "constants";
import { useFormik } from "formik";
import moment from "moment/moment";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { FaMinusCircle, FaPlusCircle } from "react-icons/fa";
import { FiEdit, FiEye } from "react-icons/fi";
import { HiOutlineClipboardList } from "react-icons/hi";
import { HiCalendar } from "react-icons/hi2";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { kegiatanReducer } from "reducers/kegiatanReducers";
import listName from "utils/listName";
import * as Yup from "yup";
import KegiatanGallery from "./KegiatanGallery";
import KegiatanResume from "./KegiatanResume";
import { userReducer } from "reducers/authReducers";
import DateTimePicker from "react-datetime-picker";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";

const Kegiatan = () => {
  const { onTapNotif } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const {
    addResumeKegiatanResult,
    addResumeKegiatanLoading,
    addKegiatanResult,
    addKegiatanLoading,
    getKegiatanResult,
    getKegiatanLoading,
    getKegiatanError,
    deleteKegiatanResult,
    addRiwayatKegiatanResult,
  } = useSelector((state) => state.kegiatan);

  const [limit, setLimit] = useState(10);
  const [pageActive, setPageActive] = useState(0);
  const [search, setSearch] = useState("");
  const [modal, setModal] = useState(false);
  const [modalDetail, setModalDetail] = useState(false);
  const [modalResume, setModalResume] = useState(false);
  const [modalImage, setModalImage] = useState(false);
  const [modalDataUndangan, setModalDataUndangan] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [image, setImage] = useState("");
  const [dataDetail, setDataDetail] = useState("");
  const [riwayatKegiatan, setRiwayatKegiatan] = useState([]);
  const [dataResponseAttendees, setDataResponseAttendees] = useState([]);

  const [jenisKegiatan, setJenisKegiatan] = useState([]);
  const [anggota, setAnggota] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [resumeData, setResumeData] = useState({});

  const [dataGallery, setDataGallery] = useState([]);

  const options_anggota = anggota.map((item) => ({
    value: item.id,
    label: `${item.jabatan} (${item.nama})`,
  }));

  const formik = useFormik({
    initialValues: {
      id: "",
      nama: "",
      jenis_kegiatan: "",
      tempat: "",
      start_date: "",
      end_date: "",
      penerima: [],
      pembuat: "",
      cc: [{ email: "" }],
      is_publish: "0",
    },
    validationSchema: Yup.object({
      start_date: Yup.string().required("Waktu dimulai harus diisi"),
      end_date: Yup.string().required("Waktu berakhir harus diisi"),
      tempat: Yup.string().required("Lokasi kegiatan harus diisi"),
      jenis_kegiatan: Yup.string().required("Jenis Kegiatan harus diisi"),
      nama: Yup.string().required("Nama Kegiatan harus diisi"),
      penerima: Yup.array()
        .required("Penerima harus diisi")
        .min(1, "Penerima harus diisi"),
      pembuat: Yup.string().required("Pembuat harus diisi"),
    }),
    onSubmit: (values) => {
      const data = {
        ...values,
        start_date: moment(values.start_date).format("YYYY-MM-DDTHH:mm"),
        end_date: moment(values.end_date).format("YYYY-MM-DDTHH:mm"),
        cc: values.cc.map((item) => item.email),
        is_publish: values.is_publish === "0" ? false : true,
      };

      if (values.id) {
        data.pk = values.id;
        delete data.id;
        updateData(
          { dispatch, redux: kegiatanReducer },
          data,
          API_URL_edelkegiatan,
          "ADD_KEGIATAN"
        );
      } else {
        delete data.id;
        addData(
          { dispatch, redux: kegiatanReducer },
          data,
          API_URL_createkegiatan,
          "ADD_KEGIATAN"
        );
      }
    },
  });

  const formik_resume = useFormik({
    initialValues: {
      kegiatan_id: "",
      deskripsi: "",
      file: [],
    },
    onSubmit: (values) => {
      const formData = new FormData();
      formData.append("kegiatan_id", values.kegiatan_id);
      formData.append("deskripsi", values.deskripsi);
      for (let i = 0; i < values.file.length; i++) {
        formData.append("file", values.file[i]);
      }
      addFormData(
        { dispatch, redux: kegiatanReducer },
        formData,
        API_URL_createresume,
        "ADD_RESUMEKEGIATAN"
      );
    },
  });

  const handlePageClick = (e) => {
    const offset = e.selected * limit;
    const param = {};
    if (search !== "") {
      param.search = search;
    }
    param.limit = limit;
    param.offset = offset;
    get(param);
    setPageActive(e.selected);
  };

  const handleSelect = (e) => {
    const param = {};
    if (search !== "") {
      param.search = search;
    }
    param.limit = e;
    get(param);
    setLimit(e);
    setPageActive(0);
  };

  const onAdd = () => {
    formik.resetForm();
    setModal(true);
    setIsEdit(false);
    formik.setFieldValue("pembuat", isAuth().user_id);
    setModalTitle("Tambah Data");
  };

  const onDetail = async (item) => {
    try {
      const res = await axiosAPI.post(API_URL_getresumekegiatan, {
        kegiatan_id: item.id,
      });
      setResumeData(res.data);

      if (item.jenis_kegiatan.is_riwayat) {
        const res_riwayat = await axiosAPI.post(API_URL_getriwayatkegiatan, {
          kegiatan_id: item.id,
        });
        setRiwayatKegiatan(res_riwayat.data);
      }
    } catch (error) {
      console.log(error);
    }

    setModalDetail(true);
    setDataDetail(item);
  };

  const onEdit = async (item) => {
    setModal(true);
    setIsEdit(true);
    setModalTitle("Edit Data");

    formik.setValues({
      id: item.id,
      start_date: moment.utc(item.start_date).format("YYYY-MM-DDTHH:mm"),
      end_date: moment.utc(item.end_date).format("YYYY-MM-DDTHH:mm"),
      jenis_kegiatan: item.jenis_kegiatan.id,
      nama: item.nama,
      tempat: item.tempat,
      penerima: item.penerima.map((item) => item.user_id),
      // approval: item.approval.map((item) => ({
      //   value: item.user_id,
      //   label: item.nama,
      // })),
      is_publish: item.is_publish ? "1" : "0",
      status: item.status?.id,
      pembuat: item.pembuat.id,
      cc: item?.email_cc?.map((item) => ({ email: item })) || [{ email: "" }],
    });
  };

  const onDelete = (item) => {
    deleteData(
      { dispatch, redux: kegiatanReducer },
      item.id,
      API_URL_edelkegiatan,
      "DELETE_KEGIATAN"
    );
  };

  const onSearch = (e) => {
    const { value } = e.target;
    setSearch(value);
    setLimit(10);
    setPageActive(0);
    get({ search: value });
  };

  const onResponseAttendee = async (item) => {
    try {
      const res = await axiosAPI.post(API_URL_getresponseattendes, {
        kegiatan_id: item.id,
      });
      setModalDataUndangan(true);
      setDataResponseAttendees(res.data?.attendees);
    } catch (error) {
      setModalDataUndangan(true);
      setDataResponseAttendees([]);
    }
  };

  const onChangePublish = (item) => {
    updateData(
      { dispatch, redux: kegiatanReducer },
      {
        pk: item.id,
        is_portal: !item.is_portal,
      },
      API_URL_publishkegiatan,
      "ADD_KEGIATAN"
    );
  };

  const onDownload = (url) => {
    axios({
      url,
      method: "GET",
      responseType: "blob",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": true,
        "Access-Control-Allow-Methods": "OPTIONS, GET, POST",
      },
    })
      .then((response) => {
        const href = window.URL.createObjectURL(response.data);

        const anchorElement = document.createElement("a");

        anchorElement.href = href;
        anchorElement.download = url.split("/").pop();

        document.body.appendChild(anchorElement);
        anchorElement.click();

        document.body.removeChild(anchorElement);
        window.URL.revokeObjectURL(href);
      })
      .catch((error) => {
        console.log("error: ", error);
      });
  };

  const get = useCallback(
    async (param) => {
      getData(
        { dispatch, redux: kegiatanReducer },
        param,
        API_URL_getdatakegiatan,
        "GET_KEGIATAN"
      );
    },
    [dispatch]
  );

  const fetchData = useCallback(
    async (param = false) => {
      setModal(false);
      setModalDetail(false);
      setModalImage(false);
      setModalResume(false);
      get(param ? param : { limit: limit });

      const res_jenis_kegiatan = await axiosAPI.get(API_URL_getjeniskegiatan);
      setJenisKegiatan(res_jenis_kegiatan.data);

      const res_anggota = await axiosAPI.get(API_URL_getakun);
      setAnggota(res_anggota.data);
    },
    [get] // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (addRiwayatKegiatanResult) {
      fetchData();
    }
  }, [addRiwayatKegiatanResult]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (addKegiatanResult || deleteKegiatanResult) {
      const offset = pageActive * limit;
      const param = {};
      if (search !== "") {
        param.search = search;
      }
      param.limit = limit;
      param.offset = offset;
      fetchData(param);
    }
  }, [addKegiatanResult, deleteKegiatanResult, dispatch]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (addResumeKegiatanResult) {
      const res = async () => {
        const res = await axiosAPI.post(API_URL_getresumekegiatan, {
          kegiatan_id: dataDetail?.id,
        });
        setResumeData(res.data);
        setDataDetail({ ...dataDetail, status: { id: 3, status: "Done" } });
        const offset = pageActive * limit;
        const param = {};
        if (search !== "") {
          param.search = search;
        }
        param.limit = limit;
        param.offset = offset;
        get(param);
      };
      res();
      setModalResume(false);
    }
  }, [addResumeKegiatanResult, dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (onTapNotif) {
      axiosAPI
        .post(API_URL_getkegiatan, {
          kegiatan_id: onTapNotif,
        })
        .then((response) => onDetail(response.data));

      dispatch(userReducer({ type: "ON_TAP_NOTIF", data: false }));
    }
  }, [onTapNotif]);

  const renderInput = (props, openCalendar) => {
    const { className, ...res } = props;
    const disabled =
      isEdit && formik.values.is_publish && formik.values?.status !== 1;
    return (
      <div className="text-sm border rounded-sm w-full flex items-center">
        <input
          // disabled={disabled}
          readOnly
          placeholder="dd-mm-yyyy, HH:mm"
          className="form-control w-full h-full outline-none p-2"
          onClick={openCalendar}
          {...res}
        />
        <div className="p-2 text-lg">
          <HiCalendar onClick={openCalendar} className="cursor-pointer" />
        </div>
      </div>
    );
  };

  const onDeleteGallery = (pk) => {
    deleteData(
      { dispatch, redux: kegiatanReducer },
      pk,
      API_URL_detailproyekgalery,
      "ADD_DOKUMENTASI"
    );
  };

  const setModalGallery = (image) => {
    setModalImage(true);
    setImage(image);
  };

  const onChangeImage = (e) => {
    try {
      const file = e.target.files[0];
      const formData = new FormData();
      formData.append("image", file);
      formData.append("kegiatan_id", dataDetail?.id);
      addFormData(
        { dispatch, redux: kegiatanReducer },
        formData,
        API_URL_createdokumentasikegiatan,
        "ADD_DOKUMENTASI"
      );
    } catch (error) {
      console.log(error);
    }
  };

  const [tabPage, setTabPage] = useState(0);
  const tab = [
    {
      name: "Resume Kegiatan",
      comp: <KegiatanResume resumeData={resumeData} onDownload={onDownload} />,
    },
    {
      name: "Dokumentasi",
      comp: (
        <KegiatanGallery
          data={dataGallery}
          setData={setDataGallery}
          onDelete={onDeleteGallery}
          setModal={setModalGallery}
          onChangeImage={onChangeImage}
          kegiatan_id={dataDetail?.id}
        />
      ),
    },
  ];

  return (
    <Fragment>
      <HeadContent
        onSearch={onSearch}
        onAdd={
          isAuth().level.filter((item) =>
            ["Super Admin", "Admin"].includes(item)
          ).length > 0
            ? onAdd
            : false
        }
      />

      {/* Table */}
      <div className="bg-white rounded-sm shadow">
        <div className="p-4 border-b">Kegiatan List</div>
        <div className="p-4 pt-0">
          <div className="overflow-y-auto">
            <table className="w-full text-xs sm:text-sm">
              <thead>
                <tr className="text-left text-base-500 border-b">
                  <th className="p-2">No</th>
                  <th className="p-2">Tanggal</th>
                  <th className="p-2">Jenis Kegiatan</th>
                  <th className="p-2">Nama Kegiatan</th>
                  <th className="p-2">Penerima</th>
                  <th className="p-2">Initiator</th>
                  <th className="p-2">CC</th>
                  <th className="p-2 text-center">Portal</th>
                  <th className="p-2 text-center">Status</th>
                  <th className="p-2 text-center">Aksi</th>
                </tr>
              </thead>
              <tbody>
                {/* Loading */}
                {getKegiatanLoading ? (
                  <tr className="text-base-800">
                    <td className="p-10" colSpan="9">
                      <div className="flex justify-center items-center">
                        <AiOutlineLoading3Quarters className="animate-spin text-xl" />
                      </div>
                    </td>
                  </tr>
                ) : // No Data
                getKegiatanResult.count === 0 ? (
                  <tr className="text-base-800">
                    <td className="p-10" colSpan="9">
                      <div className="flex flex-col justify-center items-center">
                        <ErrorMessages
                          messages="Data tidak ditemukan"
                          type="nodata"
                        />
                      </div>
                    </td>
                  </tr>
                ) : // Data
                getKegiatanResult.count > 0 ? (
                  getKegiatanResult.results.map((item, itemIdx) => (
                    <tr className="text-base-800 border-b" key={itemIdx}>
                      <td className="p-2">
                        {pageActive * limit + itemIdx + 1}
                      </td>
                      <td className="p-2">
                        {getTanggal(item.start_date, item.end_date)}
                      </td>
                      <td className="p-2">{item.jenis_kegiatan.nama}</td>
                      <td className="p-2">{item.nama}</td>
                      <td className="p-2">{listName(item.penerima, "nama")}</td>
                      <td className="p-2">{item.pembuat.nama}</td>
                      <td className="p-2">
                        {textArray(item.email_cc, false, false)}
                      </td>
                      <td className="p-2 text-center">
                        <ToggleSwitch
                          onChange={() => onChangePublish(item)}
                          value={item.is_portal}
                        />
                      </td>
                      <td className="p-2 flex justify-center items-center">
                        <div
                          className={`flex justify-center font-semibold items-center px-2 py-1 rounded-lg text-white ${
                            !item.is_publish
                              ? "bg-red-500"
                              : item.status?.id === 0
                              ? "bg-blue-700"
                              : item.status?.id === 1
                              ? "bg-yellow-400"
                              : item.status?.id === 2
                              ? "bg-red-600"
                              : [3, 4, 5].includes(item.status?.id)
                              ? "bg-base-400	"
                              : "bg-green-700"
                          }`}
                        >
                          {item.is_publish ? item.status.status : "Drafted"}
                        </div>
                      </td>
                      <td className="p-2">
                        <div className="flex justify-center gap-x-2 text-base">
                          <ButtonHover textHover="Lihat">
                            <FiEye
                              onClick={() => onDetail(item)}
                              className="cursor-pointer text-gray-500"
                            />
                          </ButtonHover>

                          {isAuth().level.filter((item) =>
                            ["Super Admin", "Admin"].includes(item)
                          ).length > 0 && (
                            <>
                              {[3, 4, 5].includes(item.status?.id) && (
                                <ButtonHover textHover="Data Undangan">
                                  <HiOutlineClipboardList
                                    onClick={() => onResponseAttendee(item)}
                                    className="cursor-pointer text-green-500"
                                  />
                                </ButtonHover>
                              )}
                              <ButtonHover textHover="Edit">
                                <FiEdit
                                  onClick={() => onEdit(item)}
                                  className="cursor-pointer text-blue-500"
                                />
                              </ButtonHover>
                              {(!item.is_publish ||
                                isAuth().level.includes("Super Admin")) && (
                                <ButtonHover
                                  placement="right"
                                  textHover="Delete"
                                >
                                  <RiDeleteBin6Line
                                    onClick={() => onDelete(item)}
                                    className="cursor-pointer text-red-500"
                                  />
                                </ButtonHover>
                              )}
                            </>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))
                ) : // Error
                getKegiatanError ? (
                  <tr className="text-base-800">
                    <td className="p-10" colSpan="9">
                      <div className="flex flex-col justify-center items-center">
                        <ErrorMessages
                          messages={getKegiatanError}
                          type="error"
                        />
                      </div>
                    </td>
                  </tr>
                ) : null}
              </tbody>
            </table>
          </div>
          <Pagination
            handlePageClick={handlePageClick}
            pageCount={
              getKegiatanResult.count > 0 ? getKegiatanResult.count : 0
            }
            limit={limit}
            setLimit={handleSelect}
            pageActive={pageActive}
          />
        </div>
      </div>

      {/* Modal */}
      <Modal
        title={modalTitle}
        sizeModal="sm"
        dismiss
        show={modal}
        setShow={setModal}
      >
        {/* Content */}
        <div className="p-5">
          <form onSubmit={formik.handleSubmit}>
            {/* Input Nama Kegiatan */}
            <div className="mb-2">
              <label className="required" htmlFor="nama">
                Nama Kegiatan
              </label>
              <input
                type="text"
                name="nama"
                id="nama"
                className="p-2 text-sm border rounded-sm w-full outline-none"
                onChange={formik.handleChange}
                value={formik.values.nama}
                placeholder="Masukkan Nama Kegiatan"
                // disabled={isEdit && formik.values.is_publish}
              />
              {formik.touched.nama && formik.errors.nama ? (
                <div className="text-red-500 text-xs">{formik.errors.nama}</div>
              ) : null}
            </div>

            {/* Input Jenis  */}
            <div className="mb-2">
              <label className="required" htmlFor="jenis_kegiatan">
                Jenis Kegiatan
              </label>
              <select
                name="jenis_kegiatan"
                id="jenis_kegiatan"
                className="p-2 text-sm border rounded-sm w-full outline-none"
                onChange={formik.handleChange}
                value={formik.values.jenis_kegiatan}
                disabled={isEdit && formik.values.is_publish}
              >
                <option value="" disabled>
                  Pilih Jenis Kegiatan
                </option>
                {jenisKegiatan.map((item, itemIdx) => (
                  <option value={item.id} key={itemIdx}>
                    {item.nama}
                  </option>
                ))}
              </select>
              {formik.touched.jenis_kegiatan && formik.errors.jenis_kegiatan ? (
                <div className="text-red-500 text-xs">
                  {formik.errors.jenis_kegiatan}
                </div>
              ) : null}
            </div>

            {/* Input Deskripsi */}
            <div className="mb-2">
              <label htmlFor="deskripsi" className="required">
                Lokasi
              </label>
              <textarea
                name="tempat"
                id="tempat"
                className="p-2 text-sm border rounded-sm w-full outline-none"
                onChange={formik.handleChange}
                value={formik.values.tempat}
                placeholder="Masukkan Lokasi Kegiatan"
                // disabled={isEdit && formik.values.is_publish}
              />
              {formik.touched.tempat && formik.errors.tempat ? (
                <div className="text-red-500 text-xs">
                  {formik.errors.tempat}
                </div>
              ) : null}
            </div>

            {/* Input start & end date */}
            <div className="flex gap-2 mb-2">
              <div className="w-full">
                <label className="required" htmlFor="start_date">
                  Waktu Dimulai
                </label>
                <div className="w-full">
                  <DateTimePicker
                    onChange={(e) => formik.setFieldValue("start_date", e)}
                    value={formik.values.start_date}
                    calendarIcon={<HiCalendar />}
                    className={"w-full text-sm h-10"}
                    dayPlaceholder="dd"
                    monthPlaceholder="MM"
                    yearPlaceholder="yyyy"
                    hourPlaceholder="hh"
                    minutePlaceholder="mm"
                    secondPlaceholder="ss"
                    format="dd/MM/yyyy hh:mm:ss a"
                  />
                </div>
                {/* <Datetime
                  dateFormat="DD-MM-YYYY, "
                  timeFormat="HH:mm"
                  onChange={(e) =>
                    formik.setFieldValue(
                      "start_date",
                      moment(e).format("YYYY-MM-DDTHH:mm")
                    )
                  }
                  renderInput={renderInput}
                  value={moment(formik.values.start_date)}
                /> */}

                {formik.touched.start_date && formik.errors.start_date ? (
                  <div className="text-red-500 text-xs">
                    {formik.errors.start_date}
                  </div>
                ) : null}
              </div>
              <div className="w-full">
                <label className="required" htmlFor="end_date">
                  Waktu Berakhir
                </label>
                <div className="w-full">
                  <DateTimePicker
                    onChange={(e) => formik.setFieldValue("end_date", e)}
                    value={formik.values.end_date}
                    calendarIcon={<HiCalendar />}
                    className={"w-full text-sm h-10"}
                    dayPlaceholder="dd"
                    monthPlaceholder="MM"
                    yearPlaceholder="yyyy"
                    hourPlaceholder="hh"
                    minutePlaceholder="mm"
                    secondPlaceholder="ss"
                    format="dd/MM/yyyy hh:mm:ss a"
                  />
                </div>
                {/* <Datetime
                  dateFormat="DD-MM-YYYY, "
                  timeFormat="HH:mm"
                  onChange={(e) =>
                    formik.setFieldValue(
                      "end_date",
                      moment(e).format("YYYY-MM-DDTHH:mm")
                    )
                  }
                  renderInput={renderInput}
                  value={moment(formik.values.end_date)}
                /> */}
                {formik.touched.end_date && formik.errors.end_date ? (
                  <div className="text-red-500 text-xs">
                    {formik.errors.end_date}
                  </div>
                ) : null}
              </div>
            </div>

            {/* Input Pembuat */}
            <div className="mb-2">
              <label className="required" htmlFor="pembuat">
                Initiator
              </label>
              <Select
                id="pembuat"
                className="text-sm"
                isClearable
                isSearchable
                value={options_anggota.filter(
                  (item) => item.value === formik.values.pembuat
                )}
                // isDisabled={isEdit && formik.values.is_publish}
                options={options_anggota}
                onChange={(e) => {
                  formik.setFieldValue("pembuat", e?.value || "");
                }}
              />
              {formik.touched.pembuat && formik.errors.pembuat ? (
                <div className="text-red-500 text-xs">
                  {formik.errors.pembuat}
                </div>
              ) : null}
            </div>

            {/* Input Penerima */}
            <div className="mb-2">
              <label className="required" htmlFor="penerima">
                Penerima
              </label>
              <CreatableSelect
                id="penerima"
                className="text-sm"
                isClearable
                isMulti
                isSearchable
                value={options_anggota.filter((item) =>
                  formik.values.penerima.includes(item.value)
                )}
                // isDisabled={
                //   isEdit &&
                //   formik.values.is_publish &&
                //   formik.values?.status !== 1
                // }
                options={options_anggota}
                onChange={(value) => {
                  const tmp = value.map((item) => item.value);
                  formik.setFieldValue("penerima", tmp);
                }}
              />
              {formik.touched.penerima && formik.errors.penerima ? (
                <div className="text-red-500 text-xs">
                  {formik.errors.penerima}
                </div>
              ) : null}
            </div>

            {/* Input CC */}
            <div className="mb-2">
              <label htmlFor="email">Email CC</label>

              {formik.values.cc.map((item, idx) => (
                <div
                  key={idx}
                  className="flex gap-2 items-center justify-center mb-2"
                >
                  <div className="flex flex-col w-full">
                    <input
                      className="p-2 text-sm border rounded-sm w-full outline-none"
                      placeholder="Masukkan cc email"
                      type="email"
                      onChange={(e) => {
                        const tmp = [...formik.values.cc];
                        tmp[idx].email = e.target.value;
                        formik.setFieldValue("cc", tmp);
                      }}
                      value={item.email}
                    />
                    {formik.touched?.["cc"]?.[idx]?.["email"] &&
                    formik.errors?.["cc"]?.[idx]?.["email"] ? (
                      <div className="text-red-500 text-xs">
                        {formik.errors?.["cc"]?.[idx]?.["email"]}
                      </div>
                    ) : null}
                  </div>
                  {idx === 0 ? (
                    <FaPlusCircle
                      color="#0369A1"
                      size={26}
                      className="cursor-pointer"
                      onClick={() => {
                        const tmp = [...formik.values.cc];
                        tmp.push({ email: "" });
                        formik.setFieldValue("cc", tmp);
                      }}
                    />
                  ) : (
                    <FaMinusCircle
                      color="#EF4444"
                      size={26}
                      className="cursor-pointer"
                      onClick={() => {
                        const tmp = [...formik.values.cc];
                        tmp.splice(idx, 1);
                        formik.setFieldValue("cc", tmp);
                      }}
                    />
                  )}
                </div>
              ))}
            </div>

            {/* Input Publish  */}
            <div className="mb-2">
              <label htmlFor="is_publish">Publish</label>
              <select
                name="is_publish"
                id="is_publish"
                className="p-2 text-sm border rounded-sm w-full outline-none"
                onChange={formik.handleChange}
                value={formik.values.is_publish}
                disabled={isEdit && formik.values.is_publish === "1"}
              >
                <option value="0">Drafted</option>
                <option value="1">Publish</option>
              </select>
            </div>

            {/* Input Is Publish */}
            {/* <div className="flex items-center gap-2 mb-2">
              <input
                id="is_publish"
                name="is_publish"
                type="checkbox"
                className="w-4 h-4"
                onChange={formik.handleChange}
                checked={formik.values.is_publish}
                disabled={isEdit && formik.values.is_publish}
              />
              <label htmlFor="is_publish">Publish</label>
            </div> */}
          </form>
        </div>

        {/* Footer */}
        <div className="space-x-2 h-full flex justify-end items-center">
          <button
            className="p-2 px-4 border text-sm rounded-sm"
            type="submit"
            onClick={() => setModal(false)}
          >
            Cancel
          </button>
          <button
            className="p-2 px-4 w-20 flex justify-center items-center bg-primary-700 text-white text-sm rounded-sm"
            type="submit"
            disabled={addKegiatanLoading}
            onClick={formik.handleSubmit}
          >
            {addKegiatanLoading ? (
              <AiOutlineLoading3Quarters className="animate-spin" />
            ) : (
              "Submit"
            )}
          </button>
        </div>
      </Modal>

      {/* Modal Detail */}
      <Modal
        title={"Detail Kegiatan"}
        dismiss
        closeButton
        show={modalDetail}
        setShow={setModalDetail}
      >
        {dataDetail && (
          <div className="p-5">
            {/* Detail */}
            <div>
              <div className="mb-10">
                <div className="flex gap-2 mb-2">
                  <div className="w-1/3 font-semibold">Nama Kegiatan</div>
                  <div className="flex-1">: {dataDetail?.nama}</div>
                </div>
                <div className="flex gap-2 mb-2">
                  <div className="w-1/3 font-semibold">Jenis Kegiatan</div>
                  <div className="flex-1">
                    : {dataDetail?.jenis_kegiatan?.nama}
                  </div>
                </div>
                <div className="flex gap-2 mb-2">
                  <div className="w-1/3 font-semibold">Tanggal</div>
                  <div className="flex-1">
                    : {getTanggal(dataDetail?.start_date, dataDetail?.end_date)}
                  </div>
                </div>
                <div className="flex gap-2 mb-2">
                  <div className="w-1/3 font-semibold">Lokasi</div>
                  <div className="flex-1">: {dataDetail?.tempat}</div>
                </div>
                <div className="flex gap-2 mb-2">
                  <div className="w-1/3 font-semibold">Pembuat</div>
                  <div className="flex-1">: {dataDetail?.pembuat?.nama}</div>
                </div>
                <div className="flex gap-2 mb-2">
                  <div className="w-1/3 font-semibold">Penerima</div>
                  <div className="flex-1">
                    : {listName(dataDetail?.penerima, "nama")}
                  </div>
                </div>
                <div className="flex gap-2 mb-2">
                  <div className="w-1/3 font-semibold">CC</div>
                  <div className="flex-1">
                    : {textArray(dataDetail?.email_cc, false, false)}
                  </div>
                </div>
                {/* <div className="flex gap-2 mb-2">
                  <div className="w-1/3 font-semibold">Approval</div>
                  <div className="flex gap-1">
                    <div>:</div>
                    <div className="flex flex-col">
                      {dataDetail?.approval?.map((item, idx) => (
                        <div className="flex gap-2">
                          <div
                            className={`flex justify-center px-2 rounded-full text-white items-center ${
                              item.is_approval === 0
                                ? "bg-sky-400"
                                : item.is_approval === 1
                                ? "bg-green-400"
                                : item.is_approval === 2
                                ? "bg-red-500"
                                : "bg-yellow-300"
                            }`}
                          >
                            {item.is_approval === 0 ? (
                              <TbHourglassHigh />
                            ) : item.is_approval === 1 ? (
                              <TbCheck />
                            ) : item.is_approval === 2 ? (
                              <TbX />
                            ) : (
                              <TbInfoCircle />
                            )}
                          </div>
                          <div>{`${item.nama} ${
                            item.is_approval === 3 ? "- " + item.keterangan : ""
                          }`}</div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div> */}
                <div className="flex gap-2 mb-2">
                  <div className="w-1/3 font-semibold">Publish</div>
                  <div className="flex-1">
                    :{" "}
                    {dataDetail?.is_publish ? (
                      <span className="text-green-500">Publish</span>
                    ) : (
                      <span className="text-red-500">Draft</span>
                    )}
                  </div>
                </div>
                <div className="flex gap-2 mb-2">
                  <div className="w-1/3 font-semibold">Status</div>
                  <div className="flex flex-1 items-center gap-1">
                    :
                    <div
                      className={`flex justify-center font-semibold items-center px-2 py-1 rounded-lg text-white ${
                        !dataDetail?.is_publish
                          ? "bg-red-500"
                          : dataDetail?.status?.id === 0
                          ? "bg-blue-700"
                          : dataDetail?.status?.id === 1
                          ? "bg-yellow-400"
                          : dataDetail?.status?.id === 2
                          ? "bg-red-600"
                          : [3, 4, 5].includes(dataDetail?.status?.id)
                          ? "bg-base-400	"
                          : "bg-green-700"
                      }`}
                    >
                      {dataDetail?.is_publish
                        ? dataDetail?.status.status
                        : "Drafted"}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-col gap-3">
              {/* Tabs */}
              <div className="flex flex-row rounded-lg gap-2">
                {tab.map((item, idx) => (
                  <div key={idx}>
                    <button
                      onClick={() => setTabPage(idx)}
                      className={`px-4 p-2 flex rounded-lg justify-center text-sm transition-all ease-in duration-200 w-full ${
                        tabPage === idx
                          ? "text-white bg-gradient-to-br bg-primary-700 hover:bg-primary-800 font-bold"
                          : "border-transparent text-gray-400 hover:bg-neutral-200"
                      }`}
                    >
                      {item.name}
                    </button>
                  </div>
                ))}
              </div>

              {/* Content */}
              <div className="p-3 border-2 rounded-md">
                {tab.find((item, index) => index === tabPage).comp}
              </div>
            </div>

            {/* Riwayat */}
            {dataDetail?.jenis_kegiatan?.is_riwayat && (
              <div>
                <div>Riwayat</div>
                {riwayatKegiatan?.map((item, itemIdx) => (
                  <div key={itemIdx} className="flex min-h-[100px]">
                    <div className="relative w-10 flex flex-col justify-center items-center">
                      <div className="absolute top-0 z-10 w-full h-8 flex justify-center items-center bg-transparen rounded-full">
                        <div className="w-3 h-3 bg-gray-800 rounded-full" />
                      </div>
                      <div
                        className={`w-1 h-full bg-gray-800/50 ${
                          itemIdx === 0 ? "mt-5" : ""
                        }`}
                      />
                    </div>
                    <div className="flex-1">
                      <div className="h-8 flex items-center">
                        {moment(item.created_at).format("DD MMMM YYYY, H:MM")}{" "}
                        {`(${item.user.nama})`}
                      </div>
                      <div className="font-semibold">{item.nama}</div>
                      <div className="text-xs mb-2">{item.deskripsi}</div>
                      <div className="flex flex-wrap gap-2 mb-4">
                        {item.file &&
                          item.file.map((itemImage, itemIdx) => (
                            <div
                              key={itemIdx}
                              onClick={() => {
                                setImage(baseurl + itemImage);
                                setModalImage(true);
                              }}
                              className="inline-block cursor-pointer"
                            >
                              <img
                                className="object-cover w-20 h-20 rounded-md"
                                src={baseurl + itemImage}
                                alt="detail"
                              />
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        )}

        {(dataDetail?.status?.id === 5 || true) && (
          <div className="flex justify-end items-center h-full">
            <button
              className="p-2 px-4 bg-green-700 text-white text-sm rounded-sm"
              onClick={() => {
                formik_resume.resetForm();
                formik_resume.setFieldValue("kegiatan_id", dataDetail?.id);
                setModalResume(true);
              }}
            >
              Upload Resume
            </button>
          </div>
        )}
      </Modal>

      {/* Modal Resume */}
      <Modal
        title={"Upload Resume"}
        sizeModal="sm"
        dismiss
        show={modalResume}
        setShow={setModalResume}
      >
        {/* Content */}
        <div className="p-5">
          <form onSubmit={formik_resume.handleSubmit}>
            {/* Input Deskripsi */}
            <div className="mb-2">
              <label htmlFor="deskripsi">Deskripsi</label>
              <textarea
                name="deskripsi"
                id="deskripsi"
                className="p-2 text-sm border rounded-sm w-full outline-none"
                onChange={formik_resume.handleChange}
                value={formik_resume.values.deskripsi}
                placeholder="Masukkan Deskripsi"
              />
            </div>
            {/* Input File */}
            <div className="mb-2">
              <label htmlFor="file">File</label>
              <input
                name="file"
                id="file"
                type="file"
                multiple
                className="p-2 text-sm border rounded-sm w-full outline-none"
                onChange={(e) => {
                  formik_resume.setFieldValue("file", e.target.files);
                  console.log(e.target.files);
                }}
                value={
                  formik_resume.values.file.length > 0
                    ? formik_resume.values.file[0].fileName
                    : ""
                }
                accept=".pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,image/*"
              />
            </div>
          </form>
        </div>

        {/* Footer */}
        <div className="space-x-2 h-full flex justify-end items-center">
          <button
            className="p-2 px-4 border text-sm rounded-sm"
            type="submit"
            onClick={() => setModalResume(false)}
          >
            Cancel
          </button>
          <button
            className="p-2 px-4 w-20 flex justify-center items-center bg-primary-700 text-white text-sm rounded-sm"
            type="submit"
            disabled={addResumeKegiatanLoading}
            onClick={formik_resume.handleSubmit}
          >
            {addResumeKegiatanLoading ? (
              <AiOutlineLoading3Quarters className="animate-spin" />
            ) : (
              "Submit"
            )}
          </button>
        </div>
      </Modal>

      {/* Modal Detail */}
      <Modal
        title={"Data Undangan"}
        dismiss
        closeButton
        show={modalDataUndangan}
        setShow={setModalDataUndangan}
      >
        <div className="px-5">
          <div className="overflow-y-auto">
            <table className="w-full text-xs sm:text-sm">
              <thead>
                <tr className="text-left text-base-500 border-b">
                  <th className="p-2">No</th>
                  <th className="p-2">Email</th>
                  <th className="p-2">Response</th>
                </tr>
              </thead>
              <tbody>
                {dataResponseAttendees.map((item, idx) => (
                  <tr className="text-base-800 border-b" key={idx}>
                    <td className="p-2">{idx + 1}</td>
                    <td className="p-2">{item.email}</td>
                    <td className="p-2">{item.response}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </Modal>

      {/* Modal Image */}
      <ModalContent dismiss show={modalImage} setShow={setModalImage}>
        <div className="flex relative m-auto w-full h-full items-center justify-center">
          <img className="max-h-full max-w-full" src={image} alt="foto" />
        </div>
      </ModalContent>
    </Fragment>
  );
};

export default Kegiatan;
