import { Fragment, useCallback, useEffect, useState } from "react";

import { addData, deleteData, getData, updateData } from "actions";
import {
  ButtonHover,
  ErrorMessages,
  HeadContent,
  Modal,
  Pagination,
  ToggleSwitch,
} from "components";
import {
  API_URL_createperiode,
  API_URL_edelperiode,
  API_URL_getdataperiode,
} from "constants";
import { useFormik } from "formik";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { FiEdit } from "react-icons/fi";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { periodeReducer } from "reducers/masterdataReducers";
import * as Yup from "yup";

const Periode = () => {
  const dispatch = useDispatch();
  const {
    addPeriodeResult,
    addPeriodeLoading,
    getPeriodeResult,
    getPeriodeLoading,
    getPeriodeError,
    deletePeriodeResult,
  } = useSelector((state) => state.masterdata);

  const [limit, setLimit] = useState(10);
  const [pageActive, setPageActive] = useState(0);
  const [search, setSearch] = useState("");
  const [modal, setModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");

  const [periodeTahun, setPeriodeTahun] = useState([]);

  const formik = useFormik({
    initialValues: {
      id: "",
      periode_start: "",
      periode_end: "",
    },
    validationSchema: Yup.object({
      periode_start: Yup.number().required("Periode Start harus diisi"),
      periode_end: Yup.number().required("Periode End harus diisi"),
    }),
    onSubmit: (values) => {
      if (values.id) {
        updateData(
          { dispatch, redux: periodeReducer },
          {
            pk: values.id,
            periode_start: values.periode_start,
            periode_end: values.periode_end,
          },
          API_URL_edelperiode,
          "ADD_PERIODE"
        );
      } else {
        addData(
          { dispatch, redux: periodeReducer },
          {
            periode_start: values.periode_start,
            periode_end: values.periode_end,
          },
          API_URL_createperiode,
          "ADD_PERIODE"
        );
      }
    },
  });

  const handlePageClick = (e) => {
    const offset = e.selected * limit;
    const param = {};
    if (search !== "") {
      param.search = search;
    }
    param.limit = limit;
    param.offset = offset;
    get(param);
    setPageActive(e.selected);
  };

  const handleSelect = (e) => {
    const param = {};
    if (search !== "") {
      param.search = search;
    }
    param.limit = e;
    get(param);
    setLimit(e);
    setPageActive(0);
  };

  const onAdd = () => {
    formik.resetForm();
    setModal(true);
    setModalTitle("Tambah Data");
  };

  const onEdit = (item) => {
    formik.setValues({
      id: item.id,
      periode_start: item.periode_start,
      periode_end: item.periode_end,
    });
    setModal(true);
    setModalTitle("Edit Data");
  };

  const onChangeActive = (item) => {
    updateData(
      { dispatch, redux: periodeReducer },
      {
        pk: item.id,
        periode_start: item.periode_start,
        periode_end: item.periode_end,
        is_active: !item.is_active,
      },
      API_URL_edelperiode,
      "ADD_PERIODE"
    );
  };

  const onDelete = (item) => {
    deleteData(
      { dispatch, redux: periodeReducer },
      item.id,
      API_URL_edelperiode,
      "DELETE_PERIODE"
    );
  };

  const onSearch = (e) => {
    const { value } = e.target;
    setSearch(value);
    setLimit(10);
    setPageActive(0);
    get({ search: value });
  };

  const get = useCallback(
    async (param) => {
      getData(
        { dispatch, redux: periodeReducer },
        param,
        API_URL_getdataperiode,
        "GET_PERIODE"
      );
    },
    [dispatch]
  );

  const fetchData = useCallback(
    async (param = false) => {
      setModal(false);
      get(param ? param : { limit: limit });
    },
    [get] // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(() => {
    fetchData();

    const getPeriodeTahun = () => {
      const currentYear = new Date().getFullYear();
      const years = [];
      let startYear = 2000;
      while (startYear <= currentYear + 4) {
        years.push(startYear++);
      }
      setPeriodeTahun(years.reverse());
    };
    getPeriodeTahun();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (addPeriodeResult || deletePeriodeResult) {
      const offset = pageActive * limit;
      const param = {};
      if (search !== "") {
        param.search = search;
      }
      param.limit = limit;
      param.offset = offset;
      fetchData(param);
    }
  }, [addPeriodeResult, deletePeriodeResult, dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Fragment>
      <HeadContent onSearch={onSearch} onAdd={onAdd} />

      {/* Table */}
      <div className="bg-white rounded-sm shadow">
        <div className="p-4 border-b">Periode</div>
        <div className="p-4 pt-0">
          <div className="overflow-y-auto">
            <table className="w-full text-xs sm:text-sm">
              <thead>
                <tr className="text-left text-base-500 border-b">
                  <th className="p-2">No</th>
                  <th className="p-2">Nama</th>
                  <th className="p-2">Periode Mulai</th>
                  <th className="p-2">Periode Selesai</th>
                  <th className="p-2 text-center">Active</th>
                  <th className="p-2 text-center">Aksi</th>
                </tr>
              </thead>
              <tbody>
                {/* Loading */}
                {getPeriodeLoading ? (
                  <tr className="text-base-800">
                    <td className="p-10" colSpan="5">
                      <div className="flex justify-center items-center">
                        <AiOutlineLoading3Quarters className="animate-spin text-xl" />
                      </div>
                    </td>
                  </tr>
                ) : // No Data
                getPeriodeResult.count === 0 ? (
                  <tr className="text-base-800">
                    <td className="p-10" colSpan="5">
                      <div className="flex flex-col justify-center items-center">
                        <ErrorMessages
                          messages="Data tidak ditemukan"
                          type="nodata"
                        />
                      </div>
                    </td>
                  </tr>
                ) : // Data
                getPeriodeResult.count > 0 ? (
                  getPeriodeResult.results.map((item, itemIdx) => (
                    <tr className="text-base-800 border-b" key={itemIdx}>
                      <td className="p-2">
                        {pageActive * limit + itemIdx + 1}
                      </td>
                      <td className="p-2">{item.nama}</td>
                      <td className="p-2">{item.periode_start}</td>
                      <td className="p-2">{item.periode_end}</td>
                      <td className="p-2 text-center">
                        <ToggleSwitch
                          onChange={() => onChangeActive(item)}
                          value={item.is_active}
                        />
                      </td>
                      <td className="p-2">
                        <div className="flex justify-center gap-x-2 text-base">
                          <ButtonHover textHover="Edit">
                            <FiEdit
                              onClick={() => onEdit(item)}
                              className="cursor-pointer text-blue-500"
                            />
                          </ButtonHover>
                          <ButtonHover placement="right" textHover="Delete">
                            <RiDeleteBin6Line
                              onClick={() => onDelete(item)}
                              className="cursor-pointer text-red-500"
                            />
                          </ButtonHover>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : // Error
                getPeriodeError ? (
                  <tr className="text-base-800">
                    <td className="p-10" colSpan="5">
                      <div className="flex flex-col justify-center items-center">
                        <ErrorMessages
                          messages={getPeriodeError}
                          type="error"
                        />
                      </div>
                    </td>
                  </tr>
                ) : null}
              </tbody>
            </table>
          </div>
          <Pagination
            handlePageClick={handlePageClick}
            pageCount={getPeriodeResult.count > 0 ? getPeriodeResult.count : 0}
            limit={limit}
            setLimit={handleSelect}
            pageActive={pageActive}
          />
        </div>
      </div>

      {/* Modal */}
      <Modal
        title={modalTitle}
        sizeModal="sm"
        dismiss
        show={modal}
        setShow={setModal}
      >
        {/* Content */}
        <div className="p-5">
          <form onSubmit={formik.handleSubmit}>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
              {/* Input Periode Mulai */}
              <div className="mb-2">
                <label className="required" htmlFor="periode_start">
                  Periode Mulai
                </label>
                <select
                  className="p-2 text-sm border rounded-sm w-full outline-none"
                  id="periode_start"
                  name="periode_start"
                  placeholder="Masukkan Periode Mulai"
                  onChange={formik.handleChange}
                  value={formik.values.periode_start}
                >
                  <option value="">Pilih Periode Mulai</option>
                  {periodeTahun.map((item, itemIdx) => (
                    <option key={itemIdx} value={item}>
                      {item}
                    </option>
                  ))}
                </select>
                {formik.touched.periode_start && formik.errors.periode_start ? (
                  <div className="text-red-500 text-xs">
                    {formik.errors.periode_start}
                  </div>
                ) : null}
              </div>

              {/* Input Periode Selesai */}
              <div className="mb-2">
                <label className="required" htmlFor="periode_end">
                  Periode Selesai
                </label>
                <select
                  className="p-2 text-sm border rounded-sm w-full outline-none"
                  id="periode_end"
                  name="periode_end"
                  placeholder="Masukkan Periode Selesai"
                  onChange={formik.handleChange}
                  value={formik.values.periode_end}
                >
                  <option value="">Pilih Periode Selesai</option>
                  {periodeTahun.map((item, itemIdx) => (
                    <option key={itemIdx} value={item}>
                      {item}
                    </option>
                  ))}
                </select>
                {formik.touched.periode_end && formik.errors.periode_end ? (
                  <div className="text-red-500 text-xs">
                    {formik.errors.periode_end}
                  </div>
                ) : null}
              </div>
            </div>
          </form>
        </div>

        {/* Footer */}
        <div className="space-x-2 h-full flex justify-end items-center">
          <button
            className="p-2 px-4 border text-sm rounded-sm"
            type="submit"
            onClick={() => setModal(false)}
          >
            Cancel
          </button>
          <button
            className="p-2 px-4 w-20 flex justify-center items-center bg-primary-700 text-white text-sm rounded-sm"
            type="submit"
            disabled={addPeriodeLoading}
            onClick={formik.handleSubmit}
          >
            {addPeriodeLoading ? (
              <AiOutlineLoading3Quarters className="animate-spin text-xl" />
            ) : (
              "Submit"
            )}
          </button>
        </div>
      </Modal>
    </Fragment>
  );
};

export default Periode;
