import { isAuth } from "actions";
import { MainLayout } from "components";
import { menu } from "constants/menu";
import {
  Forbidden,
  Kegiatan,
  Login,
  NotFound,
  Portal,
  Profile,
  UbahPassword,
} from "pages";
import PrivateRoute from "pages/Login/PrivateRoute";
import { BrowserRouter, Route, Routes } from "react-router-dom";

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        {/* Menu */}
        <Route
          element={
            <PrivateRoute>
              <MainLayout />
            </PrivateRoute>
          }
        >
          {menu.map((item, index) => {
            if (
              isAuth() &&
              item.level.filter((item) => isAuth().level.includes(item))
                .length > 0
            ) {
              if (item.sub.length > 0) {
                return item.sub.map((sub, index) => {
                  return (
                    <Route key={index} path={sub.path} element={sub.element} />
                  );
                });
              } else {
                return (
                  <Route key={index} path={item.path} element={item.element} />
                );
              }
            }
            return null;
          })}
        </Route>

        {/* Other Route */}
        <Route path="login" element={<Login />} />
        <Route
          path="/"
          element={
            <PrivateRoute>
              <Portal />
            </PrivateRoute>
          }
        />
        <Route
          path="/kegiatan/:id"
          element={
            <PrivateRoute>
              <Kegiatan />
            </PrivateRoute>
          }
        />

        {/* Other Route Main */}
        <Route
          element={
            <PrivateRoute>
              <MainLayout />
            </PrivateRoute>
          }
        >
          <Route path="/profile" element={<Profile />} />
          <Route path="/ubahpassword" element={<UbahPassword />} />
        </Route>

        {/* 404 */}
        <Route path="forbidden" element={<Forbidden />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
