// plugins
import axios from "axios";
import Swal from "sweetalert2";
import CryptoJS from "crypto-js";
import Cookies from "js-cookie";
import moment from "moment";

export function encrypted(plainText) {
  var key = CryptoJS.enc.Utf8.parse("_secret___derus_");
  var iv = CryptoJS.enc.Utf8.parse("Iv837013870912vI");
  var encrypted = CryptoJS.AES.encrypt(JSON.stringify(plainText), key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
  });
  return encrypted.toString();
}

export function decrypted(encrypted) {
  var key = CryptoJS.enc.Utf8.parse("_secret___derus_");
  var iv = CryptoJS.enc.Utf8.parse("Iv837013870912vI");
  var decrypted = CryptoJS.AES.decrypt(encrypted, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
  });
  decrypted = decrypted.toString(CryptoJS.enc.Utf8);
  return JSON.parse(decrypted);
}

export function isAuth() {
  const token = Cookies.get("user");
  if (token == null) {
    return token;
  } else {
    var decoded = decrypted(token);
  }
  return decoded;
}

export function getTanggal(start_date, end_date) {
  const start = moment.utc(start_date);
  const end = moment.utc(end_date);

  if (start.format("L") === end.format("L")) {
    return `${start.format("D MMMM YYYY")}, ${start.format(
      "HH:mm"
    )}-${end.format("HH:mm")}`;
  }
  return `${start.format("D MMMM YYYY, HH:mm")} - ${end.format(
    "D MMMM YYYY, HH:mm"
  )}`;
}

export function getToken() {
  const token = Cookies.get("token");
  if (token == null) {
    return token;
  }
  return token;
}

export function setNewHeaders(response, update = false) {
  if (update !== true) {
    Cookies.set("token", response.token, { expires: 3 });
  }
  Cookies.set("user", response.access, { expires: 3 });
}

export function logout() {
  Cookies.remove("token");
  Cookies.remove("user");
  window.location.href = "/login";
}

export function convertJSON(data) {
  return JSON.parse(data.replaceAll("'", '"'));
}

export function errorMessage(error) {
  if (error.response && error.response.data && error.response.data.messages) {
    return error.response.data.messages;
  } else if (error.response && error.response.statusText) {
    return error.response.statusText;
  }
  return error.message;
}

export function textArray(data, key = false, string = true) {
  let text = "";
  if (!data) {
    return "-";
  }
  const item = string ? JSON.parse(data.replaceAll("'", '"')) : data;
  if (key) {
    item.forEach((item, index) => {
      if (index !== 0) {
        text += ", ";
      }
      text += item[key];
    });
  } else {
    item.forEach((item, index) => {
      if (index !== 0) {
        text += ", ";
      }
      text += item;
    });
  }
  return text;
}

export const addFormVideo = (reducers, data, url, type) => {
  const { dispatch, redux } = reducers;
  dispatch(
    redux({
      type: type,
      payload: {
        loading: true,
        data: false,
        progress: false,
        error: false,
      },
    })
  );
  axios({
    method: "POST",
    url: url,
    data: data,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Token ${getToken()}`,
    },
    onUploadProgress: (progressEvent) => {
      const { loaded, total } = progressEvent;
      let percent = Math.floor((loaded * 100) / total);
      if (percent < 100) {
        dispatch(
          redux({
            type: type,
            payload: {
              loading: true,
              data: false,
              progress: percent,
              error: false,
            },
          })
        );
      }
    },
  })
    .then((response) => {
      if (response.data.status === 201) {
        dispatch(
          redux({
            type: type,
            payload: {
              loading: false,
              data: false,
              progress: false,
              error: response.data.messages,
            },
          })
        );
        Swal.fire({
          icon: "error",
          title: "Oops...",
          customClass: {
            container: "z-[99999]",
          },
          text: response.data.messages,
        });
      } else {
        dispatch(
          redux({
            type: type,
            payload: {
              loading: false,
              data: response.data.data,
              progress: false,
              error: false,
            },
          })
        );
      }
    })
    .catch((error) => {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        customClass: {
          container: "z-[99999]",
        },
        text: errorMessage(error),
      });
      dispatch(
        redux({
          type: type,
          payload: {
            loading: false,
            data: false,
            progress: false,
            error: errorMessage(error),
          },
        })
      );
      if (error.response.status === 403 || error.response.status === 401) {
        logout();
      }
    });
};

export const getData = (reducers, param, url, type) => {
  const { dispatch, redux } = reducers;
  dispatch(
    redux({
      type: type,
      payload: {
        loading: true,
        data: false,
        error: false,
      },
    })
  );
  axios({
    method: "GET",
    url: url,
    params: param,
    headers: { Authorization: `Token ${getToken()}` },
  })
    .then((response) => {
      dispatch(
        redux({
          type: type,
          payload: {
            loading: false,
            data: response.data,
            error: false,
          },
        })
      );
    })
    .catch((error) => {
      dispatch(
        redux({
          type: type,
          payload: {
            loading: false,
            data: false,
            error: errorMessage(error),
          },
        })
      );
      if (error.response.status === 403 || error.response.status === 401) {
        logout();
      }
    });
};

export const addData = (reducers, data, url, type) => {
  const { dispatch, redux } = reducers;
  dispatch(
    redux({
      type: type,
      payload: {
        loading: true,
        data: false,
      },
    })
  );

  axios({
    method: "POST",
    url: url,
    timeout: 120000,
    data: data,
    headers: { Authorization: `Token ${getToken()}` },
  })
    .then((response) => {
      if (response.data.status === 201) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          customClass: {
            container: "z-[99999]",
          },
          text: response.data.messages,
        });
      } else {
        Swal.fire({
          icon: "success",
          title: "Good job!",
          customClass: {
            container: "z-[99999]",
          },
          text: response.data.messages,
          showConfirmButton: false,
          timer: 1500,
        });
      }
      dispatch(
        redux({
          type: type,
          payload: {
            loading: false,
            data: response.data,
          },
        })
      );
    })
    .catch((error) => {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        customClass: {
          container: "z-[99999]",
        },
        text: errorMessage(error),
      });
      dispatch(
        redux({
          type: type,
          payload: {
            loading: false,
            data: false,
          },
        })
      );
      if (error.response.status === 403 || error.response.status === 401) {
        logout();
      }
    });
};

export const updateData = (reducers, data, url, type) => {
  const { dispatch, redux } = reducers;
  dispatch(
    redux({
      type: type,
      payload: {
        loading: true,
        data: false,
      },
    })
  );

  axios({
    method: "PUT",
    url: url + data.pk,
    timeout: 120000,
    data: data,
    headers: { Authorization: `Token ${getToken()}` },
  })
    .then((response) => {
      if (response.data.status === 201) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          customClass: {
            container: "z-[99999]",
          },
          text: response.data.messages,
        });
      } else {
        Swal.fire({
          icon: "success",
          title: "Good job!",
          customClass: {
            container: "z-[99999]",
          },
          text: response.data.messages,
          showConfirmButton: false,
          timer: 1500,
        });
      }
      dispatch(
        redux({
          type: type,
          payload: {
            loading: false,
            data: response.data,
          },
        })
      );
    })
    .catch((error) => {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        customClass: {
          container: "z-[99999]",
        },
        text: errorMessage(error),
      });
      dispatch(
        redux({
          type: type,
          payload: {
            loading: false,
            data: false,
          },
        })
      );
      if (error.response.status === 403 || error.response.status === 401) {
        logout();
      }
    });
};

export const addFormData = (reducers, data, url, type) => {
  const { dispatch, redux } = reducers;
  dispatch(
    redux({
      type: type,
      payload: {
        loading: true,
        data: false,
      },
    })
  );
  axios({
    method: "POST",
    url: url,
    timeout: 120000,
    data: data,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Token ${getToken()}`,
    },
  })
    .then((response) => {
      if (response.data.status === 201) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          customClass: {
            container: "z-[99999]",
          },
          text: response.data.messages,
        });
      } else {
        Swal.fire({
          icon: "success",
          title: "Good job!",
          customClass: {
            container: "z-[99999]",
          },
          text: response.data.messages,
          showConfirmButton: false,
          timer: 1500,
        });
      }
      dispatch(
        redux({
          type: type,
          payload: {
            loading: false,
            data: response.data,
          },
        })
      );
    })
    .catch((error) => {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        customClass: {
          container: "z-[99999]",
        },
        text: errorMessage(error),
      });
      dispatch(
        redux({
          type: type,
          payload: {
            loading: false,
            data: false,
          },
        })
      );
      if (error.response.status === 403 || error.response.status === 401) {
        logout();
      }
    });
};

export const updateFormData = (reducers, data, url, type, pk) => {
  const { dispatch, redux } = reducers;
  dispatch(
    redux({
      type: type,
      payload: {
        loading: true,
        data: false,
      },
    })
  );

  axios({
    method: "PUT",
    url: url + pk,
    timeout: 120000,
    data: data,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Token ${getToken()}`,
    },
  })
    .then((response) => {
      if (response.data.status === 201) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          customClass: {
            container: "z-[99999]",
          },
          text: response.data.messages,
        });
      } else {
        Swal.fire({
          icon: "success",
          title: "Good job!",
          customClass: {
            container: "z-[99999]",
          },
          text: response.data.messages,
          showConfirmButton: false,
          timer: 1500,
        });
      }
      dispatch(
        redux({
          type: type,
          payload: {
            loading: false,
            data: response.data,
          },
        })
      );
    })
    .catch((error) => {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        customClass: {
          container: "z-[99999]",
        },
        text: errorMessage(error),
      });
      dispatch(
        redux({
          type: type,
          payload: {
            loading: false,
            data: false,
          },
        })
      );
      if (error.response.status === 403 || error.response.status === 401) {
        logout();
      }
    });
};

export const deleteData = (reducers, pk, url, type) => {
  const { dispatch, redux } = reducers;
  dispatch(
    redux({
      type: type,
      payload: {
        data: false,
      },
    })
  );
  Swal.fire({
    title: "Anda Yakin?",
    text: "Anda tidak akan dapat mengembalikan data ini!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#0369a1",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, delete it!",
    customClass: {
      container: "z-[99999]",
    },
  }).then((result) => {
    if (result.isConfirmed) {
      axios({
        method: "DELETE",
        url: url + pk,
        timeout: 120000,
        headers: { Authorization: `Token ${getToken()}` },
      })
        .then((response) => {
          Swal.fire({
            icon: "success",
            title: "Good job!",
            customClass: {
              container: "z-[99999]",
            },
            text: response.data.messages,
            showConfirmButton: false,
            timer: 1500,
          });
          dispatch(
            redux({
              type: type,
              payload: {
                data: response.data,
              },
            })
          );
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            customClass: {
              container: "z-[99999]",
            },
            text: errorMessage(error),
          });
          dispatch(
            redux({
              type: type,
              payload: {
                data: false,
              },
            })
          );
          if (error.response.status === 403 || error.response.status === 401) {
            logout();
          }
        });
    }
  });
};
