import { createSlice } from "@reduxjs/toolkit";

export const anggotaReducers = createSlice({
  name: "anggota",
  initialState: {
    addDerusResult: false,
    addDerusLoading: false,
    getDerusResult: false,
    getDerusLoading: false,
    getDerusError: false,
    deleteDerusResult: false,

    addDewasResult: false,
    addDewasLoading: false,
    getDewasResult: false,
    getDewasLoading: false,
    getDewasError: false,
    deleteDewasResult: false,

    addKapengwilResult: false,
    addKapengwilLoading: false,
    getKapengwilResult: false,
    getKapengwilLoading: false,
    getKapengwilError: false,
    deleteKapengwilResult: false,
  },
  reducers: {
    anggotaReducer: (state, action) => {
      const { type, payload } = action.payload;
      switch (type) {
        case "GET_DEWAS":
          return {
            ...state,
            getDewasResult: payload.data,
            getDewasLoading: payload.loading,
            getDewasError: payload.error,
          };
        case "ADD_DEWAS":
          return {
            ...state,
            addDewasResult: payload.data,
            addDewasLoading: payload.loading,
          };
        case "DELETE_DEWAS":
          return {
            ...state,
            deleteDewasResult: payload.data,
          };
        case "GET_DERUS":
          return {
            ...state,
            getDerusResult: payload.data,
            getDerusLoading: payload.loading,
            getDerusError: payload.error,
          };
        case "ADD_DERUS":
          return {
            ...state,
            addDerusResult: payload.data,
            addDerusLoading: payload.loading,
          };
        case "DELETE_DERUS":
          return {
            ...state,
            deleteDerusResult: payload.data,
          };
        case "GET_KAPENGWIL":
          return {
            ...state,
            getKapengwilResult: payload.data,
            getKapengwilLoading: payload.loading,
            getKapengwilError: payload.error,
          };
        case "ADD_KAPENGWIL":
          return {
            ...state,
            addKapengwilResult: payload.data,
            addKapengwilLoading: payload.loading,
          };
        case "DELETE_KAPENGWIL":
          return {
            ...state,
            deleteKapengwilResult: payload.data,
          };
        default:
          return state;
      }
    },
  },
});

export const { anggotaReducer } = anggotaReducers.actions;

export default anggotaReducers.reducer;
