import { createSlice } from "@reduxjs/toolkit";

export const masterdataReducers = createSlice({
	name: "masterdata",
	initialState: {
		addDewanPengurusResult: false,
		addDewanPengurusLoading: false,
		getDewanPengurusResult: false,
		getDewanPengurusLoading: false,
		getDewanPengurusError: false,
		deleteDewanPengurusResult: false,

		addDewanPengawasResult: false,
		addDewanPengawasLoading: false,
		getDewanPengawasResult: false,
		getDewanPengawasLoading: false,
		getDewanPengawasError: false,
		deleteDewanPengawasResult: false,

		addJenisKegiatanResult: false,
		addJenisKegiatanLoading: false,
		getJenisKegiatanResult: false,
		getJenisKegiatanLoading: false,
		getJenisKegiatanError: false,
		deleteJenisKegiatanResult: false,

		addJabatanResult: false,
		addJabatanLoading: false,
		getJabatanResult: false,
		getJabatanLoading: false,
		getJabatanError: false,
		deleteJabatanResult: false,

		addPeriodeResult: false,
		addPeriodeLoading: false,
		getPeriodeResult: false,
		getPeriodeLoading: false,
		getPeriodeError: false,
		deletePeriodeResult: false,
	},
	reducers: {
		dewanPengurusReducer: (state, action) => {
			const { type, payload } = action.payload;
			switch (type) {
				case "GET_DEWANPENGURUS":
					return {
						...state,
						getDewanPengurusResult: payload.data,
						getDewanPengurusLoading: payload.loading,
						getDewanPengurusError: payload.error,
					};
				case "ADD_DEWANPENGURUS":
					return {
						...state,
						addDewanPengurusResult: payload.data,
						addDewanPengurusLoading: payload.loading,
					};
				case "DELETE_DEWANPENGURUS":
					return {
						...state,
						deleteDewanPengurusResult: payload.data,
					};
				default:
					return state;
			}
		},
		dewanPengawasReducer: (state, action) => {
			const { type, payload } = action.payload;
			switch (type) {
				case "GET_DEWANPENGAWAS":
					return {
						...state,
						getDewanPengawasResult: payload.data,
						getDewanPengawasLoading: payload.loading,
						getDewanPengawasError: payload.error,
					};
				case "ADD_DEWANPENGAWAS":
					return {
						...state,
						addDewanPengawasResult: payload.data,
						addDewanPengawasLoading: payload.loading,
					};
				case "DELETE_DEWANPENGAWAS":
					return {
						...state,
						deleteDewanPengawasResult: payload.data,
					};
				default:
					return state;
			}
		},
		jenisKegiatanReducer: (state, action) => {
			const { type, payload } = action.payload;
			switch (type) {
				case "GET_JENISKEGIATAN":
					return {
						...state,
						getJenisKegiatanResult: payload.data,
						getJenisKegiatanLoading: payload.loading,
						getJenisKegiatanError: payload.error,
					};
				case "ADD_JENISKEGIATAN":
					return {
						...state,
						addJenisKegiatanResult: payload.data,
						addJenisKegiatanLoading: payload.loading,
					};
				case "DELETE_JENISKEGIATAN":
					return {
						...state,
						deleteJenisKegiatanResult: payload.data,
					};
				default:
					return state;
			}
		},
		jabatanReducer: (state, action) => {
			const { type, payload } = action.payload;
			switch (type) {
				case "GET_JABATAN":
					return {
						...state,
						getJabatanResult: payload.data,
						getJabatanLoading: payload.loading,
						getJabatanError: payload.error,
					};
				case "ADD_JABATAN":
					return {
						...state,
						addJabatanResult: payload.data,
						addJabatanLoading: payload.loading,
					};
				case "DELETE_JABATAN":
					return {
						...state,
						deleteJabatanResult: payload.data,
					};
				default:
					return state;
			}
		},
		periodeReducer: (state, action) => {
			const { type, payload } = action.payload;
			switch (type) {
				case "GET_PERIODE":
					return {
						...state,
						getPeriodeResult: payload.data,
						getPeriodeLoading: payload.loading,
						getPeriodeError: payload.error,
					};
				case "ADD_PERIODE":
					return {
						...state,
						addPeriodeResult: payload.data,
						addPeriodeLoading: payload.loading,
					};
				case "DELETE_PERIODE":
					return {
						...state,
						deletePeriodeResult: payload.data,
					};
				default:
					return state;
			}
		},
	},
});

export const {
	dewanPengurusReducer,
	dewanPengawasReducer,
	jenisKegiatanReducer,
	jabatanReducer,
	periodeReducer,
} = masterdataReducers.actions;

export default masterdataReducers.reducer;
