import axiosAPI from "actions/axiosAPI";
import { ButtonHover } from "components";
import { API_URL_detailproyekgalery, baseurl } from "constants";
import { useEffect } from "react";
import { FiCamera } from "react-icons/fi";
import { TbCameraPlus, TbCircleXFilled } from "react-icons/tb";
import { useDispatch, useSelector } from "react-redux";

const KegiatanGallery = ({
  data,
  setData,
  onDelete,
  setModal,
  onChangeImage,
  kegiatan_id,
}) => {
  const { addDokumentasiKegiatanResult } = useSelector(
    (state) => state.kegiatan
  );

  const dispatch = useDispatch();

  const onFetchDokumentasi = async () => {
    const res = await axiosAPI.get(API_URL_detailproyekgalery + kegiatan_id);
    setData(res.data);
  };

  useEffect(() => {
    if (addDokumentasiKegiatanResult) {
      onFetchDokumentasi();
    }
  }, [addDokumentasiKegiatanResult, dispatch]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    onFetchDokumentasi();
  }, [dispatch]); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <>
      <div className="flex justify-between items-center">
        <div className="font-semibold">Dokumentasi</div>
        {data.length > 0 && onChangeImage && (
          <div
            className={`relative bg-primary-700 hover:bg-primary-800 h-8 w-8 flex items-center justify-center rounded-md`}
          >
            <ButtonHover textHover={"tambah foto lainnya"}>
              <TbCameraPlus className="text-white" />
              <input
                onChange={onChangeImage}
                className="absolute h-full w-full opacity-0 cursor-pointer"
                type="file"
                accept="image/*"
              />
            </ButtonHover>
          </div>
        )}
      </div>

      {data.length > 0 ? (
        <div className="grid grid-cols-4 gap-2 mt-3 ">
          {data.map((item, idx) => {
            return (
              <div
                className="relative group/image flex justify-center items-center object-contain bg-black"
                key={idx}
              >
                {onDelete && (
                  <TbCircleXFilled
                    onClick={() => onDelete(item.id)}
                    size={21}
                    className="text-red-500 absolute top-0 right-0 -mt-2 -mr-2 invisible group-hover/image:visible z-40 hover:cursor-pointer"
                  />
                )}
                <img src={baseurl + item.image} alt="image" className="h-40" />
                {setModal && (
                  <div className="absolute text-white opacity-0 group-hover/image:opacity-100 bg-black/30 h-full w-full flex justify-center items-center transition-all font-bold text-xs">
                    <span
                      className="hover:underline hover:cursor-pointer"
                      onClick={() => setModal(baseurl + item.image)}
                    >
                      Lihat Gambar
                    </span>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      ) : (
        <div className="flex flex-col justify-center items-center p-10 relative">
          {onChangeImage && (
            <input
              onChange={onChangeImage}
              className="absolute h-full w-full opacity-0 cursor-pointer"
              type="file"
              accept="image/*"
            />
          )}
          <FiCamera size={36} className="text-base-500" />
          <div className="text-base-500 text-sm text-center mt-3">
            Upload Gambar
          </div>
        </div>
      )}
    </>
  );
};

export default KegiatanGallery;
