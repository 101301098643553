import { createSlice } from "@reduxjs/toolkit";

export const authReducers = createSlice({
	name: "auth",
	initialState: {
		loginUserResult: false,
		loginUserLoading: false,
		logoutUserResult: false,

		updateAkunResult: false,
		updateAkunLoading: false,

		changePasswordResult: false,
		changePasswordLoading: false,

		onTapNotif: false,
	},
	reducers: {
		authReducer: (state, action) => {
			const { type, payload } = action.payload;
			switch (type) {
				case "LOGIN_USER":
					return {
						...state,
						loginUserResult: payload.data,
						loginUserLoading: payload.loading,
					};
				case "LOGOUT_USER":
					return {
						...state,
						logoutUserResult: payload.data,
					};
				case "UPDATE_AKUN":
					return {
						...state,
						updateAkunResult: payload.data,
						updateAkunLoading: payload.loading,
					};
				case "CHANGE_PASSWORD":
					return {
						...state,
						changePasswordResult: payload.data,
						changePasswordLoading: payload.loading,
					};
				default:
					return state;
			}
		},
		userReducer: (state, action) => {
			const { type, data } = action.payload;
			switch (type) {
				case "ON_TAP_NOTIF":
					return {
						...state,
						onTapNotif: data,
					};
				default:
					return state;
			}
		},
	},
});

export const { authReducer, userReducer } = authReducers.actions;

export default authReducers.reducer;
