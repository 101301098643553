import { ErrorMessages } from "components";
import { baseurl } from "constants";
import { FaFileAlt } from "react-icons/fa";

const KegiatanResume = ({ resumeData, onDownload }) => {
  return (
    <>
      <div className="font-semibold flex flex-col">Resume Kegiatan </div>
      {Object.keys(resumeData).length > 0 ? (
        <div className="w-full">{resumeData.deskripsi}</div>
      ) : (
        <div className="flex flex-col justify-center items-center">
          <ErrorMessages messages="Resume belum ditambahkan" type="nodata" />
        </div>
      )}

      {resumeData?.file && onDownload && (
        <div className="flex gap-3 mt-2">
          {resumeData?.file?.map((item, idx) => (
            <div
              className="relative group/image cursor-pointer flex justify-center items-center h-20 w-36 object-contain border rounded-lg shadow-md"
              key={idx}
              onClick={() =>
                window.open(baseurl + item, "_blank", "noopener,noreferrer")
              }
            >
              <div className="flex flex-col justify-center items-center">
                <FaFileAlt />
                <div className="text-xs mt-1">{item.split("/").pop()}</div>
              </div>
              {/* <img src={baseurl + item.image} alt="image" className="h-40" /> */}
              {/* {setModal && (
                  <div className="absolute text-white opacity-0 group-hover/image:opacity-100 bg-black/30 h-full w-full flex justify-center items-center transition-all font-bold text-xs">
                    <span
                      className="hover:underline hover:cursor-pointer"
                      onClick={() => setModal(baseurl + item.image)}
                    >
                      Lihat Gambar
                    </span>
                  </div>
                )} */}
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default KegiatanResume;
