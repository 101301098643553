import React, { Fragment } from "react";

// components
import ReactPaginate from "react-paginate";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import { HiOutlineDotsHorizontal } from "react-icons/hi";

const Pagination = ({
  handlePageClick,
  pageCount,
  limit,
  setLimit,
  pageActive,
}) => {
  return (
    <Fragment>
      <div className="sm:flex justify-between items-center mt-4">
        <div className="flex items-center">
          <select
            value={limit}
            className="flex bg-white text-xs border border-base-300 rounded-sm h-7 w-12 outline-none pl-1"
            onChange={(e) => setLimit(parseInt(e.target.value))}
          >
            <option value={10}>10</option>
            <option value={25}>25</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </select>
          <div className="ml-2 text-xs">{pageCount} entries</div>
        </div>
        <ReactPaginate
          className="flex justify-end text-base-800"
          activeLinkClassName="border border-gray-400 bg-gray-300"
          pageLinkClassName="bg-white text-xs border border-base-300 w-7 h-7 mx-1 flex rounded-sm justify-center items-center outline-none"
          previousLinkClassName="bg-white text-xs border border-base-300 w-7 h-7 mr-1 flex rounded-sm justify-center items-center"
          nextLinkClassName="bg-white text-xs border border-base-300 w-7 h-7 ml-1 flex rounded-sm justify-center items-center"
          breakLinkClassName="bg-white text-xs border border-base-300 w-7 h-7 mx-1 flex rounded-sm justify-center items-end"
          disabledLinkClassName="text-base-500"
          breakLabel={<HiOutlineDotsHorizontal />}
          renderOnZeroPageCount={null}
          previousLabel={<MdKeyboardArrowLeft />}
          nextLabel={<MdKeyboardArrowRight />}
          pageCount={Math.ceil(pageCount / limit)}
          onPageChange={(e) => handlePageClick(e)}
          marginPagesDisplayed={1}
          pageRangeDisplayed={2}
          forcePage={pageActive}
        />
      </div>
    </Fragment>
  );
};

export default Pagination;
