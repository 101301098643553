import { isAuth } from "actions";
import { updateAkun } from "actions/auth";
import { API_URL_editmyprofile } from "constants";
import { baseurl } from "constants";
import { API_URL_editfotoprofile } from "constants";
import { useFormik } from "formik";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

const Profile = () => {
  const [image, setImage] = useState(null);
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      id: isAuth().user_id,
      nama: isAuth().nama,
      email: isAuth().email,
      gmail: isAuth().gmail,
      no_hp: isAuth().no_hp,
    },
    onSubmit: (values) => {
      updateAkun(
        dispatch,
        {
          pk: values.id,
          email: values.email,
          gmail: values.gmail,
          nama: values.nama,
          no_hp: values.no_hp,
        },
        isAuth().user_id,
        API_URL_editmyprofile
      );
    },
  });

  const onChangeImage = (e) => {
    try {
      const file = e.target.files[0];
      const formData = new FormData();
      formData.append("image", file);
      updateAkun(dispatch, formData, isAuth().user_id, API_URL_editfotoprofile);
      setImage(URL.createObjectURL(file));
    } catch (error) {
      setImage(baseurl + isAuth().image);
    }
  };

  useEffect(() => {
    setImage(baseurl + isAuth().image);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Fragment>
      <div className="flex flex-col gap-3 bg-white p-5 rounded-sm shadow">
        <div className="relative shadow w-40 h-40 bg-gray-400 border text-white rounded-sm flex justify-center items-center overflow-hidden group/profile ">
          {image ? (
            <img
              className="object-cover w-full h-full"
              src={image}
              alt="profile"
            />
          ) : (
            <div className="text-sm">Tidak ada foto</div>
          )}
          <div className="absolute opacity-0 group-hover/profile:opacity-100 bg-black/50 h-full w-full flex justify-center items-center transition-all font-bold">
            Ubah Foto
          </div>
          <input
            onChange={onChangeImage}
            className="absolute h-full w-full opacity-0 cursor-pointer"
            type="file"
            accept="image/*"
          />
        </div>
        <form onSubmit={formik.handleSubmit} className="flex flex-col gap-3">
          <label htmlFor="nama">
            <span className="text-sm font-semibold">Nama</span>
            <input
              id="nama"
              placeholder="Nama"
              className="p-2 text-sm border rounded-sm w-full outline-none"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.nama}
            />
          </label>
          <label htmlFor="email">
            <span className="text-sm font-semibold">Email</span>
            <input
              id="email"
              placeholder="Email"
              className="p-2 text-sm border rounded-sm w-full outline-none"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.email}
            />
          </label>
          <label htmlFor="gmail">
            <span className="text-sm font-semibold">Gmail</span>
            <input
              id="gmail"
              placeholder="Gmail"
              className="p-2 text-sm border rounded-sm w-full outline-none"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.gmail}
            />
          </label>
          <label htmlFor="no_hp">
            <span className="text-sm font-semibold">No. Telepon</span>
            <input
              id="no_hp"
              placeholder="No. Telepon"
              className="p-2 text-sm border rounded-sm w-full outline-none"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.no_hp}
            />
          </label>
          <button
            type="submit"
            className="p-2 bg-primary-700 w-20 text-white text-sm self-end rounded-sm"
          >
            Simpan
          </button>
        </form>
      </div>
    </Fragment>
  );
};

export default Profile;
