import { menu } from "constants/menu";
import { useWindowSize } from "hooks/useWindowSize";
import { Fragment } from "react";
import { BsDashLg } from "react-icons/bs";
import { ImArrowLeft2 } from "react-icons/im";
import Menu from "./Menu";
import Logo from "assets/image/icon-white.png";

const Sidebar = ({ sideOpen, setSideOpen }) => {
  const [width] = useWindowSize();
  return (
    <Fragment>
      {/* backdrop */}
      <div
        onClick={() => setSideOpen(!sideOpen)}
        className={`fixed w-screen h-screen sm:hidden z-10 bg-black transition-opacity duration-700 ${
          sideOpen ? "opacity-40" : "opacity-0 pointer-events-none"
        }`}
      ></div>

      <div
        className={`absolute bg-primary-700 border-r-8 border-primary-700 z-50 text-white flex flex-col h-screen top-0 shadow transition-all duration-700 sm:duration-300 sm:static ${
          sideOpen ? "left-0 w-64" : "-left-64 w-16"
        }`}
      >
        {/* Logo */}
        <div className="flex justify-between items-center h-16">
          <div className="flex-1 h-10 px-2">
            <img
              className="object-contain w-full h-full"
              src={Logo}
              alt="logo"
            />
          </div>

          <div className="w-10 block sm:hidden">
            <ImArrowLeft2
              className="cursor-pointer"
              onClick={() => setSideOpen(false)}
            />
          </div>
        </div>

        {/* Menu */}
        <menu className="overflow-y-auto scrollbar-hide flex-1 text-sm font-semibold">
          <div
            className={`uppercase text-xs px-5 h-16 flex items-center whitespace-nowrap ${
              sideOpen ? "" : "sm:justify-center"
            }`}
          >
            {sideOpen || width < 640 ? "Main Menu" : <BsDashLg size={24} />}
          </div>
          <Menu menu={menu} sideOpen={sideOpen} setSideOpen={setSideOpen} />
        </menu>
      </div>
    </Fragment>
  );
};

export default Sidebar;
