const DEBUG = false;

let localhost = "";
let apiURL = "";
if (DEBUG) {
  localhost = `http://127.0.0.1:8000`;
  apiURL = "/api";
} else {
  localhost = `https://agenda2.apjii.or.id`;
  apiURL = "";
}

export const mediaurl = `${localhost}`;
export const baseurl = `${localhost}${apiURL}`;
export const API_URL_signin = `${baseurl}/signin/`;

// Akun
export const API_URL_getdataakun = `${baseurl}/getdataakun/`;
export const API_URL_createakun = `${baseurl}/createakun/`;
export const API_URL_edelakun = `${baseurl}/edelakun/`;
export const API_URL_getakun = `${baseurl}/getakun/`;
export const API_URL_activeakun = `${baseurl}/activeakun/`;
export const API_URL_editfotoprofile = `${baseurl}/editfotoprofile/`;
export const API_URL_editfotouser = `${baseurl}/editfotouser/`;
export const API_URL_editmyprofile = `${baseurl}/editmyprofile/`;
export const API_URL_changepassword = `${baseurl}/changepassword/`;
export const API_URL_changepassworduser = `${baseurl}/changepassworduser/`;
export const API_URL_getchartdashboard = `${baseurl}/getchartdashboard/`;
export const API_URL_getstatistikdewan = `${baseurl}/getstatistikdewan/`;
export const API_URL_getkalenderkegiatan = `${baseurl}/getkalenderkegiatan/`;

// Jenis Kegiatan
export const API_URL_getjeniskegiatan = `${baseurl}/getjeniskegiatan/`;
export const API_URL_getdatajeniskegiatan = `${baseurl}/getdatajeniskegiatan/`;
export const API_URL_createjeniskegiatan = `${baseurl}/createjeniskegiatan/`;
export const API_URL_edeljeniskegiatan = `${baseurl}/edeljeniskegiatan/`;

// Jabatan
export const API_URL_getjabatan = `${baseurl}/getjabatan/`;
export const API_URL_getdatajabatan = `${baseurl}/getdatajabatan/`;
export const API_URL_createjabatan = `${baseurl}/createjabatan/`;
export const API_URL_edeljabatan = `${baseurl}/edeljabatan/`;

// Periode
export const API_URL_getperiode = `${baseurl}/getperiode/`;
export const API_URL_getdataperiode = `${baseurl}/getdataperiode/`;
export const API_URL_createperiode = `${baseurl}/createperiode/`;
export const API_URL_edelperiode = `${baseurl}/edelperiode/`;

// Template
export const API_URL_gettemplatesurat = `${baseurl}/gettemplatesurat/`;
export const API_URL_gettemplateemail = `${baseurl}/gettemplateemail/`;

// Anggota
export const API_URL_getderus = `${baseurl}/getdewan/`;
export const API_URL_getportal = `${baseurl}/getportal/`;
// derus
export const API_URL_getdewanpengurus = `${baseurl}/getdewanpengurus/`;
export const API_URL_getdatadewanpengurus = `${baseurl}/getdatadewanpengurus/`;
export const API_URL_createdewanpengurus = `${baseurl}/createdewanpengurus/`;
export const API_URL_edeldewanpengurus = `${baseurl}/edeldewanpengurus/`;
export const API_URL_activedewanpengurus = `${baseurl}/activedewanpengurus/`;
// dewas
export const API_URL_getdewanpengawas = `${baseurl}/getdewanpengawas/`;
export const API_URL_getdatadewanpengawas = `${baseurl}/getdatadewanpengawas/`;
export const API_URL_createdewanpengawas = `${baseurl}/createdewanpengawas/`;
export const API_URL_edeldewanpengawas = `${baseurl}/edeldewanpengawas/`;
export const API_URL_activedewanpengawas = `${baseurl}/activedewanpengawas/`;
// kapengwil
export const API_URL_getkapengwil = `${baseurl}/getkapengwil/`;
export const API_URL_getdatakapengwil = `${baseurl}/getdatakapengwil/`;
export const API_URL_createkapengwil = `${baseurl}/createkapengwil/`;
export const API_URL_edelkapengwil = `${baseurl}/edelkapengwil/`;
export const API_URL_activekapengwil = `${baseurl}/activekapengwil/`;

// Kegiatan
export const API_URL_getkegiatan = `${baseurl}/getkegiatan/`;
export const API_URL_getdatakegiatan = `${baseurl}/getdatakegiatan/`;
export const API_URL_createkegiatan = `${baseurl}/createkegiatan/`;
export const API_URL_edelkegiatan = `${baseurl}/edelkegiatan/`;
export const API_URL_publishkegiatan = `${baseurl}/publishkegiatan/`;
export const API_URL_publishkegiatanpenerima = `${baseurl}/publishkegiatanpenerima/`;
export const API_URL_createresume = `${baseurl}/createresume/`;
export const API_URL_getresumekegiatan = `${baseurl}/getresumekegiatan/`;
export const API_URL_responsekegiatan = `${baseurl}/responsekegiatan/`;
export const API_URL_getresponseattendes = `${baseurl}/getresponseattendes/`;
export const API_URL_detailproyekgalery = `${baseurl}/detailproyekgalery/`;
export const API_URL_createdokumentasikegiatan = `${baseurl}/createdokumentasikegiatan/`;

// Riwayat Kegiatan
export const API_URL_getriwayatkegiatan = `${baseurl}/getriwayatkegiatan/`;
export const API_URL_createresponsekegiatan = `${baseurl}/createresponsekegiatan/`;

// Notif
export const API_URL_getnotif = `${baseurl}/getnotif/`;
export const API_URL_isread = `${baseurl}/isread/`;
