import { createSlice } from "@reduxjs/toolkit";

export const kegiatanReducers = createSlice({
  name: "kegiatan",
  initialState: {
    addKegiatanResult: false,
    addKegiatanLoading: false,
    addResumeKegiatanResult: false,
    addResumeKegiatanLoading: false,
    addResponseKegiatanResult: false,
    addResponseKegiatanLoading: false,
    addDokumentasiKegiatanResult: false,
    addDokumentasiKegiatanLoading: false,
    getKegiatanResult: false,
    getKegiatanLoading: false,
    getKegiatanError: false,
    deleteKegiatanResult: false,

    addRiwayatKegiatanResult: false,
    addRiwayatKegiatanLoading: false,
  },
  reducers: {
    kegiatanReducer: (state, action) => {
      const { type, payload } = action.payload;
      switch (type) {
        case "ADD_DOKUMENTASI":
          return {
            ...state,
            addDokumentasiKegiatanResult: payload.data,
            addDokumentasiKegiatanLoading: payload.loading,
          };
        case "ADD_RESPONSE":
          return {
            ...state,
            addResponseKegiatanResult: payload.data,
            addResponseKegiatanLoading: payload.loading,
          };
        case "ADD_RESUMEKEGIATAN":
          return {
            ...state,
            addResumeKegiatanResult: payload.data,
            addResumeKegiatanLoading: payload.loading,
          };
        case "GET_KEGIATAN":
          return {
            ...state,
            getKegiatanResult: payload.data,
            getKegiatanLoading: payload.loading,
            getKegiatanError: payload.error,
          };
        case "ADD_KEGIATAN":
          return {
            ...state,
            addKegiatanResult: payload.data,
            addKegiatanLoading: payload.loading,
          };
        case "DELETE_KEGIATAN":
          return {
            ...state,
            deleteKegiatanResult: payload.data,
          };
        default:
          return state;
      }
    },
    riwayatReducer: (state, action) => {
      const { type, payload } = action.payload;
      switch (type) {
        case "ADD_RIWAYAT":
          return {
            ...state,
            addRiwayatKegiatanResult: payload.data,
            addRiwayatKegiatanLoading: payload.loading,
          };
        default:
          return state;
      }
    },
  },
});

export const { kegiatanReducer, riwayatReducer } = kegiatanReducers.actions;

export default kegiatanReducers.reducer;
