import { Fragment, useCallback, useState } from "react";

import { addData, deleteData, getData, updateData } from "actions";
import axiosAPI from "actions/axiosAPI";
import {
  ButtonHover,
  ErrorMessages,
  HeadContent,
  Modal,
  ModalContent,
  Pagination,
  ToggleSwitch,
} from "components";
import {
  API_URL_edeldewanpengawas,
  API_URL_getakun,
  API_URL_getjabatan,
  API_URL_getperiode,
} from "constants";
import { useFormik } from "formik";
import { useEffect } from "react";
import { AiOutlineCheck, AiOutlineLoading3Quarters } from "react-icons/ai";
import { FiEdit } from "react-icons/fi";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { anggotaReducer } from "reducers/anggotaReducers";
import * as Yup from "yup";
import { API_URL_edelkapengwil } from "constants";
import { API_URL_createkapengwil } from "constants";
import { API_URL_getdatakapengwil } from "constants";
import { API_URL_activekapengwil } from "constants";

const Kapengwil = () => {
  const dispatch = useDispatch();
  const {
    addKapengwilResult,
    addKapengwilLoading,
    getKapengwilResult,
    getKapengwilLoading,
    getKapengwilError,
    deleteKapengwilResult,
  } = useSelector((state) => state.anggota);

  const [limit, setLimit] = useState(10);
  const [pageActive, setPageActive] = useState(0);

  const [search, setSearch] = useState("");
  const [modal, setModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalFilter, setModalFilter] = useState(false);
  const [filterPeriode, setFilterPeriode] = useState("");

  const [akun, setAkun] = useState([]);
  const [jabatan, setJabatan] = useState([]);
  const [periode, setPeriode] = useState([]);

  const formik = useFormik({
    initialValues: {
      id: "",
      akun: [],
      jabatan: [],
      periode: [],
      is_active: false,
    },
    validationSchema: Yup.object({
      akun: Yup.array()
        .required("Nama harus diisi!")
        .min(1, "Nama harus diisi!"),
      jabatan: Yup.array()
        .required("Jabatan harus diisi!")
        .min(1, "Jabatan harus diisi!"),
      periode: Yup.array()
        .required("Periode harus diisi!")
        .min(1, "Periode harus diisi!"),
    }),
    onSubmit: (values, helpers) => {
      if (values.id) {
        updateData(
          { dispatch, redux: anggotaReducer },
          {
            pk: values.id,
            akun: values.akun[0].value,
            jabatan: values.jabatan[0].value,
            periode: values.periode[0].value,
            is_active: values.is_active,
          },
          API_URL_edelkapengwil,
          "ADD_KAPENGWIL"
        );
      } else {
        addData(
          { dispatch, redux: anggotaReducer },
          {
            akun: values.akun[0].value,
            jabatan: values.jabatan[0].value,
            periode: values.periode[0].value,
            is_active: values.is_active,
          },
          API_URL_createkapengwil,
          "ADD_KAPENGWIL"
        );
      }
    },
  });

  const handlePageClick = (e) => {
    const offset = e.selected * limit;
    const param = {};
    if (search !== "") {
      param.search = search;
    }
    param.periode = filterPeriode;
    param.limit = limit;
    param.offset = offset;
    get(param);
    setPageActive(e.selected);
  };

  const handleSelect = (e) => {
    const param = {};
    if (search !== "") {
      param.search = search;
    }
    param.limit = e;
    param.periode = filterPeriode;
    get(param);
    setLimit(e);
    setPageActive(0);
  };

  const onAdd = () => {
    formik.resetForm();
    setModal(true);
    setModalTitle("Tambah Data");
  };

  const onEdit = (item) => {
    formik.setValues({
      id: item.id,
      akun: [{ value: item.user.id, label: item.user.nama }],
      jabatan: [{ value: item.jabatan.id, label: item.jabatan.nama }],
      periode: [{ value: item.periode.id, label: item.periode.nama }],
      is_active: item.is_active,
    });
    setModal(true);
    setModalTitle("Edit Data");
  };

  const onDelete = (item) => {
    deleteData(
      { dispatch, redux: anggotaReducer },
      item.id,
      API_URL_edeldewanpengawas,
      "DELETE_KAPENGWIL"
    );
  };

  const onSearch = (e) => {
    const { value } = e.target;
    setSearch(value);
    setLimit(10);
    get({ search: value, periode: filterPeriode });
    setPageActive(0);
  };

  const onFilter = (item) => {
    setModalFilter(false);
    setFilterPeriode(item.id);
    const param = {};
    if (search !== "") {
      param.search = search;
    }
    param.periode = item.id;
    get(param);
    setPageActive(0);
  };

  const onChangePeriode = async (e) => {
    formik.setFieldValue("periode", [e]);

    const res_akun = await axiosAPI.get(
      API_URL_getakun + "?periode=" + e.value
    );
    setAkun(res_akun.data);
  };

  const onChangeActive = (item) => {
    updateData(
      { dispatch, redux: anggotaReducer },
      {
        pk: item.id,
        is_active: !item.is_active,
      },
      API_URL_activekapengwil,
      "ADD_KAPENGWIL"
    );
  };

  const get = useCallback(
    async (param) => {
      getData(
        { dispatch, redux: anggotaReducer },
        param,
        API_URL_getdatakapengwil,
        "GET_KAPENGWIL"
      );
    },
    [dispatch]
  );

  const fetchData = useCallback(
    async (param = false) => {
      setModal(false);
      const res_periode = await axiosAPI.get(API_URL_getperiode);
      setPeriode(res_periode.data);
      if (param) {
        const params = { ...param };
        if (res_periode.data.length > 0) {
          params.periode = res_periode.data[0].id;
          setFilterPeriode(res_periode.data[0].id);
          get(params);
        } else {
          get(param);
        }
      } else {
        if (res_periode.data.length > 0) {
          setFilterPeriode(res_periode.data[0].id);
          get({
            limit: limit,
            periode: res_periode.data[0].id,
          });
        } else {
          get({ limit: limit });
        }
      }

      const res_jabatan = await axiosAPI.get(API_URL_getjabatan, {
        params: { type: 3 },
      });
      setJabatan(res_jabatan.data);
    },
    [get] // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (addKapengwilResult || deleteKapengwilResult) {
      const offset = pageActive * limit;
      const param = {};
      if (search !== "") {
        param.search = search;
      }
      param.periode = filterPeriode;
      param.limit = limit;
      param.offset = offset;
      fetchData(param);
    }
  }, [addKapengwilResult, deleteKapengwilResult, dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Fragment>
      <HeadContent
        isFilter={periode.filter((item) => item.id === filterPeriode)[0]?.nama}
        onFilter={() => setModalFilter(true)}
        onSearch={onSearch}
        onAdd={onAdd}
      />

      {/* Table */}
      <div className="bg-white rounded-sm shadow">
        <div className="flex justify-between items-center p-4 border-b">
          <div>Kapengwil</div>
          <div className="text-sm">
            {periode.filter((item) => item.id === filterPeriode)[0]?.nama}
          </div>
        </div>
        <div className="p-4 pt-0">
          <div className="overflow-y-auto">
            <table className="w-full text-xs sm:text-sm">
              <thead>
                <tr className="text-left text-base-500 border-b">
                  <th className="p-2">No</th>
                  <th className="p-2">Nama</th>
                  <th className="p-2">Jabatan</th>
                  <th className="p-2">Periode</th>
                  <th className="p-2 text-center">Active</th>
                  <th className="p-2 text-center">Aksi</th>
                </tr>
              </thead>
              <tbody>
                {/* Loading */}
                {getKapengwilLoading ? (
                  <tr className="text-base-800">
                    <td className="p-10" colSpan="6">
                      <div className="flex justify-center items-center">
                        <AiOutlineLoading3Quarters className="animate-spin text-xl" />
                      </div>
                    </td>
                  </tr>
                ) : // No Data
                getKapengwilResult.count === 0 ? (
                  <tr className="text-base-800">
                    <td className="p-10" colSpan="6">
                      <div className="flex flex-col justify-center items-center">
                        <ErrorMessages
                          messages="Data tidak ditemukan"
                          type="nodata"
                        />
                      </div>
                    </td>
                  </tr>
                ) : // Data
                getKapengwilResult.count > 0 ? (
                  getKapengwilResult.results.map((item, itemIdx) => (
                    <tr className="text-base-800 border-b" key={itemIdx}>
                      <td className="p-2">
                        {pageActive * limit + itemIdx + 1}
                      </td>
                      <td className="p-2">{item.user?.nama}</td>
                      <td className="p-2">{item.jabatan?.nama}</td>
                      <td className="p-2">{item.periode?.nama}</td>
                      <td className="p-2 text-center">
                        <ToggleSwitch
                          onChange={() => onChangeActive(item)}
                          value={item.is_active}
                        />
                      </td>
                      <td className="p-2">
                        <div className="flex justify-center gap-x-2 text-base">
                          <ButtonHover textHover="Edit">
                            <FiEdit
                              onClick={() => onEdit(item)}
                              className="cursor-pointer text-blue-500"
                            />
                          </ButtonHover>
                          <ButtonHover placement="right" textHover="Delete">
                            <RiDeleteBin6Line
                              onClick={() => onDelete(item)}
                              className="cursor-pointer text-red-500"
                            />
                          </ButtonHover>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : // Error
                getKapengwilError ? (
                  <tr className="text-base-800">
                    <td className="p-10" colSpan="4">
                      <div className="flex flex-col justify-center items-center">
                        <ErrorMessages
                          messages={getKapengwilError}
                          type="error"
                        />
                      </div>
                    </td>
                  </tr>
                ) : null}
              </tbody>
            </table>
          </div>
          <Pagination
            handlePageClick={handlePageClick}
            pageCount={
              getKapengwilResult.count > 0 ? getKapengwilResult.count : 0
            }
            limit={limit}
            setLimit={handleSelect}
            pageActive={pageActive}
          />
        </div>
      </div>

      {/* Modal */}
      <Modal
        title={modalTitle}
        sizeModal="sm"
        dismiss
        show={modal}
        setShow={setModal}
      >
        {/* Content */}
        <div className="p-5">
          <form onSubmit={formik.handleSubmit}>
            {/* Select Periode */}
            <div className="mb-2">
              <label className="required" htmlFor="periode">
                Periode
              </label>
              <Select
                isSearchable={true}
                name="periode"
                options={periode.map((item) => ({
                  value: item.id,
                  label: item.nama,
                }))}
                value={formik.values.periode}
                onChange={(e) => onChangePeriode(e)}
              />
              {formik.touched.periode && formik.errors.periode ? (
                <div className="text-red-500 text-xs">
                  {formik.errors.periode}
                </div>
              ) : null}
            </div>

            {/* Select Nama */}
            <div className="mb-2">
              <label className="required" htmlFor="akun">
                Nama
              </label>
              <Select
                isSearchable={true}
                isDisabled={formik.values.periode.length === 0 ? true : false}
                name="akun"
                options={akun.map((item) => ({
                  value: item.id,
                  label: item.nama,
                }))}
                value={formik.values.akun}
                onChange={(e) => {
                  formik.setFieldValue("akun", [e]);
                }}
              />
              {formik.touched.akun && formik.errors.akun ? (
                <div className="text-red-500 text-xs">{formik.errors.akun}</div>
              ) : null}
            </div>

            {/* Select Jabatan */}
            <div className="mb-2">
              <label className="required" htmlFor="jabatan">
                Jabatan
              </label>
              <Select
                isSearchable={true}
                name="jabatan"
                options={jabatan.map((item) => ({
                  value: item.id,
                  label: item.nama,
                }))}
                value={formik.values.jabatan}
                onChange={(e) => {
                  formik.setFieldValue("jabatan", [e]);
                }}
              />
              {formik.touched.jabatan && formik.errors.jabatan ? (
                <div className="text-red-500 text-xs">
                  {formik.errors.jabatan}
                </div>
              ) : null}
            </div>

            {/* Input Is Active */}
            <div className="flex items-center gap-2 mb-2">
              <input
                id="is_active"
                name="is_active"
                type="checkbox"
                className="w-4 h-4"
                onChange={formik.handleChange}
                checked={formik.values.is_active}
              />
              <label htmlFor="is_active">Active</label>
            </div>
          </form>
        </div>

        {/* Footer */}
        <div className="space-x-2 h-full flex justify-end items-center">
          <button
            className="p-2 px-4 border text-sm rounded-sm"
            type="submit"
            onClick={() => setModal(false)}
          >
            Cancel
          </button>
          <button
            className="p-2 px-4 w-20 flex justify-center items-center bg-primary-700 text-white text-sm rounded-sm"
            type="submit"
            disabled={addKapengwilLoading}
            onClick={formik.handleSubmit}
          >
            {addKapengwilLoading ? (
              <AiOutlineLoading3Quarters className="animate-spin text-xl" />
            ) : (
              "Submit"
            )}
          </button>
        </div>
      </Modal>

      <ModalContent dismiss show={modalFilter} setShow={setModalFilter}>
        <div className="w-[400px] h-[400px] overflow-y-auto">
          <div className="p-5 font-semibold text-lg border-b sticky top-0 bg-white">
            Periode
          </div>
          <div>
            {periode.map((item) => (
              <div
                onClick={() => {
                  filterPeriode !== item.id && onFilter(item);
                }}
                key={item.id}
                className="p-5 border-b cursor-pointer hover:bg-gray-50 transition-all flex justify-between items-center"
              >
                <span>{item.nama}</span>
                {filterPeriode === item.id && (
                  <AiOutlineCheck className="text-primary-700" />
                )}
              </div>
            ))}
          </div>
        </div>
      </ModalContent>
    </Fragment>
  );
};

export default Kapengwil;
