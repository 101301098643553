import { configureStore } from "@reduxjs/toolkit";
import authReducers from "./authReducers";
import masterdataReducers from "./masterdataReducers";
import akunReducers from "./akunReducers";
import anggotaReducers from "./anggotaReducers";
import kegiatanReducers from "./kegiatanReducers";

export default configureStore({
	reducer: {
		auth: authReducers,
		masterdata: masterdataReducers,
		akun: akunReducers,
		anggota: anggotaReducers,
		kegiatan: kegiatanReducers,
	},
});
