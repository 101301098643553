import React from "react";

const ToggleSwitch = ({ value, onChange }) => {
	return (
		<label className="relative inline-flex items-center cursor-pointer">
			<input
				onChange={(e) => onChange(e)}
				checked={value}
				type="checkbox"
				className="peer absolute z-10 sr-only cursor-pointer"
			/>
			<div className="w-7 h-4 bg-gray-200 rounded-full peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-3 after:w-3 after:transition-all peer-checked:bg-primary-600"></div>
		</label>
	);
};

export default ToggleSwitch;
