import React from "react";
import { FiFilter, FiSearch } from "react-icons/fi";

const HeadContent = ({ title, desc, onSearch, onAdd, isFilter, onFilter }) => {
	return (
		<div className="block mb-4 sm:mb-8 items-center justify-between md:flex">
			<div className="mb-2 md:mb-0 mr-2">
				<h1 className="text-2xl font-bold">{title}</h1>
				<p className="text-xs">{desc}</p>
			</div>
			<div className="flex gap-x-2">
				{/* Search */}
				{onSearch && (
					<label className="bg-white w-60 h-10 p-2 outline-none rounded-sm shadow flex">
						<div className="p-1 mr-2">
							<FiSearch />
						</div>
						<input
							type="text"
							className="outline-none w-full text-xs"
							placeholder="Cari"
							onChange={onSearch}
						/>
					</label>
				)}

				{/* Filter */}
				{isFilter && (
					<button
						onClick={onFilter}
						className="flex justify-center items-center h-10 min-w-14 px-4 gap-4 whitespace-nowrap text-primary-700 bg-white hover:bg-base-100 text-sm font-semibold rounded-sm shadow transition-all"
					>
						<FiFilter /> {isFilter}
					</button>
				)}

				{/* Add */}
				{onAdd && (
					<button
						onClick={onAdd}
						className="flex justify-center items-center h-10 w-10 bg-primary-700 hover:bg-primary-800 text-white text-sm font-semibold rounded-sm shadow transition-all"
					>
						+
					</button>
				)}
			</div>
		</div>
	);
};

export default HeadContent;
