import { Disclosure, Popover, Transition } from "@headlessui/react";
import { isAuth } from "actions";
import { useWindowSize } from "hooks/useWindowSize";
import React, { useState } from "react";
import { BsFillCaretRightFill } from "react-icons/bs";
import { NavLink, useLocation } from "react-router-dom";

const Menu = ({ menu, sideOpen, setSideOpen }) => {
  const [width] = useWindowSize();
  const { pathname } = useLocation();

  const initNav = menu
    .filter((item) => item.sub.length > 0)
    .reduce((acc, item) => {
      acc[item.name] = false;
      return acc;
    }, {});
  const [navopen, setNavopen] = useState(initNav);
  const navOpen = (data) => {
    setNavopen({ ...initNav, [data]: !navopen[data] });
  };

  return (
    <>
      {menu.map((item, itemIdx) => {
        if (
          item.level.filter((item) => isAuth().level.includes(item)).length > 0
        ) {
          if (item.sub.length === 0) {
            return (
              <NavLink
                title={item.title}
                className="relative whitespace-nowrap"
                key={itemIdx}
                onClick={() => {
                  if (width < 640) setSideOpen(false);
                }}
                to={item.path}
              >
                {({ isActive }) => (
                  <div
                    className={`flex items-center justify-center w-full transition-colors
										${
                      isActive
                        ? "text-primary-700 font-bold bg-gray-100"
                        : "hover:text-primary-200"
                    }
										${sideOpen ? "px-5 py-4" : "px-5 py-4 sm:p-[2px]"}
										${!sideOpen && isActive ? "sm:bg-transparent" : ""}
										${sideOpen && isActive ? "bg-gray-100" : ""}`}
                  >
                    <div
                      className={`
											${sideOpen ? "" : "sm:text-2xl sm:p-3"}
											${
                        !sideOpen && isActive
                          ? "sm:bg-gray-100 sm:text-primary-700 font-bold sm:rounded-sm sm:shadow"
                          : ""
                      }`}
                    >
                      {item.icon}
                    </div>
                    <div
                      className={`flex-1 ml-4 
											${sideOpen ? "" : "block sm:hidden"}`}
                    >
                      {item.title}
                    </div>
                  </div>
                )}
              </NavLink>
            );
          }

          if ((item.sub.length > 0 && sideOpen) || width < 640) {
            return (
              <Disclosure
                title={item.title}
                as={"div"}
                className={`relative whitespace-nowrap
								${pathname.includes(item.name) || navopen[item.name] ? "bg-gray-100" : ""}`}
                key={itemIdx}
              >
                <Disclosure.Button
                  onClick={() => navOpen(item.name)}
                  className={`flex items-center w-full px-5 py-4
									${
                    pathname.includes(item.name) || navopen[item.name]
                      ? "text-primary-700 font-bold bg-gray-100"
                      : ""
                  }`}
                >
                  <div>{item.icon}</div>
                  <div className="flex items-center w-full justify-between ml-4">
                    <div>{item.title}</div>
                    <BsFillCaretRightFill
                      size={10}
                      className={`
											${navopen[item.name] ? "rotate-90" : ""} transition-transform`}
                    />
                  </div>
                </Disclosure.Button>
                <Transition
                  show={navopen[item.name]}
                  className="overflow-hidden"
                  enter="transition-[max-height] duration-500 ease-in"
                  enterFrom="max-h-0"
                  enterTo="max-h-screen"
                  leave="transition-[max-height] duration-500 ease-out"
                  leaveFrom="max-h-screen"
                  leaveTo="max-h-0"
                >
                  <Disclosure.Panel>
                    {item.sub.map((subItem, subItemIdx) => (
                      <NavLink
                        key={subItemIdx}
                        to={subItem.path}
                        onClick={() => {
                          if (width < 640) setSideOpen(false);
                        }}
                        className="flex items-center text-sm p-5 py-4 pl-[50px]"
                      >
                        {({ isActive }) => (
                          <div
                            className={`transition-all
														${pathname.includes(item.name) ? "" : "text-base-600"}
														${
                              isActive
                                ? "bg-gray-100 text-primary-700 hover:text-primary-800 font-bold"
                                : " text-primary-700 hover:text-primary-900"
                            }`}
                          >
                            {subItem.title}
                          </div>
                        )}
                      </NavLink>
                    ))}
                  </Disclosure.Panel>
                </Transition>
              </Disclosure>
            );
          }

          if (item.sub.length > 0 && !sideOpen) {
            return (
              <Popover key={itemIdx} as={"div"}>
                <Popover.Button
                  title={item.title}
                  onClick={() => navOpen(item.name)}
                  className="flex items-center justify-center w-full text-sm p-[2px]"
                >
                  <div
                    className={`text-2xl p-3 ${
                      pathname.includes(item.name) ? "menu-button-active" : ""
                    }`}
                  >
                    {item.icon}
                  </div>
                </Popover.Button>
                <Transition
                  show={navopen[menu.name]}
                  className="absolute left-16 hidden sm:block"
                  enter="transition duration-100 ease-out"
                  enterFrom="transform scale-95 opacity-0"
                  enterTo="transform scale-100 opacity-100"
                  leave="transition duration-75 ease-out"
                  leaveFrom="transform scale-100 opacity-100"
                  leaveTo="transform scale-95 opacity-0"
                >
                  <Popover.Panel className="w-40 bg-white text-primary-700 rounded-sm overflow-hidden shadow flex flex-col absolute -top-[51px] left-3">
                    <div className="p-5 py-3 uppercase text-xs">
                      {item.title}
                    </div>
                    {item.sub.map((subItem, subItemIdx) => (
                      <NavLink key={subItemIdx} to={subItem.path}>
                        {({ isActive }) => (
                          <div
                            className={`p-5 py-3 transition ${
                              isActive
                                ? "bg-gray-100 text-primary-700 font-bold"
                                : ""
                            }`}
                          >
                            {subItem.title}
                          </div>
                        )}
                      </NavLink>
                    ))}
                  </Popover.Panel>
                </Transition>
              </Popover>
            );
          }
        }

        return null;
      })}
    </>
  );
};

export default Menu;
