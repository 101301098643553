import { FiArrowDownRight, FiArrowUpRight } from "react-icons/fi";
import { numberWithCommas } from "utils/numberWithCommas";

const CardDashboard = ({ title, value, valueBefore, Icons }) => {
  return (
    <div className="w-full h-24 bg-white rounded-sm shadow p-5 flex flex-col">
      <div className="flex justify-between items-start">
        <div className="leading-none font-semibold text-sm">{title}</div>
        {Icons && (
          <Icons className="p-2 w-8 h-8 flex justify-center items-center rounded-sm bg-primary-500/10 text-primary-500" />
        )}
      </div>
      <div className="flex flex-1 justify-between items-end">
        <div className="text-xl font-semibold">{numberWithCommas(value)}</div>
        {value && valueBefore ? (
          <div className="text-sm">
            {value - valueBefore > 0 ? (
              <span className="text-green-500 flex gap-1 items-center">
                <FiArrowUpRight /> +
                {(((value - valueBefore) / valueBefore) * 100).toFixed(1)} %
              </span>
            ) : (
              <span className="text-red-500 flex gap-1 items-center">
                <FiArrowDownRight />{" "}
                {(((value - valueBefore) / valueBefore) * 100).toFixed(1)} %
              </span>
            )}
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default CardDashboard;
