import { isAuth } from "actions";
import { loginUser } from "actions/auth";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { HiEyeOff } from "react-icons/hi";
import { HiEye } from "react-icons/hi2";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import OrangApjii from "assets/image/orangapjii.png";
import LogoApjii from "assets/image/icon-white.png";

const validation = Yup.object({
  username: Yup.string().required("Username harus diisi"),
  password: Yup.string().required("Password harus diisi"),
});

const Login = () => {
  const {
    loginUserResult,
    loginUserLoading,
    // loginUserError
  } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const onSubmit = (values) => {
    loginUser(dispatch, values);
  };
  const [showPassword, setShowPassword] = useState(false);

  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: validation,
    onSubmit: onSubmit,
  });

  useEffect(() => {
    const level = ["User", "Admin", "Super Admin"];
    if (
      // false
      loginUserResult ||
      (isAuth() &&
        level.filter((item) => isAuth().level.includes(item)).length > 0)
    ) {
      window.location.href = "/";
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginUserResult]);

  return (
    <div className="w-screen h-screen flex justify-center items-end bg-white">
      <img className="w-full h-full" src={OrangApjii} alt="foto" />
      {/* Login */}
      <div className="absolute z-50 bg-blue-400/30 backdrop-blur w-96 py-10 m-2 mb-20 rounded-sm shadow-lg">
        <form onSubmit={formik.handleSubmit}>
          <div className="w-full flex flex-col items-center gap-y-5">
            <img className="w-48" src={LogoApjii} alt="foto" />
            <h1 className="w-4/5 h-16 text-center flex items-center text-2xl font-bold text-white">
              Masuk
            </h1>
            <div className="w-4/5">
              <label
                htmlFor="username"
                className="text-sm text-white font-semibold"
              >
                Email or username
              </label>
              <input
                id="username"
                name="username"
                type="text"
                placeholder="Masukkan email or username"
                className="w-full pt-2 pb-1 border-b border-gray-300 outline-none text-cyan-50 text-sm bg-transparent"
                onChange={formik.handleChange}
                autoComplete="off"
                value={formik.values.username}
              />
              {formik.errors.username && formik.touched.username ? (
                <div className="text-red-500 text-xs mt-1">
                  *{formik.errors.username}
                </div>
              ) : null}
            </div>
            <div className="w-4/5">
              <label
                htmlFor="password"
                className="text-sm text-white font-semibold"
              >
                Password
              </label>
              <div className="relative">
                <input
                  id="password"
                  placeholder="Masukkan Password"
                  onChange={formik.handleChange}
                  value={formik.values.password}
                  autoComplete="off"
                  className="w-full pt-2 pb-1 border-b border-gray-300 outline-none text-cyan-50 text-sm bg-transparent"
                  type={showPassword ? "text" : "password"}
                />
                <div className="absolute top-2.5 right-3 text-white cursor-pointer">
                  {showPassword ? (
                    <HiEyeOff onClick={() => setShowPassword(false)} />
                  ) : (
                    <HiEye onClick={() => setShowPassword(true)} />
                  )}
                </div>
              </div>
              {formik.errors.password && formik.touched.password ? (
                <div className="text-red-500 text-xs mt-1">
                  *{formik.errors.password}
                </div>
              ) : null}
            </div>
            <div className="w-4/5">
              <button
                type="submit"
                onClick={formik.handleSubmit}
                disabled={loginUserLoading}
                className="w-full flex items-center justify-center text-sm font-semibold bg-primary-500 active:bg-primary-600 text-white py-2 rounded-sm disabled:bg-primary-600"
              >
                {loginUserLoading ? (
                  <>
                    <AiOutlineLoading3Quarters className="animate-spin mr-2" />
                    Loading...
                  </>
                ) : (
                  "Masuk"
                )}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
