import React from "react";
import AssetsImage from "assets/image/PageForbidden.svg";
import { logout } from "actions";
import { AiOutlineArrowLeft } from "react-icons/ai";

const Forbidden = () => {
  return (
    <div className="h-screen w-screen flex justify-center flex-col items-center bg-base p-3">
      <img src={AssetsImage} alt="" className="w-1/2 md:w-1/3" />
      <div className="text-sm mt-5 font-medium flex items-center">
        Oops, You Don't Have Permission To Access This Site.
      </div>
      <div
        onClick={logout}
        className="text-sm mt-5 font-medium flex items-center hover:text-primary-600 hover:cursor-pointer"
      >
        <span className="mr-1">
          <AiOutlineArrowLeft />
        </span>{" "}
        Go Back
      </div>
    </div>
  );
};

export default Forbidden;
