import axios from "axios";
import { getToken, logout } from "actions";

const axiosAPI = axios.create({
  //   timeout: 5000,
  headers: {
    Authorization: getToken() ? `Token  ${getToken()}` : null,
    "Content-Type": "application/json",
    accept: "application/json",
  },
});

axiosAPI.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (error.response.status === 403) {
      originalRequest.headers["Authorization"] = "Token " + getToken();
      return axiosAPI(originalRequest);
    }

    if (error.response.status === 401) {
      logout();
      return Promise.reject(error);
    }
    // if (error.response.status === 403) {
    // logout();
    //   return Promise.reject(error);
    // }

    return Promise.reject(error);
  }
);

export default axiosAPI;
