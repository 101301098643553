import { Transition } from "@headlessui/react";
import React from "react";
import { FiX } from "react-icons/fi";

const ModalContent = ({
	show,
	setShow,
	dismiss,
	children,
	closeButton,
	container,
}) => {
	const onDismiss = () => {
		if (dismiss) {
			setShow(false);
		}
	};

	return (
		<Transition
			show={show}
			className="fixed inset-0 z-50 h-full w-full p-5 flex flex-col items-center justify-center overflow-auto"
		>
			{/* Backdrop */}
			<Transition.Child
				as="div"
				onClick={onDismiss}
				enterFrom="opacity-0"
				enterTo="opacity-100"
				leaveFrom="opacity-100"
				leaveTo="opacity-0"
				className="w-full h-full absolute bg-black bg-opacity-50 transition-opacity duration-500"
			/>

			{/* Head */}
			<Transition.Child
				as="div"
				enterFrom="opacity-0 -translate-y-6"
				enterTo="opacity-100 translate-y-0"
				leaveFrom="opacity-100 translate-y-0"
				leaveTo="opacity-0 -translate-y-6"
				className={`flex flex-col items-center justify-center transition-all duration-700 pointer-events-none ${
					container ? "w-full md:w-5/6 h-full" : "max-w-[80vw] max-h-full"
				}`}
			>
				{/* close */}
				<div className="bg-white h-full w-full shadow-lg overflow-hidden pointer-events-auto rounded">
					{closeButton && (
						<button
							onClick={() => setShow(false)}
							className="cursor-pointer absolute top-5 right-5 z-[999] bg-white rounded-full p-2 border"
						>
							<FiX size={16} color="black" />
						</button>
					)}
					{children}
				</div>
			</Transition.Child>
		</Transition>
	);
};

export default ModalContent;
