import dayGridPlugin from "@fullcalendar/daygrid";
import listPlugin from "@fullcalendar/list";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import { getTanggal, isAuth, textArray } from "actions";
import axiosAPI from "actions/axiosAPI";
import { CardDashboard, ErrorMessages, Modal, ModalContent } from "components";
import {
  API_URL_getchartdashboard,
  API_URL_getkalenderkegiatan,
  API_URL_getkegiatan,
  API_URL_getperiode,
  API_URL_getresumekegiatan,
  API_URL_getstatistikdewan,
} from "constants";
import moment from "moment";
import KegiatanGallery from "pages/Kegiatan/KegiatanGallery";
import KegiatanResume from "pages/Kegiatan/KegiatanResume";
import { Fragment, useCallback, useEffect, useRef, useState } from "react";
import { AiOutlineCheck } from "react-icons/ai";
import { BsCheckAll } from "react-icons/bs";
import { FaTasks } from "react-icons/fa";
import { FiActivity, FiFilter } from "react-icons/fi";
import { MdEvent } from "react-icons/md";
import { TbReport } from "react-icons/tb";
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import listName from "utils/listName";

const Dashboard = () => {
  const calendarRef = useRef(null);

  const [modalFilter, setModalFilter] = useState(false);
  const [periode, setPeriode] = useState([]);
  const [filterPeriode, setFilterPeriode] = useState();
  const [data, setData] = useState([]);
  const [statistik, setStatistik] = useState({});
  const [dataEvent, setDataEvent] = useState([]);

  const [modalEvent, setModalEvent] = useState(false);
  const [event, setEvent] = useState({});
  const [dataGallery, setDataGallery] = useState([]);
  const [resumeData, setResumeData] = useState({});

  const onFilter = async (item) => {
    setModalFilter(false);
    setFilterPeriode(item.id);
    const res_chart = await axiosAPI.get(API_URL_getchartdashboard, {
      params: { periode: item.id },
    });
    setData(res_chart.data);
  };

  const getEvent = async (month_year = false) => {
    var params = {};
    if (month_year) {
      params = { "month-year": month_year };
    }
    const res = await axiosAPI.get(API_URL_getkalenderkegiatan, {
      params: params,
    });
    setDataEvent(res.data);
  };

  const onEvent = async (e) => {
    const kegiatan_id = e.event.extendedProps.pk;
    const res_kegiatan = await axiosAPI.post(API_URL_getkegiatan, {
      kegiatan_id,
    });
    setEvent(res_kegiatan.data);

    const res_resume = await axiosAPI.post(API_URL_getresumekegiatan, {
      kegiatan_id,
    });
    setResumeData(res_resume.data);
    setModalEvent(true);
  };

  const fetchData = useCallback(async () => {
    setModalFilter(false);
    const res_periode = await axiosAPI.get(API_URL_getperiode);
    setPeriode(res_periode.data);
    setFilterPeriode(res_periode.data[0].id);
    const res_chart = await axiosAPI.get(API_URL_getchartdashboard, {
      params: { periode: res_periode.data[0].id },
    });
    setData(res_chart.data);
    getEvent();

    const params = {
      user_id: isAuth().user_id,
    };
    if (
      isAuth().level.filter((item) => ["Admin", "Super Admin"].includes(item))
        .length > 0
    ) {
      params.dewan = "ALL";
    }
    const res_statistik = await axiosAPI.post(
      API_URL_getstatistikdewan,
      params
    );
    setStatistik(res_statistik.data);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const [tabPage, setTabPage] = useState(0);
  const tab = [
    {
      name: "Resume Kegiatan",
      comp: <KegiatanResume resumeData={resumeData} />,
    },
    {
      name: "Dokumentasi",
      comp: (
        <KegiatanGallery
          data={dataGallery}
          setData={setDataGallery}
          kegiatan_id={event?.id}
        />
      ),
    },
  ];

  return (
    <Fragment>
      <div className="grid grid-cols-1 lg:grid-cols-5 sm gap-4">
        <CardDashboard
          title="Total Kegiatan"
          value={statistik.total_kegiatan ? statistik?.total_kegiatan?.now : 0}
          valueBefore={
            statistik.total_kegiatan ? statistik?.total_kegiatan?.before : 0
          }
          Icons={FaTasks}
        />
        <CardDashboard
          title="Kegiatan Onschedule"
          value={
            statistik.kegiatan_onschedule
              ? statistik?.kegiatan_onschedule?.now
              : 0
          }
          valueBefore={
            statistik.kegiatan_onschedule
              ? statistik?.kegiatan_onschedule?.before
              : 0
          }
          Icons={MdEvent}
        />
        <CardDashboard
          title="Kegiatan Ongoing"
          value={
            statistik.kegiatan_ongoing ? statistik?.kegiatan_ongoing?.now : 0
          }
          valueBefore={
            statistik.kegiatan_ongoing ? statistik?.kegiatan_ongoing?.before : 0
          }
          Icons={FiActivity}
        />
        <CardDashboard
          title="Kegiatan Finishing"
          value={
            statistik.kegiatan_finishing
              ? statistik?.kegiatan_finishing?.now
              : 0
          }
          valueBefore={
            statistik.kegiatan_finishing
              ? statistik?.kegiatan_finishing?.before
              : 0
          }
          Icons={TbReport}
        />
        <CardDashboard
          title="Kegiatan Done"
          value={statistik.kegiatan_done ? statistik?.kegiatan_done?.now : 0}
          valueBefore={
            statistik.kegiatan_done ? statistik?.kegiatan_done?.before : 0
          }
          Icons={BsCheckAll}
        />
      </div>
      <div className="bg-white rounded-sm shadow mt-4 p-4">
        <div className="w-full flex justify-end mb-4">
          <button
            onClick={() => setModalFilter(true)}
            className="flex justify-center items-center h-10 min-w-14 px-4 gap-4 whitespace-nowrap text-primary-700 bg-white hover:bg-base-100 text-sm font-semibold rounded-sm shadow transition-all"
          >
            <FiFilter />{" "}
            {periode.filter((item) => item.id === filterPeriode)[0]?.nama}
          </button>
        </div>
        <div className="flex justify-center items-center min-h-[300px]">
          {data.length > 0 ? (
            <ResponsiveContainer width="100%" height={300}>
              <BarChart
                margin={{
                  top: 15,
                  right: 0,
                  left: 40,
                  bottom: 15,
                }}
                layout="vertical"
                data={data}
              >
                <XAxis type="number" dataKey="total" allowDecimals={false} />
                <YAxis
                  style={{
                    fontSize: "0.8rem",
                  }}
                  type="category"
                  dataKey="nama"
                />
                <CartesianGrid strokeDasharray="5 3" />
                <Tooltip />
                <Bar dataKey="total" fill="#1C6EB1" />
              </BarChart>
            </ResponsiveContainer>
          ) : (
            <ErrorMessages messages="Data tidak ditemukan" type="nodata" />
          )}
        </div>
      </div>

      {isAuth().level.filter((item) => ["Super Admin", "Admin"].includes(item))
        .length > 0 && (
        <div className="bg-white rounded-sm shadow mt-4 p-4">
          <FullCalendar
            ref={calendarRef}
            plugins={[dayGridPlugin, timeGridPlugin, listPlugin]}
            initialView="dayGridMonth"
            events={dataEvent}
            eventClick={onEvent}
            headerToolbar={{
              start: "today prev,next",
              center: "title",
              end: "dayGridMonth,timeGridWeek,timeGridDay,listWeek",
            }}
            customButtons={{
              prev: {
                click: (e) => {
                  const calendarApi = calendarRef.current.getApi();
                  calendarApi.prev();
                  const date = calendarApi.currentData.currentDate;
                  getEvent(moment(date).format("YYYY-MM"));
                },
              },
              next: {
                click: (e) => {
                  const calendarApi = calendarRef.current.getApi();
                  calendarApi.next();
                  const date = calendarApi.currentData.currentDate;
                  getEvent(moment(date).format("YYYY-MM"));
                },
              },
            }}
          />
        </div>
      )}

      {/* Modal Detail */}
      <Modal
        title={"Detail Event"}
        dismiss
        closeButton
        show={modalEvent}
        setShow={setModalEvent}
      >
        {event && (
          <div className="p-5">
            {/* Detail */}
            <div>
              <div className="mb-10">
                <div className="flex gap-2 mb-2">
                  <div className="w-1/3 font-semibold">Nama Kegiatan</div>
                  <div className="flex-1">: {event?.nama}</div>
                </div>
                <div className="flex gap-2 mb-2">
                  <div className="w-1/3 font-semibold">Jenis Kegiatan</div>
                  <div className="flex-1">: {event?.jenis_kegiatan?.nama}</div>
                </div>
                <div className="flex gap-2 mb-2">
                  <div className="w-1/3 font-semibold">Tanggal</div>
                  <div className="flex-1">
                    : {getTanggal(event?.start_date, event?.end_date)}
                  </div>
                </div>
                <div className="flex gap-2 mb-2">
                  <div className="w-1/3 font-semibold">Lokasi</div>
                  <div className="flex-1">: {event?.tempat}</div>
                </div>
                <div className="flex gap-2 mb-2">
                  <div className="w-1/3 font-semibold">Pembuat</div>
                  <div className="flex-1">: {event?.pembuat?.nama}</div>
                </div>
                <div className="flex gap-2 mb-2">
                  <div className="w-1/3 font-semibold">Penerima</div>
                  <div className="flex-1">
                    : {listName(event?.penerima, "nama")}
                  </div>
                </div>
                <div className="flex gap-2 mb-2">
                  <div className="w-1/3 font-semibold">CC</div>
                  <div className="flex-1">
                    : {textArray(event?.email_cc, false, false)}
                  </div>
                </div>
                <div className="flex gap-2 mb-2">
                  <div className="w-1/3 font-semibold">Publish</div>
                  <div className="flex-1">
                    :{" "}
                    {event?.is_publish ? (
                      <span className="text-green-500">Publish</span>
                    ) : (
                      <span className="text-red-500">Draft</span>
                    )}
                  </div>
                </div>
                <div className="flex gap-2 mb-2">
                  <div className="w-1/3 font-semibold">Status</div>
                  <div className="flex flex-1 items-center gap-1">
                    :
                    <div
                      className={`flex justify-center font-semibold items-center px-2 py-1 rounded-lg text-white ${
                        !event?.is_publish
                          ? "bg-red-500"
                          : event?.status?.id === 0
                          ? "bg-blue-700"
                          : event?.status?.id === 1
                          ? "bg-yellow-400"
                          : event?.status?.id === 2
                          ? "bg-red-600"
                          : [3, 4, 5].includes(event?.status?.id)
                          ? "bg-base-400	"
                          : "bg-green-700"
                      }`}
                    >
                      {event?.is_publish ? event?.status.status : "Drafted"}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-col gap-3">
              {/* Tabs */}
              <div className="flex flex-row rounded-lg gap-2">
                {tab.map((item, idx) => (
                  <div key={idx}>
                    <button
                      onClick={() => setTabPage(idx)}
                      className={`px-4 p-2 flex rounded-lg justify-center text-sm transition-all ease-in duration-200 w-full ${
                        tabPage === idx
                          ? "text-white bg-gradient-to-br bg-primary-700 hover:bg-primary-800 font-bold"
                          : "border-transparent text-gray-400 hover:bg-neutral-200"
                      }`}
                    >
                      {item.name}
                    </button>
                  </div>
                ))}
              </div>

              {/* Content */}
              <div className="p-3 border-2 rounded-md">
                {tab.find((item, index) => index === tabPage).comp}
              </div>
            </div>
          </div>
        )}
      </Modal>

      <ModalContent dismiss show={modalFilter} setShow={setModalFilter}>
        <div className="w-[400px] h-[400px] overflow-y-auto">
          <div className="p-5 font-semibold text-lg border-b sticky top-0 bg-white">
            Periode
          </div>
          <div>
            {periode.map((item) => (
              <div
                onClick={() => {
                  filterPeriode !== item.id && onFilter(item);
                }}
                key={item.id}
                className="p-5 border-b cursor-pointer hover:bg-gray-50 transition-all flex justify-between items-center"
              >
                <span>{item.nama}</span>
                {filterPeriode === item.id && (
                  <AiOutlineCheck className="text-primary-700" />
                )}
              </div>
            ))}
          </div>
        </div>
      </ModalContent>
    </Fragment>
  );
};

export default Dashboard;
