import { isAuth, updateData } from "actions";
import { API_URL_changepassword } from "constants";
import { useFormik } from "formik";
import React, { Fragment, useEffect, useState } from "react";
import { HiEyeOff } from "react-icons/hi";
import { HiEye } from "react-icons/hi2";
import { useDispatch, useSelector } from "react-redux";
import { authReducer } from "reducers/authReducers";
import * as Yup from "yup";

const UbahPassword = () => {
  const { changePasswordResult } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [showPassword3, setShowPassword3] = useState(false);

  const formik = useFormik({
    initialValues: {
      id: "",
      password_baru: "",
      password_lama: "",
      konfirmasi_password: "",
    },
    validationSchema: Yup.object({
      password_lama: Yup.string().required("Password Lama harus diisi"),
      password_baru: Yup.string().required("Password Baru harus diisi"),
      konfirmasi_password: Yup.string()
        .required("Konfirmasi Password harus diisi")
        .oneOf([Yup.ref("password_baru"), null], "Password tidak sama"),
    }),
    onSubmit: (values) => {
      updateData(
        { dispatch, redux: authReducer },
        {
          pk: isAuth().user_id,
          old_password: values.password_lama,
          new_password: values.password_baru,
        },
        API_URL_changepassword,
        "CHANGE_PASSWORD"
      );
    },
  });

  useEffect(() => {
    if (changePasswordResult) {
      formik.resetForm();
    }
  }, [changePasswordResult]); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <Fragment>
      <div className="bg-white shadow rounded-sm">
        <div className="p-4 border-b font-semibold">Ubah Password</div>
        <div className="flex flex-col p-4 gap-3">
          <form onSubmit={formik.handleSubmit} className="flex flex-col gap-3">
            <label htmlFor="password_lama">
              <span className="text-sm font-semibold">Password Lama</span>
              <div className="relative">
                <input
                  id="password_lama"
                  placeholder="Password Lama"
                  className="p-2 text-sm border rounded-sm w-full outline-none"
                  type={showPassword ? "text" : "password"}
                  onChange={formik.handleChange}
                  value={formik.values.password_lama}
                />
                <div className="absolute top-2.5 right-3 text-lg text-gray-600 cursor-pointer">
                  {showPassword ? (
                    <HiEyeOff onClick={() => setShowPassword(false)} />
                  ) : (
                    <HiEye onClick={() => setShowPassword(true)} />
                  )}
                </div>
              </div>
              {formik.touched.password_lama && formik.errors.password_lama ? (
                <div className="text-red-500 text-xs">
                  {formik.errors.password_lama}
                </div>
              ) : null}
            </label>

            <label htmlFor="password_baru">
              <span className="text-sm font-semibold">Password Baru</span>
              <div className="relative">
                <input
                  id="password_baru"
                  placeholder="Password Baru"
                  className="p-2 text-sm border rounded-sm w-full outline-none"
                  type={showPassword2 ? "text" : "password"}
                  onChange={formik.handleChange}
                  value={formik.values.password_baru}
                />
                <div className="absolute top-2.5 right-3 text-lg text-gray-600 cursor-pointer">
                  {showPassword2 ? (
                    <HiEyeOff onClick={() => setShowPassword2(false)} />
                  ) : (
                    <HiEye onClick={() => setShowPassword2(true)} />
                  )}
                </div>
              </div>
              {formik.touched.password_baru && formik.errors.password_baru ? (
                <div className="text-red-500 text-xs">
                  {formik.errors.password_baru}
                </div>
              ) : null}
            </label>

            <label htmlFor="konfirmasi_password">
              <span className="text-sm font-semibold">Konfirmasi Password</span>
              <div className="relative">
                <input
                  id="konfirmasi_password"
                  placeholder="Konfirmasi Password"
                  className="p-2 text-sm border rounded-sm w-full outline-none"
                  type={showPassword3 ? "text" : "password"}
                  onChange={formik.handleChange}
                  value={formik.values.konfirmasi_password}
                />
                <div className="absolute top-2.5 right-3 text-lg text-gray-600 cursor-pointer">
                  {showPassword3 ? (
                    <HiEyeOff onClick={() => setShowPassword3(false)} />
                  ) : (
                    <HiEye onClick={() => setShowPassword3(true)} />
                  )}
                </div>
              </div>
              {formik.touched.konfirmasi_password &&
              formik.errors.konfirmasi_password ? (
                <div className="text-red-500 text-xs">
                  {formik.errors.konfirmasi_password}
                </div>
              ) : null}
            </label>

            <button
              type="submit"
              className="p-2 bg-primary-700 w-20 text-white text-sm self-end rounded-sm"
            >
              Simpan
            </button>
          </form>
        </div>
      </div>
    </Fragment>
  );
};

export default UbahPassword;
