import React from "react";
import NoData from "assets/image/NoData.png";
import ErrorImage from "assets/image/Error.png";

const ErrorMessages = ({ type, messages }) => {
	if (type === "error")
		return (
			<div>
				<img className="w-40" src={ErrorImage} alt="nodata" />
				<p className="text-base-500 text-sm text-center">{messages}</p>
			</div>
		);

	if (type === "nodata")
		return (
			<div>
				<img className="w-40" src={NoData} alt="nodata" />
				<p className="text-base-500 text-sm text-center">{messages}</p>
			</div>
		);

	return null;
};

export default ErrorMessages;
