// plugins
import axios from "axios";
import Swal from "sweetalert2";

import { authReducer } from "reducers/authReducers";
import { errorMessage, getToken, logout, setNewHeaders } from "actions";
import { API_URL_signin } from "constants";

export const loginUser = (dispatch, data) => {
  dispatch(
    authReducer({
      type: "LOGIN_USER",
      payload: {
        loading: true,
        data: false,
      },
    })
  );

  axios({
    method: "POST",
    url: API_URL_signin,
    timeout: 120000,
    data: data,
  })
    .then((response) => {
      if (response.data.status === 201) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          customClass: {
            container: "z-[99999]",
          },
          text: response.data.messages,
        });
      } else {
        setNewHeaders(response.data);
        Swal.fire({
          icon: "success",
          title: "Good job!",
          customClass: {
            container: "z-[99999]",
          },
          text: response.data.messages,
          showConfirmButton: false,
          timer: 1500,
        });
      }
      dispatch(
        authReducer({
          type: "LOGIN_USER",
          payload: {
            loading: false,
            data: response.data,
          },
        })
      );
    })
    .catch((error) => {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        customClass: {
          container: "z-[99999]",
        },
        text: errorMessage(error),
      });
      dispatch(
        authReducer({
          type: "LOGIN_USER",
          payload: {
            loading: false,
            data: false,
          },
        })
      );
    });
};

export const logoutUser = (dispatch) => {
  logout();
  dispatch(
    authReducer({
      type: "LOGOUT_USER",
      payload: {
        data: "LOGOUT SUKSES",
      },
    })
  );
  dispatch(
    authReducer({
      type: "LOGIN_USER",
      payload: {
        loading: false,
        data: false,
      },
    })
  );
};

export const updateAkun = (dispatch, data, pk, url) => {
  dispatch(
    authReducer({
      type: "UPDATE_AKUN",
      payload: {
        loading: true,
        data: false,
      },
    })
  );

  axios({
    method: "PUT",
    url: url + pk,
    timeout: 120000,
    data: data,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Token ${getToken()}`,
    },
  })
    .then((response) => {
      if (response.data.status === 201) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          customClass: {
            container: "z-[99999]",
          },
          text: response.data.messages,
        });
      } else {
        setNewHeaders(response.data, true);
        Swal.fire({
          icon: "success",
          title: "Good job!",
          customClass: {
            container: "z-[99999]",
          },
          text: response.data.messages,
          showConfirmButton: false,
          timer: 1500,
        });
      }
      dispatch(
        authReducer({
          type: "UPDATE_AKUN",
          payload: {
            loading: false,
            data: response.data,
          },
        })
      );
    })
    .catch((error) => {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        customClass: {
          container: "z-[99999]",
        },
        text: errorMessage(error),
      });
      dispatch(
        authReducer({
          type: "UPDATE_AKUN",
          payload: {
            loading: false,
            data: false,
          },
        })
      );
      if (error.response.status === 403 || error.response.status === 401) {
        logout();
      }
    });
};
