import { getTanggal } from "actions";
import axiosAPI from "actions/axiosAPI";
import {
  CardBidang,
  ErrorMessages,
  ModalContent,
  PortalHeader,
} from "components";
import {
  API_URL_getkalenderkegiatan,
  API_URL_getperiode,
  API_URL_getportal,
} from "constants";
import { useCallback, useEffect, useState } from "react";
import { AiOutlineCheck, AiOutlineLoading3Quarters } from "react-icons/ai";
import { FiFilter } from "react-icons/fi";

const Portal = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalFilter, setModalFilter] = useState(false);
  const [periode, setPeriode] = useState([]);
  const [kalender, setKalender] = useState([]);
  const [filterPeriode, setFilterPeriode] = useState("");
  const [dewan, setDewan] = useState("1");

  const onFilter = async (item) => {
    setModalFilter(false);
    setFilterPeriode(item.id);
    setLoading(true);
    const response = await axiosAPI.get(API_URL_getportal, {
      params: { periode: item.id, dewan },
    });
    setData(response.data);
    setLoading(false);
  };

  const onDewan = async (e) => {
    setLoading(true);
    const { value } = e.target;
    setDewan(value);
    const response = await axiosAPI.get(API_URL_getportal, {
      params: { periode: filterPeriode, dewan: value },
    });
    setData(response.data);
    setLoading(false);
  };

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const response = await axiosAPI.get(API_URL_getportal);
      setData(response.data);

      setModalFilter(false);
      const res_periode = await axiosAPI.get(API_URL_getperiode);
      setPeriode(res_periode.data);
      const res_kalender = await axiosAPI.get(API_URL_getkalenderkegiatan);
      setKalender(res_kalender.data);
      setFilterPeriode(res_periode.data[0].id);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <PortalHeader />

      <div className="flex justify-between w-full px-5 md:px-16 lg:px-24">
        <div className="flex">
          <select
            className="p-2 text-sm shadow rounded-sm w-full bg-white hover:bg-base-100 active:bg-base-100 focus:bg-base-100 text-primary-700 font-semibold"
            name="dewan"
            id="dewan"
            onChange={onDewan}
            value={dewan}
          >
            <option value="1">Dewan Pengurus</option>
            <option value="2">Dewan Pengawas</option>
            <option value="3">Kapengwil</option>
          </select>
        </div>
        <div className="flex ">
          <button
            onClick={() => setModalFilter(true)}
            className="flex justify-center items-center h-10 min-w-14 px-4 gap-4 whitespace-nowrap text-primary-700 bg-white hover:bg-base-100 text-sm font-semibold rounded-sm shadow transition-all"
          >
            <FiFilter />{" "}
            {periode.filter((item) => item.id === filterPeriode)[0]?.nama}
          </button>
        </div>
      </div>
      {loading ? (
        <div className="w-full h-[500px] flex justify-center items-center">
          <AiOutlineLoading3Quarters className="animate-spin text-4xl" />
        </div>
      ) : data.length > 0 ? (
        <div className="w-full px-5 md:px-16 lg:px-24 mb-10 transition-all my-10">
          {/* Menu Bidang */}
          <div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-2 mb-8">
            {data?.map((item, itemIdx) => (
              <CardBidang key={itemIdx} data={item} />
            ))}
          </div>

          {/* Berita */}
          {/* <hr className="mb-6" /> */}
          {/* <div className="grid grid-cols-5">
            <div className="col-span-full md:col-span-2">
              <div className="text-lg md:text-xl font-semibold mb-2">
                Kalender Kegiatan
              </div>
              <hr className="my-4 w-28 border-2 border-primary-800" />
              <div className="w-full max-h-[500px] overflow-y-auto border rounded">
                <table className="w-full">
                  <thead className="sticky top-0 text-white drop-shadow">
                    <tr className="bg-gradient-to-br from-primary-900 to-primary-700">
                      <th className="p-3 font-normal">Tanggal</th>
                      <th className="p-3 w-2/3 font-normal">Kegiatan</th>
                    </tr>
                  </thead>
                  <tbody>
                    {kalender.map((item, itemIdx) => (
                      <tr key={itemIdx}>
                        <td className="p-3 border border-l-0">
                          {getTanggal(item.start_date, item.end_date)}
                        </td>
                        <td className="p-3 border border-r-0 leading-none text-sm">
                          <span
                            className="text-primary-700 hover:text-primary-900"
                            href="/#"
                          >
                            {item.nama}
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div> */}
        </div>
      ) : data.length === 0 ? (
        <div className="w-full h-[300px] flex justify-center items-center">
          <ErrorMessages messages="Data tidak ditemukan" type="nodata" />
        </div>
      ) : (
        <div className="w-full h-[300px] flex justify-center items-center">
          <ErrorMessages messages="Error" type="error" />
        </div>
      )}

      <ModalContent dismiss show={modalFilter} setShow={setModalFilter}>
        <div className="w-[400px] h-[400px] overflow-y-auto">
          <div className="p-5 font-semibold text-lg border-b sticky top-0 bg-white">
            Periode
          </div>
          <div>
            {periode.map((item) => (
              <div
                onClick={() => {
                  filterPeriode !== item.id && onFilter(item);
                }}
                key={item.id}
                className="p-5 border-b cursor-pointer hover:bg-gray-50 transition-all flex justify-between items-center"
              >
                <span>{item.nama}</span>
                {filterPeriode === item.id && (
                  <AiOutlineCheck className="text-primary-700" />
                )}
              </div>
            ))}
          </div>
        </div>
      </ModalContent>
    </>
  );
};

export default Portal;
