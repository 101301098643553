import { Fragment, useCallback, useEffect, useState } from "react";

import {
  addData,
  deleteData,
  getData,
  isAuth,
  updateData,
  updateFormData,
} from "actions";
import {
  ButtonHover,
  ErrorMessages,
  HeadContent,
  Modal,
  ModalContent,
  Pagination,
  ToggleSwitch,
} from "components";
import {
  API_URL_activeakun,
  API_URL_changepassworduser,
  API_URL_createakun,
  API_URL_edelakun,
  API_URL_editfotouser,
  API_URL_getdataakun,
  baseurl,
} from "constants";
import { useFormik } from "formik";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { FiEdit } from "react-icons/fi";
import { HiEyeOff } from "react-icons/hi";
import { HiEye } from "react-icons/hi2";
import { ImKey } from "react-icons/im";
import { IoPerson } from "react-icons/io5";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { akunReducer } from "reducers/akunReducers";
import * as Yup from "yup";

const Akun = () => {
  const dispatch = useDispatch();
  const {
    addAkunResult,
    addAkunLoading,
    getAkunResult,
    getAkunLoading,
    getAkunError,
    deleteAkunResult,
  } = useSelector((state) => state.akun);

  const [limit, setLimit] = useState(10);
  const [pageActive, setPageActive] = useState(0);

  const [search, setSearch] = useState("");
  const [modal, setModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalUbahPassword, setModalUbahPassword] = useState(false);
  const [modalFoto, setModalFoto] = useState(false);
  const [foto, setFoto] = useState({});

  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);

  const formik = useFormik({
    initialValues: {
      id: "",
      nama: "",
      username: "",
      email: "",
      gmail: "",
      no_hp: "",
      level: "User",
    },
    validationSchema: Yup.object({
      nama: Yup.string().required("Nama harus diisi"),
      username: Yup.string().required("Username harus diisi"),
      email: Yup.string().email("Email tidak valid"),
      gmail: Yup.string().email("Gmail tidak valid"),
    }),
    onSubmit: (values, helpers) => {
      if (values.id) {
        updateData(
          { dispatch, redux: akunReducer },
          {
            pk: values.id,
            nama: values.nama,
            username: values.username,
            email: values.email,
            gmail: values.gmail,
            no_hp: values.no_hp,
            level: values.level,
          },
          API_URL_edelakun,
          "ADD_AKUN"
        );
      } else {
        addData(
          { dispatch, redux: akunReducer },
          {
            nama: values.nama,
            username: values.username,
            email: values.email,
            gmail: values.gmail,
            no_hp: values.no_hp,
            level: values.level,
          },
          API_URL_createakun,
          "ADD_AKUN"
        );
      }
    },
  });

  const formikPassword = useFormik({
    initialValues: {
      id: "",
      password: "",
      konfirmasi_password: "",
    },
    validationSchema: Yup.object({
      password: Yup.string().required("Password harus diisi"),
      konfirmasi_password: Yup.string()
        .required("Konfirmasi password harus diisi")
        .oneOf([Yup.ref("password"), null], "Password tidak sama"),
    }),
    onSubmit: (values, helpers) => {
      updateData(
        { dispatch, redux: akunReducer },
        {
          pk: values.id,
          new_password: values.password,
        },
        API_URL_changepassworduser,
        "ADD_AKUN"
      );
    },
  });

  const handlePageClick = (e) => {
    const offset = e.selected * limit;
    const param = {};
    if (search !== "") {
      param.search = search;
    }
    param.limit = limit;
    param.offset = offset;
    get(param);
    setPageActive(e.selected);
  };

  const handleSelect = (e) => {
    const param = {};
    if (search !== "") {
      param.search = search;
    }
    param.limit = e;
    get(param);
    setLimit(e);
    setPageActive(0);
  };

  const handleUploadFoto = (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("image", file);
    updateFormData(
      { dispatch, redux: akunReducer },
      formData,
      API_URL_editfotouser,
      "ADD_AKUN",
      foto.user_id
    );
  };

  const onAdd = () => {
    formik.resetForm();
    setModal(true);
    setModalTitle("Tambah Data");
  };

  const onEdit = (item) => {
    setModal(true);
    setModalTitle("Edit Data");
    formik.setValues({
      id: item.id,
      nama: item.nama,
      username: item.username,
      email: item.email,
      gmail: item.akun?.gmail || "",
      no_hp: item.akun?.no_hp || "",
      level: item.groups[0].name,
    });
  };

  const onDelete = (item) => {
    deleteData(
      { dispatch, redux: akunReducer },
      item.id,
      API_URL_edelakun,
      "DELETE_AKUN"
    );
  };

  const onSearch = (e) => {
    const { value } = e.target;
    setSearch(value);
    setLimit(10);
    setPageActive(0);
    get({ search: value });
  };

  const onChangeActive = (item) => {
    updateData(
      { dispatch, redux: akunReducer },
      {
        pk: item.id,
        is_active: !item.is_active,
      },
      API_URL_activeakun,
      "ADD_AKUN"
    );
  };

  const onChangePassword = (item) => {
    formikPassword.setValues({
      id: item.id,
    });
    setShowPassword(false);
    setShowPassword2(false);
    setModalUbahPassword(true);
  };

  const onViewFoto = (item) => {
    let data = {};
    data["user_id"] = item.id;
    if (item.akun.foto) {
      data["foto"] = baseurl + item.akun.foto;
    } else {
      data["foto"] = null;
    }
    setFoto(data);
    setModalFoto(true);
  };

  const get = useCallback(
    async (param) => {
      getData(
        { dispatch, redux: akunReducer },
        param,
        API_URL_getdataakun,
        "GET_AKUN"
      );
    },
    [dispatch]
  );

  const fetchData = useCallback(
    async (param = false) => {
      setModal(false);
      setModalUbahPassword(false);
      setModalFoto(false);

      get(param ? param : { limit: limit });
    },
    [get] // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (addAkunResult || deleteAkunResult) {
      const offset = pageActive * limit;
      const param = {};
      if (search !== "") {
        param.search = search;
      }
      param.limit = limit;
      param.offset = offset;
      fetchData(param);
    }
  }, [addAkunResult, deleteAkunResult, dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Fragment>
      <HeadContent onSearch={onSearch} onAdd={onAdd} />

      {/* Table */}
      <div className="bg-white rounded-sm shadow">
        <div className="p-4 border-b">Akun</div>
        <div className="p-4 pt-0">
          <div className="overflow-y-auto">
            <table className="w-full text-xs sm:text-sm">
              <thead>
                <tr className="text-left text-base-500 border-b">
                  <th className="p-2">No</th>
                  <th className="p-2">Nama</th>
                  <th className="p-2">Username</th>
                  <th className="p-2">No. Telepon</th>
                  <th className="p-2 text-center">Foto</th>
                  <th className="p-2 text-center">Active</th>
                  <th className="p-2 text-center">Aksi</th>
                </tr>
              </thead>
              <tbody>
                {/* Loading */}
                {getAkunLoading ? (
                  <tr className="text-base-800">
                    <td className="p-10" colSpan="7">
                      <div className="flex justify-center items-center">
                        <AiOutlineLoading3Quarters className="animate-spin text-xl" />
                      </div>
                    </td>
                  </tr>
                ) : // No Data
                getAkunResult.count === 0 ? (
                  <tr className="text-base-800">
                    <td className="p-10" colSpan="7">
                      <div className="flex flex-col justify-center items-center">
                        <ErrorMessages
                          messages="Data tidak ditemukan"
                          type="nodata"
                        />
                      </div>
                    </td>
                  </tr>
                ) : // Data
                getAkunResult.count > 0 ? (
                  getAkunResult.results.map((item, itemIdx) => (
                    <tr className="text-base-800 border-b -z-10" key={itemIdx}>
                      <td className="p-2">
                        {pageActive * limit + itemIdx + 1}
                      </td>
                      <td className="p-2">{item.nama}</td>
                      <td className="p-2">{item.username}</td>
                      <td className="p-2">{item.akun?.no_hp}</td>
                      <td className="p-2 flex justify-center items-center">
                        <div
                          onClick={() => onViewFoto(item)}
                          className="w-10 h-10 flex justify-center items-center rounded-full bg-white border overflow-hidden cursor-pointer"
                        >
                          {item.akun?.foto ? (
                            <img
                              src={baseurl + item.akun.foto}
                              alt="foto"
                              className="w-full h-full"
                            />
                          ) : (
                            <IoPerson className="text-gray-800 text-2xl" />
                          )}
                        </div>
                      </td>
                      <td className="p-2 text-center">
                        <ToggleSwitch
                          onChange={() => onChangeActive(item)}
                          value={item.is_active}
                        />
                      </td>
                      <td className="p-2">
                        <div className="flex justify-center gap-x-2 text-base">
                          <ButtonHover textHover="Edit">
                            <FiEdit
                              onClick={() => onEdit(item)}
                              className="cursor-pointer text-blue-500"
                            />
                          </ButtonHover>
                          <ButtonHover
                            placement="right"
                            textHover="Ubah Password"
                          >
                            <ImKey
                              onClick={() => onChangePassword(item)}
                              className="cursor-pointer text-yellow-300"
                            />
                          </ButtonHover>
                          <ButtonHover placement="right" textHover="Delete">
                            <RiDeleteBin6Line
                              onClick={() => onDelete(item)}
                              className="cursor-pointer text-red-500"
                            />
                          </ButtonHover>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : // Error
                getAkunError ? (
                  <tr className="text-base-800">
                    <td className="p-10" colSpan="7">
                      <div className="flex flex-col justify-center items-center">
                        <ErrorMessages messages={getAkunError} type="error" />
                      </div>
                    </td>
                  </tr>
                ) : null}
              </tbody>
            </table>
          </div>
          <Pagination
            handlePageClick={handlePageClick}
            pageCount={getAkunResult.count > 0 ? getAkunResult.count : 0}
            limit={limit}
            setLimit={handleSelect}
            pageActive={pageActive}
          />
        </div>
      </div>

      {/* Modal */}
      <Modal
        title={modalTitle}
        sizeModal="sm"
        dismiss
        show={modal}
        setShow={setModal}
      >
        {/* Content */}
        <div className="p-5">
          <form onSubmit={formik.handleSubmit}>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
              {/* Input Nama */}
              <div className="mb-2">
                <label className="required" htmlFor="nama">
                  Nama
                </label>
                <input
                  className="p-2 text-sm border rounded-sm w-full outline-none"
                  id="nama"
                  name="nama"
                  placeholder="Masukkan Nama"
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.nama}
                />
                {formik.touched.nama && formik.errors.nama ? (
                  <div className="text-red-500 text-xs">
                    {formik.errors.nama}
                  </div>
                ) : null}
              </div>

              {/* Input Username */}
              <div className="mb-2">
                <label className="required" htmlFor="username">
                  Username
                </label>
                <input
                  className="p-2 text-sm border rounded-sm w-full outline-none"
                  id="username"
                  name="username"
                  placeholder="Masukkan Username"
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.username}
                  // disabled={isEdit}
                />
                {formik.touched.username && formik.errors.username ? (
                  <div className="text-red-500 text-xs">
                    {formik.errors.username}
                  </div>
                ) : null}
              </div>
            </div>

            {/* Input Email */}
            <div className="mb-2">
              <label htmlFor="email">Email</label>
              <input
                className="p-2 text-sm border rounded-sm w-full outline-none"
                id="email"
                name="email"
                placeholder="Masukkan Email"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.email}
              />
              {formik.touched.email && formik.errors.email ? (
                <div className="text-red-500 text-xs">
                  {formik.errors.email}
                </div>
              ) : null}
            </div>

            {/* Input Gmail */}
            <div className="mb-2">
              <label htmlFor="gmail">Gmail</label>
              <input
                className="p-2 text-sm border rounded-sm w-full outline-none"
                id="gmail"
                name="gmail"
                placeholder="Masukkan Gmail"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.gmail}
              />
              {formik.touched.gmail && formik.errors.gmail ? (
                <div className="text-red-500 text-xs">
                  {formik.errors.gmail}
                </div>
              ) : null}
            </div>

            {/* Input No Telepon */}
            <div className="mb-2">
              <label htmlFor="no_hp">No Telepon</label>
              <input
                className="p-2 text-sm border rounded-sm w-full outline-none"
                id="no_hp"
                name="no_hp"
                placeholder="Masukkan No Telepon"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.no_hp}
              />
            </div>

            {/* Input Level */}
            <div className="mb-2">
              <label htmlFor="level">Level</label>
              <select
                className="p-2 text-sm border rounded-sm w-full outline-none"
                id="level"
                name="level"
                placeholder="Masukkan Level"
                onChange={formik.handleChange}
                value={formik.values.level}
              >
                {isAuth().level.filter((item) => item === "Super Admin")
                  .length > 0 && (
                  <option value="Super Admin">Super Admin</option>
                )}

                {isAuth().level.filter(
                  (item) => item === "Admin" || item === "Super Admin"
                ).length > 0 && <option value="Admin">Admin</option>}
                <option value="User">User</option>
              </select>
            </div>
          </form>
        </div>

        {/* Footer */}
        <div className="space-x-2 h-full flex justify-end items-center">
          <button
            className="p-2 px-4 border text-sm rounded-sm"
            type="submit"
            onClick={() => setModal(false)}
          >
            Cancel
          </button>
          <button
            className="p-2 px-4 w-20 flex justify-center items-center bg-primary-700 text-white text-sm rounded-sm"
            type="submit"
            disabled={addAkunLoading}
            onClick={formik.handleSubmit}
          >
            {addAkunLoading ? (
              <AiOutlineLoading3Quarters className="animate-spin text-xl" />
            ) : (
              "Submit"
            )}
          </button>
        </div>
      </Modal>

      <Modal
        title={"Ubah Password"}
        sizeModal="sm"
        dismiss
        show={modalUbahPassword}
        setShow={setModalUbahPassword}
      >
        {/* Content */}
        <div className="p-5">
          <form onSubmit={formikPassword.handleSubmit}>
            {/* Input password */}
            <div className="mb-2">
              <label className="required" htmlFor="password">
                Password
              </label>
              <div className="relative ">
                <input
                  className="p-2 text-sm border rounded-sm w-full outline-none"
                  id="password"
                  name="password"
                  placeholder="Masukkan Password"
                  type={showPassword ? "text" : "password"}
                  onChange={formikPassword.handleChange}
                  value={formikPassword.values.password}
                />
                {/* Show Password */}
                <div className="absolute top-2.5 right-3 text-lg text-gray-600 cursor-pointer">
                  {showPassword ? (
                    <HiEyeOff onClick={() => setShowPassword(false)} />
                  ) : (
                    <HiEye onClick={() => setShowPassword(true)} />
                  )}
                </div>
              </div>
              {formikPassword.touched.password &&
              formikPassword.errors.password ? (
                <div className="text-red-500 text-xs">
                  {formikPassword.errors.password}
                </div>
              ) : null}
            </div>

            {/* Input Konfirmasi Password */}
            <div className="mb-2">
              <label className="required" htmlFor="konfirmasi_password">
                Konfirmasi Password
              </label>
              <div className="relative">
                <input
                  className="p-2 text-sm border rounded-sm w-full outline-none"
                  id="konfirmasi_password"
                  name="konfirmasi_password"
                  placeholder="Masukkan Konfirmasi Password"
                  type={showPassword2 ? "text" : "password"}
                  onChange={formikPassword.handleChange}
                  value={formikPassword.values.konfirmasi_password}
                />
                {/* Show Password */}
                <div className="absolute top-2.5 right-3 text-lg text-gray-600 cursor-pointer">
                  {showPassword2 ? (
                    <HiEyeOff onClick={() => setShowPassword2(false)} />
                  ) : (
                    <HiEye onClick={() => setShowPassword2(true)} />
                  )}
                </div>
              </div>
              {formikPassword.touched.konfirmasi_password &&
              formikPassword.errors.konfirmasi_password ? (
                <div className="text-red-500 text-xs">
                  {formikPassword.errors.konfirmasi_password}
                </div>
              ) : null}
            </div>
          </form>
        </div>

        {/* Footer */}
        <div className="space-x-2 h-full flex justify-end items-center">
          <button
            className="p-2 px-4 w-20 flex justify-center items-center bg-primary-700 text-white text-sm rounded-sm"
            type="submit"
            onClick={formikPassword.handleSubmit}
          >
            Submit
          </button>
        </div>
      </Modal>

      <ModalContent dismiss show={modalFoto} setShow={setModalFoto}>
        <div className="relative w-80 h-80 flex justify-center items-center group/foto">
          {foto.foto ? (
            <img
              className="w-full h-full object-contain"
              src={foto.foto}
              alt="foto"
            />
          ) : (
            <IoPerson className="text-gray-800 text-6xl" />
          )}
          <div className="absolute text-white opacity-0 group-hover/foto:opacity-100 bg-black/30 h-full w-full flex justify-center items-center transition-all font-bold">
            Ubah Foto
          </div>
          <input
            onChange={handleUploadFoto}
            className="absolute h-full w-full opacity-0 cursor-pointer"
            type="file"
            accept="image/*"
          />
        </div>
      </ModalContent>
    </Fragment>
  );
};

export default Akun;
