import { Fragment, useCallback, useEffect, useState } from "react";

import { addData, deleteData, getData, textArray, updateData } from "actions";
import axiosAPI from "actions/axiosAPI";
import {
  ButtonHover,
  ErrorMessages,
  HeadContent,
  Modal,
  Pagination,
} from "components";
import {
  API_URL_createjeniskegiatan,
  API_URL_edeljeniskegiatan,
  API_URL_getdatajeniskegiatan,
  API_URL_gettemplateemail,
  API_URL_gettemplatesurat,
} from "constants";
import { useFormik } from "formik";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { FaMinusCircle, FaPlusCircle } from "react-icons/fa";
import { FiEdit } from "react-icons/fi";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { jenisKegiatanReducer } from "reducers/masterdataReducers";
import * as Yup from "yup";

const JenisKegiatan = () => {
  const dispatch = useDispatch();
  const {
    addJenisKegiatanResult,
    addJenisKegiatanLoading,
    getJenisKegiatanResult,
    getJenisKegiatanLoading,
    getJenisKegiatanError,
    deleteJenisKegiatanResult,
  } = useSelector((state) => state.masterdata);

  const [limit, setLimit] = useState(10);
  const [pageActive, setPageActive] = useState(0);
  const [search, setSearch] = useState("");
  const [modal, setModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");

  const [dataEmail, setDataEmail] = useState([]);
  const options_dataemail = dataEmail.map((item) => ({
    value: item.id,
    label: item.nama,
  }));
  const [dataSurat, setDataSurat] = useState([]);
  const options_datasurat = dataSurat.map((item) => ({
    value: item.id,
    label: item.nama,
  }));

  const formik = useFormik({
    initialValues: {
      id: "",
      nama: "",
      // is_riwayat: false,
      email: "",
      cc: [{ email: "" }],
      template_surat: "",
    },
    validationSchema: Yup.object({
      nama: Yup.string().required("Nama harus diisi"),
      template_surat: Yup.string().required("Template surat harus diisi"),
      email: Yup.string()
        .email("Format penulisan harus email")
        .required("Email harus diisi"),
      //   cc: Yup.array().min(1, "CC harus diisi"),
      cc: Yup.array().of(
        Yup.object().shape({
          email: Yup.string().required("Email harus diisi"),
        })
      ),
    }),
    onSubmit: (values) => {
      if (values.id) {
        updateData(
          { dispatch, redux: jenisKegiatanReducer },
          {
            pk: values.id,
            nama: values.nama,
            // is_riwayat: values.is_riwayat,
            email: values.email,
            cc: values.cc.map((item) => item.email),
            template_surat: values.template_surat,
          },
          API_URL_edeljeniskegiatan,
          "ADD_JENISKEGIATAN"
        );
      } else {
        addData(
          { dispatch, redux: jenisKegiatanReducer },
          {
            nama: values.nama,
            // is_riwayat: values.is_riwayat,
            email: values.email,
            cc: values.cc.map((item) => item.email),
            template_surat: values.template_surat,
          },
          API_URL_createjeniskegiatan,
          "ADD_JENISKEGIATAN"
        );
      }
    },
  });

  const handlePageClick = (e) => {
    const offset = e.selected * limit;
    const param = {};
    if (search !== "") {
      param.search = search;
    }
    param.limit = limit;
    param.offset = offset;
    get(param);
    setPageActive(e.selected);
  };

  const handleSelect = (e) => {
    const param = {};
    if (search !== "") {
      param.search = search;
    }
    param.limit = e;
    get(param);
    setLimit(e);
    setPageActive(0);
  };

  const onAdd = () => {
    formik.resetForm();
    setModal(true);
    setModalTitle("Tambah Data");
  };

  const onEdit = (item) => {
    formik.setValues({
      id: item.id,
      nama: item.nama,
      is_riwayat: item.is_riwayat,
      cc: item?.cc?.map((item) => ({ email: item })) || [{ email: "" }],
      email: item.email,
      template_surat: item.template_surat,
    });
    setModal(true);
    setModalTitle("Edit Data");
  };

  const onDelete = (item) => {
    deleteData(
      { dispatch, redux: jenisKegiatanReducer },
      item.id,
      API_URL_edeljeniskegiatan,
      "DELETE_JENISKEGIATAN"
    );
  };

  const onSearch = (e) => {
    const { value } = e.target;
    setSearch(value);
    setLimit(10);
    setPageActive(0);
    get({ search: value });
  };

  const get = useCallback(
    async (param) => {
      getData(
        { dispatch, redux: jenisKegiatanReducer },
        param,
        API_URL_getdatajeniskegiatan,
        "GET_JENISKEGIATAN"
      );
    },
    [dispatch]
  );

  const fetchData = useCallback(
    async (param = false) => {
      setModal(false);
      get(param ? param : { limit: limit });

      const res_email = await axiosAPI.get(API_URL_gettemplateemail);
      setDataEmail(res_email.data);
      const res_surat = await axiosAPI.get(API_URL_gettemplatesurat);
      setDataSurat(res_surat.data);
    },
    [get] // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (addJenisKegiatanResult || deleteJenisKegiatanResult) {
      const offset = pageActive * limit;
      const param = {};
      if (search !== "") {
        param.search = search;
      }
      param.limit = limit;
      param.offset = offset;
      fetchData(param);
    }
  }, [addJenisKegiatanResult, deleteJenisKegiatanResult, dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Fragment>
      <HeadContent onSearch={onSearch} onAdd={onAdd} />

      {/* Table */}
      <div className="bg-white rounded-sm shadow">
        <div className="p-4 border-b">Jenis Kegiatan</div>
        <div className="p-4 pt-0">
          <div className="overflow-y-auto">
            <table className="w-full text-xs sm:text-sm">
              <thead>
                <tr className="text-left text-base-500 border-b">
                  <th className="p-2">No</th>
                  <th className="p-2">Nama</th>
                  <th className="p-2">Email Master</th>
                  <th className="p-2">CC</th>
                  {/* <th className="p-2">Riwayat</th> */}
                  <th className="p-2 text-center">Aksi</th>
                </tr>
              </thead>
              <tbody>
                {/* Loading */}
                {getJenisKegiatanLoading ? (
                  <tr className="text-base-800">
                    <td className="p-10" colSpan="4">
                      <div className="flex justify-center items-center">
                        <AiOutlineLoading3Quarters className="animate-spin text-xl" />
                      </div>
                    </td>
                  </tr>
                ) : // No Data
                getJenisKegiatanResult.count === 0 ? (
                  <tr className="text-base-800">
                    <td className="p-10" colSpan="4">
                      <div className="flex flex-col justify-center items-center">
                        <ErrorMessages
                          messages="Data tidak ditemukan"
                          type="nodata"
                        />
                      </div>
                    </td>
                  </tr>
                ) : // Data
                getJenisKegiatanResult.count > 0 ? (
                  getJenisKegiatanResult.results.map((item, itemIdx) => (
                    <tr className="text-base-800 border-b" key={itemIdx}>
                      <td className="p-2">
                        {pageActive * limit + itemIdx + 1}
                      </td>
                      <td className="p-2">{item.nama || "-"}</td>
                      <td className="p-2">{item.email || "-"}</td>
                      <td className="p-2">
                        {textArray(item.cc, false, false)}
                      </td>
                      {/* <td className="p-2">
                        {item.is_riwayat ? (
                          <span className="p-1 px-3 text-white rounded bg-green-500">
                            Ya
                          </span>
                        ) : (
                          <span className="p-1 px-3 text-white rounded bg-red-500">
                            Tidak
                          </span>
                        )}
                      </td> */}
                      <td className="p-2">
                        <div className="flex justify-center gap-x-2 text-base">
                          <ButtonHover textHover="Edit">
                            <FiEdit
                              onClick={() => onEdit(item)}
                              className="cursor-pointer text-blue-500"
                            />
                          </ButtonHover>
                          <ButtonHover placement="right" textHover="Delete">
                            <RiDeleteBin6Line
                              onClick={() => onDelete(item)}
                              className="cursor-pointer text-red-500"
                            />
                          </ButtonHover>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : // Error
                getJenisKegiatanError ? (
                  <tr className="text-base-800">
                    <td className="p-10" colSpan="4">
                      <div className="flex flex-col justify-center items-center">
                        <ErrorMessages
                          messages={getJenisKegiatanError}
                          type="error"
                        />
                      </div>
                    </td>
                  </tr>
                ) : null}
              </tbody>
            </table>
          </div>
          <Pagination
            handlePageClick={handlePageClick}
            pageCount={
              getJenisKegiatanResult.count > 0
                ? getJenisKegiatanResult.count
                : 0
            }
            limit={limit}
            setLimit={handleSelect}
            pageActive={pageActive}
          />
        </div>
      </div>

      {/* Modal */}
      <Modal
        title={modalTitle}
        sizeModal="sm"
        dismiss
        show={modal}
        setShow={setModal}
      >
        {/* Content */}
        <div className="p-5">
          <form onSubmit={formik.handleSubmit}>
            {/* Input Nama */}
            <div className="mb-2">
              <label className="required" htmlFor="nama">
                Nama
              </label>
              <input
                className="p-2 text-sm border rounded-sm w-full outline-none"
                id="nama"
                name="nama"
                placeholder="Masukkan Nama"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.nama}
              />
              {formik.touched.nama && formik.errors.nama ? (
                <div className="text-red-500 text-xs">{formik.errors.nama}</div>
              ) : null}
            </div>

            {/* Input Email */}
            <div className="mb-2">
              <label className="required" htmlFor="email">
                Email Master
              </label>
              <Select
                id="email"
                className="text-sm"
                isClearable
                isSearchable
                value={options_dataemail.filter(
                  (item) => item.label == formik.values.email
                )}
                // isDisabled={isEdit && formik.values.is_publish}
                options={options_dataemail}
                onChange={(e) => {
                  formik.setFieldValue("email", e?.label || "");
                }}
              />
              {formik.touched.email && formik.errors.email ? (
                <div className="text-red-500 text-xs">
                  {formik.errors.email}
                </div>
              ) : null}
            </div>

            {/* Input template_surat */}
            <div className="mb-2">
              <label className="required" htmlFor="template_surat">
                Template Surat
              </label>
              <Select
                id="template_surat"
                className="text-sm"
                isClearable
                isSearchable
                value={options_datasurat.filter(
                  (item) => item.label == formik.values.template_surat
                )}
                // isDisabled={isEdit && formik.values.is_publish}
                options={options_datasurat}
                onChange={(e) => {
                  formik.setFieldValue("template_surat", e?.label || "");
                }}
              />
              {formik.touched.template_surat && formik.errors.template_surat ? (
                <div className="text-red-500 text-xs">
                  {formik.errors.template_surat}
                </div>
              ) : null}
            </div>

            {/* Input CC */}
            <div className="mb-2">
              <label className="required" htmlFor="email">
                CC
              </label>

              {formik.values.cc.map((item, idx) => (
                <div
                  key={idx}
                  className="flex gap-2 items-center justify-center mb-2"
                >
                  <div className="flex flex-col w-full">
                    <input
                      className="p-2 text-sm border rounded-sm w-full outline-none"
                      placeholder="Masukkan cc email"
                      type="email"
                      onChange={(e) => {
                        const tmp = [...formik.values.cc];
                        tmp[idx].email = e.target.value;
                        formik.setFieldValue("cc", tmp);
                      }}
                      value={item.email}
                    />
                    {formik.touched?.["cc"]?.[idx]?.["email"] &&
                    formik.errors?.["cc"]?.[idx]?.["email"] ? (
                      <div className="text-red-500 text-xs">
                        {formik.errors?.["cc"]?.[idx]?.["email"]}
                      </div>
                    ) : null}
                  </div>
                  {idx === 0 ? (
                    <FaPlusCircle
                      color="#0369A1"
                      size={26}
                      className="cursor-pointer"
                      onClick={() => {
                        const tmp = [...formik.values.cc];
                        tmp.push({ email: "" });
                        formik.setFieldValue("cc", tmp);
                      }}
                    />
                  ) : (
                    <FaMinusCircle
                      color="#EF4444"
                      size={26}
                      className="cursor-pointer"
                      onClick={() => {
                        const tmp = [...formik.values.cc];
                        tmp.splice(idx, 1);
                        formik.setFieldValue("cc", tmp);
                      }}
                    />
                  )}
                </div>
              ))}
            </div>

            {/* Input Is Riwayat */}
            {/* <div className="flex items-center gap-2 mb-2">
              <input
                id="is_riwayat"
                name="is_riwayat"
                type="checkbox"
                className="w-4 h-4"
                onChange={formik.handleChange}
                checked={formik.values.is_riwayat}
              />
              <label htmlFor="is_riwayat">Riwayat</label>
            </div> */}
          </form>
        </div>

        {/* Footer */}
        <div className="space-x-2 h-full flex justify-end items-center">
          <button
            className="p-2 px-4 border text-sm rounded-sm"
            type="submit"
            onClick={() => setModal(false)}
          >
            Cancel
          </button>
          <button
            className="p-2 px-4 w-20 flex justify-center items-center bg-primary-700 text-white text-sm rounded-sm"
            type="submit"
            disabled={addJenisKegiatanLoading}
            onClick={formik.handleSubmit}
          >
            {addJenisKegiatanLoading ? (
              <AiOutlineLoading3Quarters className="animate-spin text-xl" />
            ) : (
              "Submit"
            )}
          </button>
        </div>
      </Modal>
    </Fragment>
  );
};

export default JenisKegiatan;
