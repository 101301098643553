// pages
import {
  Akun,
  Dashboard,
  DewanPengawas,
  DewanPengurus,
  Jabatan,
  JenisKegiatan,
  Kapengwil,
  KegiatanList,
  Periode,
} from "pages";

// icons
import { BsClipboardCheck, BsPerson } from "react-icons/bs";
import { FiDatabase } from "react-icons/fi";
import { IoPeopleOutline } from "react-icons/io5";
import { RxDashboard } from "react-icons/rx";

export const menu = [
  {
    icon: <RxDashboard />,
    path: "/dashboard",
    name: "dashboard",
    title: "Dashboard",
    element: <Dashboard />,
    level: ["User", "Admin", "Super Admin"],
    sub: [],
  },
  {
    icon: <BsClipboardCheck />,
    path: "/kegiatan",
    name: "kegiatan",
    title: "Kegiatan",
    element: <KegiatanList />,
    level: ["User", "Admin", "Super Admin"],
    sub: [],
  },
  {
    icon: <IoPeopleOutline />,
    path: "/dewanpengurus",
    name: "dewanpengurus",
    title: "Dewan Pengurus",
    element: <DewanPengurus />,
    level: ["Admin", "Super Admin"],
    sub: [],
  },
  {
    icon: <IoPeopleOutline />,
    path: "/dewanpengawas",
    name: "dewanpengawas",
    title: "Dewan Pengawas",
    element: <DewanPengawas />,
    level: ["Admin", "Super Admin"],
    sub: [],
  },
  {
    icon: <IoPeopleOutline />,
    path: "/kapengwil",
    name: "kapengwil",
    title: "Ketua Pengurus Wilayah",
    element: <Kapengwil />,
    level: ["Admin", "Super Admin"],
    sub: [],
  },
  {
    icon: <FiDatabase />,
    path: "masterdata",
    name: "masterdata",
    title: "Master Data",
    element: null,
    level: ["Super Admin"],
    sub: [
      {
        icon: null,
        path: "/masterdata/jabatan",
        name: "jabatan",
        title: "Jabatan",
        element: <Jabatan />,
        sub: [],
      },
      {
        icon: null,
        path: "/masterdata/periode",
        name: "periode",
        title: "Periode",
        element: <Periode />,
        sub: [],
      },
      {
        icon: null,
        path: "/masterdata/jeniskegiatan",
        name: "jeniskegiatan",
        title: "Jenis Kegiatan",
        element: <JenisKegiatan />,
        sub: [],
      },
    ],
  },
  {
    icon: <BsPerson />,
    path: "/akun",
    name: "akun",
    title: "Akun",
    element: <Akun />,
    level: ["Super Admin"],
    sub: [],
  },
];
