import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import Header from "./Header";
import Sidebar from "./Sidebar";

const MainLayout = () => {
	const [sideOpen, setSideOpen] = useState(
		// window.innerWidth < 640 ? false : true
		true
	);

	return (
		<div className="flex h-screen overflow-hidden text-base-800">
			<Sidebar sideOpen={sideOpen} setSideOpen={setSideOpen} />
			<div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
				<Header sideOpen={sideOpen} setSideOpen={setSideOpen} />
				<div className="p-4 sm:p-8 h-screen w-full mx-auto overflow-y-auto overflow-x-hidden bg-gray-100">
					<Outlet />
				</div>
			</div>
		</div>
	);
};

export default MainLayout;
