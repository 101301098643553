import { Fragment, useCallback, useEffect, useState } from "react";

import { addData, deleteData, getData, updateData } from "actions";
import {
	ButtonHover,
	ErrorMessages,
	HeadContent,
	Modal,
	Pagination,
} from "components";
import {
	API_URL_createjabatan,
	API_URL_edeljabatan,
	API_URL_getdatajabatan,
} from "constants";
import { useFormik } from "formik";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { FiEdit } from "react-icons/fi";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { jabatanReducer } from "reducers/masterdataReducers";
import * as Yup from "yup";

const Jabatan = () => {
	const dispatch = useDispatch();
	const {
		addJabatanResult,
		addJabatanLoading,
		getJabatanResult,
		getJabatanLoading,
		getJabatanError,
		deleteJabatanResult,
	} = useSelector((state) => state.masterdata);

	const [limit, setLimit] = useState(10);
	const [pageActive, setPageActive] = useState(0);
	const [search, setSearch] = useState("");
	const [modal, setModal] = useState(false);
	const [modalTitle, setModalTitle] = useState("");

	const formik = useFormik({
		initialValues: {
			id: "",
			nama: "",
			dewan: "",
			keterangan: "",
		},
		validationSchema: Yup.object({
			nama: Yup.string().required("Nama harus diisi"),
			dewan: Yup.string().required("Dewan harus diisi"),
		}),
		onSubmit: (values) => {
			if (values.id) {
				updateData(
					{ dispatch, redux: jabatanReducer },
					{
						pk: values.id,
						nama: values.nama,
						type: values.dewan,
						keterangan: values.keterangan,
					},
					API_URL_edeljabatan,
					"ADD_JABATAN"
				);
			} else {
				addData(
					{ dispatch, redux: jabatanReducer },
					{
						nama: values.nama,
						type: values.dewan,
						keterangan: values.keterangan,
					},
					API_URL_createjabatan,
					"ADD_JABATAN"
				);
			}
		},
	});

	const handlePageClick = (e) => {
		const offset = e.selected * limit;
		const param = {};
		if (search !== "") {
			param.search = search;
		}
		param.limit = limit;
		param.offset = offset;
		get(param);
		setPageActive(e.selected);
	};

	const handleSelect = (e) => {
		const param = {};
		if (search !== "") {
			param.search = search;
		}
		param.limit = e;
		get(param);
		setLimit(e);
		setPageActive(0);
	};

	const onAdd = () => {
		formik.resetForm();
		setModal(true);
		setModalTitle("Tambah Data");
	};

	const onEdit = (item) => {
		formik.setValues({
			id: item.id,
			nama: item.nama,
			dewan: item.type.value,
			keterangan: item.keterangan,
		});
		setModal(true);
		setModalTitle("Edit Data");
	};

	const onDelete = (item) => {
		deleteData(
			{ dispatch, redux: jabatanReducer },
			item.id,
			API_URL_edeljabatan,
			"DELETE_JABATAN"
		);
	};

	const onSearch = (e) => {
		const { value } = e.target;
		setSearch(value);
		setLimit(10);
		get({ search: value });
		setPageActive(0);
	};

	const get = useCallback(
		async (param) => {
			getData(
				{ dispatch, redux: jabatanReducer },
				param,
				API_URL_getdatajabatan,
				"GET_JABATAN"
			);
		},
		[dispatch]
	);

	const fetchData = useCallback(
		async (param = false) => {
			setModal(false);
			get(param ? param : { limit: limit });
		},
		[get] // eslint-disable-line react-hooks/exhaustive-deps
	);

	useEffect(() => {
		fetchData();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps
	useEffect(() => {
		if (addJabatanResult || deleteJabatanResult) {
			const offset = pageActive * limit;
			const param = {};
			if (search !== "") {
				param.search = search;
			}
			param.limit = limit;
			param.offset = offset;
			fetchData(param);
		}
	}, [addJabatanResult, deleteJabatanResult, dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<Fragment>
			<HeadContent onSearch={onSearch} onAdd={onAdd} />

			{/* Table */}
			<div className="bg-white rounded-sm shadow">
				<div className="p-4 border-b">Jabatan</div>
				<div className="p-4 pt-0">
					<div className="overflow-y-auto">
						<table className="w-full text-xs sm:text-sm">
							<thead>
								<tr className="text-left text-base-500 border-b">
									<th className="p-2">No</th>
									<th className="p-2">Nama</th>
									<th className="p-2">Dewan</th>
									<th className="p-2">Keterangan</th>
									<th className="p-2 text-center">Aksi</th>
								</tr>
							</thead>
							<tbody>
								{/* Loading */}
								{getJabatanLoading ? (
									<tr className="text-base-800">
										<td className="p-10" colSpan="5">
											<div className="flex justify-center items-center">
												<AiOutlineLoading3Quarters className="animate-spin text-xl" />
											</div>
										</td>
									</tr>
								) : // No Data
								getJabatanResult.count === 0 ? (
									<tr className="text-base-800">
										<td className="p-10" colSpan="5">
											<div className="flex flex-col justify-center items-center">
												<ErrorMessages
													messages="Data tidak ditemukan"
													type="nodata"
												/>
											</div>
										</td>
									</tr>
								) : // Data
								getJabatanResult.count > 0 ? (
									getJabatanResult.results.map((item, itemIdx) => (
										<tr className="text-base-800 border-b" key={itemIdx}>
											<td className="p-2">
												{pageActive * limit + itemIdx + 1}
											</td>
											<td className="p-2">{item.nama}</td>
											<td className="p-2">{item.type.label}</td>
											<td className="p-2">{item.keterangan}</td>
											<td className="p-2">
												<div className="flex justify-center gap-x-2 text-base">
													<ButtonHover textHover="Edit">
														<FiEdit
															onClick={() => onEdit(item)}
															className="cursor-pointer text-blue-500"
														/>
													</ButtonHover>
													<ButtonHover placement="right" textHover="Delete">
														<RiDeleteBin6Line
															onClick={() => onDelete(item)}
															className="cursor-pointer text-red-500"
														/>
													</ButtonHover>
												</div>
											</td>
										</tr>
									))
								) : // Error
								getJabatanError ? (
									<tr className="text-base-800">
										<td className="p-10" colSpan="5">
											<div className="flex flex-col justify-center items-center">
												<ErrorMessages
													messages={getJabatanError}
													type="error"
												/>
											</div>
										</td>
									</tr>
								) : null}
							</tbody>
						</table>
					</div>
					<Pagination
						handlePageClick={handlePageClick}
						pageCount={getJabatanResult.count > 0 ? getJabatanResult.count : 0}
						limit={limit}
						setLimit={handleSelect}
						pageActive={pageActive}
					/>
				</div>
			</div>

			{/* Modal */}
			<Modal
				title={modalTitle}
				sizeModal="sm"
				dismiss
				show={modal}
				setShow={setModal}
			>
				{/* Content */}
				<div className="p-5">
					<form onSubmit={formik.handleSubmit}>
						{/* Input Nama */}
						<div className="mb-2">
							<label className="required" htmlFor="nama">
								Nama
							</label>
							<input
								className="p-2 text-sm border rounded-sm w-full outline-none"
								id="nama"
								name="nama"
								placeholder="Masukkan Nama"
								type="text"
								onChange={formik.handleChange}
								value={formik.values.nama}
							/>
							{formik.touched.nama && formik.errors.nama ? (
								<div className="text-red-500 text-xs">{formik.errors.nama}</div>
							) : null}
						</div>

						{/* Select Dewan */}
						<div className="mb-2">
							<label className="required" htmlFor="dewan">
								Dewan
							</label>
							<select
								className="p-2 text-sm border rounded-sm w-full outline-none"
								name="dewan"
								id="dewan"
								onChange={formik.handleChange}
								value={formik.values.dewan}
							>
								<option value="">Pilih Dewan</option>
								<option value="1">Dewan Pengurus</option>
								<option value="2">Dewan Pengawas</option>
							</select>
							{formik.touched.dewan && formik.errors.dewan ? (
								<div className="text-red-500 text-xs">
									{formik.errors.dewan}
								</div>
							) : null}
						</div>

						{/* Input Keterangan */}
						<div className="mb-2">
							<label htmlFor="keterangan">Keterangan</label>
							<textarea
								className="p-2 text-sm border rounded-sm w-full outline-none"
								id="keterangan"
								name="keterangan"
								placeholder="Masukkan Keterangan"
								type="text"
								onChange={formik.handleChange}
								value={formik.values.keterangan}
							/>
						</div>
					</form>
				</div>

				{/* Footer */}
				<div className="space-x-2 h-full flex justify-end items-center">
					<button
						className="p-2 px-4 border text-sm rounded-sm"
						type="submit"
						onClick={() => setModal(false)}
					>
						Cancel
					</button>
					<button
						className="p-2 px-4 w-20 flex justify-center items-center bg-primary-700 text-white text-sm rounded-sm"
						type="submit"
						disabled={addJabatanLoading}
						onClick={formik.handleSubmit}
					>
						{addJabatanLoading ? (
							<AiOutlineLoading3Quarters className="animate-spin text-xl" />
						) : (
							"Submit"
						)}
					</button>
				</div>
			</Modal>
		</Fragment>
	);
};

export default Jabatan;
